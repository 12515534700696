import { Component, OnInit } from '@angular/core';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { UserClient } from 'src/app/_api/clients/user.client';
import { ColumnItem } from 'src/app/_navigator/common/commonTable/interface/tableColumnInterface';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { EstimatesListState } from 'src/app/_navigator/estimates-list-store/estimates-list-state';
import { AccessType } from 'src/app/_navigator/user/enum/access-type';
import { EstimateSharedService } from './estimate-shared-service';
import { EstimateTitle, EstimateType } from 'src/app/_api/enums/estimate-type';
import { MenuType } from '../menu.type';

@Component({
    selector: 'us-estimates',
    templateUrl: './us-estimates.component.html'
})
export class UsEstimatesComponent extends SubscriberEntity implements OnInit {
    private _access = new BehaviorSubject<AccessType | undefined>(undefined);
    public moduleEstimates!: Estimate[] | undefined;
    public access = this._access.asObservable();
    public hideestimates = false;
    @Select(EstimatesListState.get) public estimates!: Observable<Estimate[] | undefined>;

    constructor(private spinner: Spinner,
        private userProfileClient: UserClient,
        private estimateSharedService: EstimateSharedService) {
        super();
    }



    public usEstimateTblColumns: ColumnItem<Estimate>[] = [
        {
            title: `Estimate Name`,
            compare: (a: Estimate, b: Estimate) => a.Name.localeCompare(b.Name),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'Name',
            type: 'estimateName'
        },
        {
            title: `Created/Modified Date`,
            compare: (a: Estimate, b: Estimate) => this.sortByDate(a, b, 'Updated'),
            sortDirections: ['ascend', 'descend'],
            sortOrder: 'descend',
            key: 'Updated',
            formate: 'MM/dd/yyyy',
            type: 'date'
        },
        {
            title: `Valuation Date`,
            compare: (a: Estimate, b: Estimate) => this.sortByDate(a, b, 'ValuationDate'),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'ValuationDate',
            formate: 'MM/dd/yyyy',
            type: 'date'
        },
        {
            title: `Investor Perspective`,
            compare: (a: Estimate, b: Estimate) => a.InvestorCountry?.localeCompare(b.InvestorCountry),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'InvestorCountry'
        },
        {
            title: `Industry`,
            key: 'Industries',
            type: 'Array'
        },
    ]

    public sortByDate = (a: any, b: any, field: string) => {
        {
            if (a[field] < b[field]) {
                return -1;
            }
            if (a[field] > b[field]) {
                return 1;
            }
            return 0;
        }
    }

    public ngOnInit(): void {
        this.estimateSharedService.setTitle(EstimateTitle.USEstimate);
        this.estimateSharedService.setModuleType(MenuType.UsCostOfCapitalEstimates);
        this.estimates.onceDefined((estimates: Estimate[]) => {
            if (estimates.length > 0) {
                this.moduleEstimates = estimates.filter((x) => x.EstimateType === EstimateType.USEstimate);
                this.moduleEstimates = this.estimateSharedService.mapEstimateCountryData(this.moduleEstimates, EstimateType.USEstimate);
            }
        });
        const profileRequest = this.userProfileClient.read();
        this.spinner.while(profileRequest);
        profileRequest.onceDefined((profile) => {
            for (const i of profile.Subscriptions) {
                if (i.Type === 'CocBasic' || i.Type === 'CocTrial' || i.Type === 'CocPro' || i.Type === 'CocEnterprise') {
                    const isAuthorized = new Date(i.End) > new Date();
                    this.hideestimates = isAuthorized;
                }
            }
        });
    }





}
