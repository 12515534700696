import * as moment from 'moment';

import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { Util } from '@concurrency/core';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import * as Highcharts from 'highcharts';
import { Chart } from 'angular-highcharts';
import { Dialog } from 'primeng/dialog';
import { TrendsOverTimePDFExportService } from 'src/app/_navigator/data/service/trends-over-time-pdf-export.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { TrendsOverTimeData } from 'src/app/benchmarking/data/trends-over-time-data';
import { TrendsOverTimeIndustry } from 'src/app/benchmarking/data/trends-over-time-industry';
import { TrendsOverTimeMetric } from 'src/app/benchmarking/data/trends-over-time-metric';
import { TrendsOverTimeTableData } from 'src/app/benchmarking/data/trends-over-time-table-data';
import { TrendsOverTimeExcelExportService } from 'src/app/home/exports/excel/trends-over-time-excel-export.service';
import { TearSheetService } from '../data/tear-sheet.service';
import { TrendsOverTimeService } from '../data/trends-over-time.service';
import { TearSheetUtil } from '../util/tear-sheet.util';
import { CategoryName } from '../enums/category';

@Component({
    encapsulation: ViewEncapsulation.None,
    templateUrl: './trends-over-time-modal.component.html',
    styleUrls: ['./trends-over-time-modal.component.scss']
})
export class TrendsOverTimeModalComponent implements OnInit, AfterViewInit {
    @ViewChild('trendsovertimepdf') public trendsovertimepdf!: PDFExportComponent;
    @ViewChild('trendsovertimemodal') public trendsovertimemodal!: ElementRef;

    @Input() public trendsOverTimeMetric!: TrendsOverTimeMetric;
    @Input() public trendsOverTimeData!: TrendsOverTimeData[][];
    @Input() public industryData: TrendsOverTimeIndustry[] = [];
    @Input() public metricCatogeryName!: string

    public metricTitle = '';

    public moment = moment;
    public Util = Util;
    public display = true;
    public dialogHeader!: string;
    public displayGraph = true;
    public displayContent = false;
    public lineChartOptionsArray: Highcharts.Options[] = [];
    public excelFileName = '';
    public medianLatestFilter = {
        display: false
    };

    public sicCompositeLatestFilter = {
        display: false
    };

    public largeCompositeLatestFilter = {
        display: false
    };

    public smallCompositeLatestFilter = {
        display: false
    };

    public highFinancialRiskLatestFilter = {
        display: false
    };

    public tableData = {} as TrendsOverTimeTableData;

    public fullSizeChart!: any;
    public isFullScreenDisplay: boolean = false;


    @ViewChild(Dialog) public dialog!: Dialog;

    constructor(
        protected dataStore: DataStore,
        public tearSheetService: TearSheetService,
        public trendsOverTimeService: TrendsOverTimeService,
        private trendsOverTimeExcelExportService: TrendsOverTimeExcelExportService,
        private trendsOverTimePDFExportService: TrendsOverTimePDFExportService
    ) { }

    private updateTrendsOverTimeDisplay(industryIndex: number, hasData: boolean): void {
        if (hasData) {
            this.industryData[industryIndex].TrendsOverTimeDisplay = true;
        } else {
            this.industryData[industryIndex].TrendsOverTimeDisplay = false;
        }
    }

    private setGraphData(): void {
        this.lineChartOptionsArray = [];

        this.trendsOverTimeData.forEach((trendOverTime, index) => {
            if (trendOverTime.length && this.industryData[index].TrendsOverTimeDisplay) {
                this.setLineChartOptions(trendOverTime, index);
            }
        });
    }

    private setTableData(): void {
        const industries = this.industryData.filter((industry) => {
            return industry.TrendsOverTimeDisplay;
        });

        const trendsOverTimeData = this.trendsOverTimeData.filter((_x, index) => {
            return this.industryData[index].TrendsOverTimeDisplay;
        });

        this.tableData = {
            industries,
            trendsOverTimeData
        };
    }

    private initializeFiltersBasedOnData(trendOverTime: TrendsOverTimeData): void {
        if (!this.medianLatestFilter.display) {
            this.medianLatestFilter.display = !!trendOverTime.Metric.MedianLatest;
        }
        if (!this.sicCompositeLatestFilter.display) {
            this.sicCompositeLatestFilter.display = !!trendOverTime.Metric.SICCompositeLatest;
        }
        if (!this.largeCompositeLatestFilter.display) {
            this.largeCompositeLatestFilter.display = !!trendOverTime.Metric.LargeCompositeLatest;
        }
        if (!this.smallCompositeLatestFilter.display) {
            this.smallCompositeLatestFilter.display = !!trendOverTime.Metric.SmallCompositeLatest;
        }
        if (!this.highFinancialRiskLatestFilter.display) {
            this.highFinancialRiskLatestFilter.display = !!trendOverTime.Metric.HighFinancialRiskLatest;
        }
    }

    private setLineChartOptions(trendsOverTimeData: TrendsOverTimeData[], index: number): void {
        // Any type is used since highchart's data property in a series accepts many data types
        let medianLatestDataArray: any[] = [];
        let sicCompositeDataArray: any[] = [];
        let largeCompositeDataArray: any[] = [];
        let smallCompositeDataArray: any[] = [];
        let highFinancialDataArray: any[] = [];
        let dateArray: string[] = [];
        let showInLegend: { [key: string]: boolean } = {
            "Median": false, "IndustryComposite": false, "SmallComposite": false,
            "LargeComposite": false, "HighFinancialRisk": false
        };

        trendsOverTimeData.forEach((data) => {
            const date = new Date(data.DataAsOf);

            dateArray = [...dateArray, moment(date).format('MM/DD/YYYY')];

            if (this.medianLatestFilter.display && (data.Metric.MedianLatest !== null
                || data.Metric.MedianLatest !== 0)) {
                medianLatestDataArray = [...medianLatestDataArray,
                {
                    y: data.Metric.MedianLatest
                }
                ];
            }
            if (this.sicCompositeLatestFilter.display && (data.Metric.SICCompositeLatest !== null
                || data.Metric.SmallCompositeLatest !== 0)) {
                sicCompositeDataArray = [...sicCompositeDataArray,
                {
                    y: data.Metric.SICCompositeLatest
                }
                ];
            }
            if (this.largeCompositeLatestFilter.display && (data.Metric.LargeCompositeLatest !== null
                || data.Metric.LargeCompositeLatest !== 0)) {
                largeCompositeDataArray = [...largeCompositeDataArray,
                {
                    y: data.Metric.LargeCompositeLatest
                }
                ];
            }
            if (this.smallCompositeLatestFilter.display && (data.Metric.SmallCompositeLatest !== null
                || data.Metric.SmallCompositeLatest !== 0)) {
                smallCompositeDataArray = [...smallCompositeDataArray,
                {
                    y: data.Metric.SmallCompositeLatest
                }
                ];
            }
            if (this.highFinancialRiskLatestFilter.display && (data.Metric.HighFinancialRiskLatest !== null
                || data.Metric.HighFinancialRiskLatest !== 0)) {
                highFinancialDataArray = [...highFinancialDataArray,
                {
                    y: data.Metric.HighFinancialRiskLatest
                }
                ];
            }
        });
        const isBeta = TearSheetUtil.isBeta(trendsOverTimeData[0].Metric);
        showInLegend["Median"] = medianLatestDataArray.map(element => element.y).every(value => value == null) ? false : true;
        showInLegend["IndustryComposite"] = sicCompositeDataArray.map(element => element.y).every(value => value == null) ? false : true;
        showInLegend["SmallComposite"] = smallCompositeDataArray.map(element => element.y).every(value => value == null) ? false : true;
        showInLegend["LargeComposite"] = largeCompositeDataArray.map(element => element.y).every(value => value == null) ? false : true;
        showInLegend["HighFinancialRisk"] = highFinancialDataArray.map(element => element.y).every(value => value == null) ? false : true;

        const industryData = this.industryData[index];
        const industryArea = industryData.Area;
        const industryCurrency = industryData.CurrencyCode;
        const industrySicId = industryData.SicId ? `SIC ${industryData.SicId}` : '';
        const industryGicId = industryData.GicId ? `GICS ${industryData.GicId}` : '';
        const industrySector = industryData.Sector;

        const lineChartOptions: Highcharts.Options = {
            chart: {
                type: 'line',
                style: {
                    fontFamily: 'nunito-sans'
                },
                className: 'trends-over-time'
            },
            exporting: {
                enabled: false
            },
            title: {
                text: `${this.metricTitle}`,
                style: {
                    color: 'rgb(77, 77, 79)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem'
                }
            },
            subtitle: {
                text: `${industrySicId}${industryGicId} - ${industrySector} (${industryArea} - ${industryCurrency})`,
                style: {
                    color: 'rgb(20,72,127)',
                    fontFamily: 'nunito-sans',
                    fontSize: '1rem',
                    fontWeight: 'bold'
                }
            },
            tooltip: {
                backgroundColor: {
                    linearGradient: { x1: 0, x2: 0, y1: 0, y2: 60 },
                    stops: [
                        [0, '#FFFFFF'],
                        [1, '#E0E0E0']
                    ]
                },
                borderWidth: 1,
                split: true,
                valueDecimals: isBeta ? 2 : 1
            },
            credits: {
                enabled: false
            },
            series: [
                {
                    type: 'line',
                    name: 'Industry Composite',
                    color: 'rgb(76,159,200)',
                    connectNulls: true,
                    data: [...sicCompositeDataArray],
                    showInLegend: showInLegend["IndustryComposite"]
                },
                {
                    type: 'line',
                    name: 'Large Composite',
                    color: 'rgb(208,0,112)',
                    connectNulls: true,
                    data: [...largeCompositeDataArray],
                    showInLegend: showInLegend["LargeComposite"]
                },
                {
                    type: 'line',
                    name: 'Small Composite',
                    color: 'rgb(167,168,169)',
                    connectNulls: true,
                    data: [...smallCompositeDataArray],
                    showInLegend: showInLegend["SmallComposite"]
                },
                {
                    type: 'line',
                    name: 'High Financial Risk',
                    color: 'rgb(103,210,223)',
                    connectNulls: true,
                    data: [...highFinancialDataArray],
                    showInLegend: showInLegend["HighFinancialRisk"]
                },
                {
                    type: 'line',
                    name: 'Median',
                    color: 'rgb(20,72,127)',
                    connectNulls: true,
                    data: [...medianLatestDataArray],
                    showInLegend: showInLegend["Median"]
                }
            ],
            xAxis: {
                categories: [...dateArray]
            },
            yAxis: { title: { text: '' }, labels: { format: isBeta ? '{value: .2f}' : '{value: .1f}' } }
        };

        this.lineChartOptionsArray = [...this.lineChartOptionsArray, lineChartOptions];
    }
    private getHighCharts(): any {
        const svgArr: string[] = [];
        let top = 0;
        let width = 0;
        // Reference to Highcharts forcing it to an any to utlize export utilities.
        const HC = Highcharts as any;

        HC.charts.forEach((chart: any) => {
            if (chart &&
                chart.options &&
                chart.options.chart) {

                if (chart.options.chart.className === 'trends-over-time') {

                    let svg: string = chart.getSVG();
                    const svgMatchWidth = svg.match(/^<svg[^>]*width\s*=\s*\"?(\d+)\"?[^>]*>/);
                    const svgMatchHeight = svg.match(/^<svg[^>]*height\s*=\s*\"?(\d+)\"?[^>]*>/);

                    if (svgMatchWidth && svgMatchHeight) {
                        const svgWidth = +svgMatchWidth[1];
                        const svgHeight = +svgMatchHeight[1];

                        svg = svg.replace(
                            '<svg',
                            '<g transform="translate(0,' + top + ')" '
                        );
                        svg = svg.replace('</svg>', '</g>');

                        top += svgHeight;
                        width = Math.max(width, svgWidth);

                        svgArr.push(svg);
                    }
                }
            }
        });

        return (`<svg height="${top}" width="${width}" version="1.1" xmlns="http://www.w3.org/2000/svg">
                    ${svgArr.join('')}
                </svg>`
        );
    }

    public showChartFullSize(index: number): void {
        const chartObj = this.lineChartOptionsArray[index];
        const fullSizeChart = new Chart({
            chart: {
                type: 'line',
            },
            title: {
                text: chartObj.title?.text,
                style: chartObj.title?.style
            },
            subtitle: {
                text: chartObj.subtitle?.text,
                style: chartObj.subtitle?.style
            },
            credits: {
                enabled: false
            },
            series: chartObj.series,
            xAxis: chartObj.xAxis,
            yAxis: chartObj.yAxis,
            tooltip: chartObj.tooltip,
            responsive: {
                rules: [
                    {
                        condition: {
                            minWidth: 1697
                        }, chartOptions: {
                            chart: {
                                height: 500
                            }
                        },
                    },
                    {
                        condition: {
                            minWidth: 1273, maxWidth: 1696
                        }, chartOptions: {
                            chart: {
                                height: 400
                            }
                        },
                    },
                    {
                        condition: {
                            minWidth: 0, maxWidth: 1272
                        }, chartOptions: {
                            chart: {
                                height: 250
                            }
                        },
                    },
                ]
            }
        });
        this.fullSizeChart = fullSizeChart;
        this.isFullScreenDisplay = !this.isFullScreenDisplay;

    }

    private exportCharts(): void {
        const HC = Highcharts as any;
        let options = {
            type: 'application/pdf'
        } as Highcharts.ExportingOptions;

        options = Highcharts.merge(Highcharts.getOptions().exporting, options);

        // TODO continue researching a way to utilize offline exporting and see if multiple chart exports is capable of this.

        HC.post(options.url, {
            filename: `Trends Over Time - ${this.metricTitle}`,
            type: options.type,
            width: options.width,
            svg: this.getHighCharts()
        });
    }

    public ngAfterViewInit(): void {
        this.trendsOverTimePDFExportService.setPdf(this.trendsovertimepdf);
        this.updateFilterResult();
        // setTimeout(() => { this.resizeofGraph(); }, 1000);
    }

    public ngOnInit(): void {
        this.industryData = this.tearSheetService.getTrendsOverTimeIndustry();
        this.metricTitle = `${this.trendsOverTimeMetric.metricCategory} - ${this.containsPercentageSubstring(this.trendsOverTimeMetric)}`;
        this.trendsOverTimeService.metricTitle = this.metricTitle;

        if (this.trendsOverTimeData.length) {

            this.trendsOverTimeData.forEach((trendsOverTime, index) => {
                this.updateTrendsOverTimeDisplay(index, trendsOverTime.length > 0);

                if (trendsOverTime.length) {

                    trendsOverTime.forEach((data) => {
                        this.initializeFiltersBasedOnData(data);
                    });
                }
            });

            this.updateFilterResult();
        }

        this.displayContent = true;
    }

    public toggleMedianLatestFilter(): void {
        this.medianLatestFilter.display = !this.medianLatestFilter.display;

        this.updateFilterResult();
    }
    public toggleSicCompositeFilter(): void {
        this.sicCompositeLatestFilter.display = !this.sicCompositeLatestFilter.display;

        this.updateFilterResult();
    }

    public toggleLargeCompositeFilter(): void {
        this.largeCompositeLatestFilter.display = !this.largeCompositeLatestFilter.display;

        this.updateFilterResult();
    }

    public toggleSmallCompositeFilter(): void {
        this.smallCompositeLatestFilter.display = !this.smallCompositeLatestFilter.display;

        this.updateFilterResult();
    }

    public toggleHighFinancialRiskFilter(): void {
        this.highFinancialRiskLatestFilter.display = !this.highFinancialRiskLatestFilter.display;

        this.updateFilterResult();
    }

    public toggleDisplay(displayGraph: boolean): void {
        this.displayGraph = displayGraph;
    }

    public updateIndustryFilterResult(industryIndex: number): void {
        this.industryData[industryIndex] = {
            ...this.industryData[industryIndex],
            TrendsOverTimeDisplay: !this.industryData[industryIndex].TrendsOverTimeDisplay
        };

        this.updateFilterResult();
    }

    public updateFilterResult(): void {
        this.resizeofGraph();
        this.setTableData();
    }

    public exportToPdf(): void {
        if (this.displayGraph) {
            this.exportCharts();
        } else {
            this.trendsOverTimePDFExportService.savePdf();
        }
    }

    public exportToExcel(component: ExcelExportComponent): void {
        this.excelFileName = `industry-benchmarking-trends.xlsx`;

        this.trendsOverTimeExcelExportService.save(component, this.tableData);
    }
    public resizeofGraph(): void {

        setTimeout(() => { this.setGraphData(); }, 1000);
    }

    public exitFullScreenChart() {
        this.isFullScreenDisplay = false;
    }

    public containsPercentageSubstring(data: TrendsOverTimeMetric): string {
        const substring = "(%)";
        var metricName = data.metricName;
        if ((this.metricCatogeryName && this.metricCatogeryName.includes(substring)) || this.metricCatogeryName === CategoryName.LiquidityRatio) {
            metricName = metricName + " " + substring;
        }
        return metricName;
    }
}
