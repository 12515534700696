import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationManager, Spinner } from '@concurrency/angular';
import { Authentication, AuthenticationType } from '@concurrency/authentication';
import { NGXLogger } from 'ngx-logger';
import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserClient } from 'src/app/_api/clients/user.client';
import { TermsAndConditionsVersions, UserActivityLogType, UserActivityRequest, UserResponse } from 'src/app/_api/responses/user.response';
import { NotificationService } from '../../data/service/notification.service'; // TODO: This should not be in here
import { AccessType } from '../enum/access-type';
import { UserType } from '../enum/user-type';
import { User } from '../model/user.model';
import { AuthService } from '@auth0/auth0-angular';
import { environment } from 'src/environments/environment';


@Injectable()
export class UserStore {
    private _access = new BehaviorSubject<AccessType | undefined>(undefined);
    private _user = new BehaviorSubject<UserResponse | undefined>(undefined);
    private _lastLoginType = new BehaviorSubject<UserType | undefined>(undefined);
    public access = this._access.asObservable();
    public AcceptTermsData!: TermsAndConditionsVersions
    public user = this._user.asObservable().pipe(
        map((x) => x == null ? undefined : new User(x))
    );

    public lastLoginType = this._lastLoginType.asObservable();
    public isSidemenuCollapsed!: boolean

    constructor(
        private logger: NGXLogger,
        private router: Router,
        private authenticationManager: AuthenticationManager,
        private spinner: Spinner,
        private userProfileClient: UserClient,
        private notificationService: NotificationService,
        private auth: AuthService
    ) {
        // TODO: We dont actually need to reload the whole page
        this.notificationService.purchaseEvent.once(() => window.location.reload());
    }

    // private findUser(authentication: Authentication): void {
    //     this.logger.info(`User Authentication is: ${authentication.current}`); // `

    //     if (authentication.current === AuthenticationType.None) {
    //         this._access.next(AccessType.Anonymous);
    //     } else {
    //         const profileRequest = this.userProfileClient.read();
    //         this.spinner.while(profileRequest);
    //         profileRequest.onceDefined((profile) => {
    //             const tokenRequest = this.authenticationManager.getToken();
    //             tokenRequest.onceDefined((token) => {
    //                 this.notificationService.start(token);
    //                 if ((sessionStorage.getItem('userActivityFlag') === '0')) {
    //                     sessionStorage.setItem('userActivityFlag', '1');
    //                     const activity: UserActivityRequest = {
    //                         Id: null,
    //                         userActivityType: UserActivityLogType.SignIn
    //                     };
    //                     this.userProfileClient.userActivityLog(activity).onceDefined((x) => {
    //                         sessionStorage.setItem('userActivityId', x);
    //                     });
    //                 }

    //             });

    //             this._user.next(profile);
    //             const isAuthorized = profile.Subscriptions[0] != null || profile.Subscriptions[0] === 'None';
    //             this._access.next(isAuthorized ? AccessType.Authorized : AccessType.Authenticated);
    //             this.router.navigate(['']);
    //         });
    //     }
    // }

    public findtheUser(): void {

        const profileRequest = this.userProfileClient.read();
        this.spinner.begin();
        profileRequest.onceDefined((profile) => {
            console.log(profile);
            this._user.next(profile);
            var token = this.auth.getAccessTokenSilently()
            token.onceDefined((tkn) => {
                this.notificationService.start(tkn, profile.Email);
            })

            if ((sessionStorage.getItem('userActivityFlag') === '0')) {
                sessionStorage.setItem('userActivityFlag', '1');
                const activity: UserActivityRequest = {
                    Id: null,
                    userActivityType: UserActivityLogType.SignIn
                };
                this.userProfileClient.userActivityLog(activity).onceDefined((x) => {
                    sessionStorage.setItem('userActivityId', x);
                });
            }
            const isAuthorized = profile.Subscriptions[0] != null || profile.Subscriptions[0] === 'None';
            this._access.next(isAuthorized ? AccessType.Authorized : AccessType.Authenticated);
            if (sessionStorage.getItem('isFreshLogin') === '1') {
                this.router.navigate(['']);
                sessionStorage.setItem('isFreshLogin', '0');
            }
            this.spinner.end();
        });

        // this.spinner.while(profileRequest);
        // profileRequest.onceDefined((profile) => {
        //     //  const tokenRequest = this.authenticationManager.getToken();
        //     // tokenRequest.onceDefined((token) => {
        //     //   this.notificationService.start(token);
        //     if ((sessionStorage.getItem('userActivityFlag') === '0')) {
        //         sessionStorage.setItem('userActivityFlag', '1');
        //         const activity: UserActivityRequest = {
        //             Id: null,
        //             userActivityType: UserActivityLogType.SignIn
        //         };
        //         this.userProfileClient.userActivityLog(activity).onceDefined((x) => {
        //             sessionStorage.setItem('userActivityId', x);
        //         });
        // }

        // });

        //     this._user.next(profile);
        //     const isAuthorized = profile.Subscriptions[0] != null || profile.Subscriptions[0] === 'None';
        //     this._access.next(isAuthorized ? AccessType.Authorized : AccessType.Authenticated);
        //     this.router.navigate(['']);
        // });

    }

    // public inititializeAuthentication(): void {
    //     if (sessionStorage.getItem('ccy.b2c.access_token') === null) {
    //         sessionStorage.setItem('userActivityFlag', '0');
    //     }
    //     this.authenticationManager.authentication.whileDefined((authentication) => {
    //         this.findUser(authentication);
    //         if (authentication.previous === AuthenticationType.AAD) {
    //             this._lastLoginType.next(UserType.Employee);
    //         } else if (authentication.previous === AuthenticationType.B2C) {
    //             this._lastLoginType.next(UserType.Commercial);
    //         }
    //     });
    // }

    public loginEmployee(): void {
        //this.authenticationManager.loginAAD();
        this.auth.loginWithRedirect();
    }

    public signupCustomer(email?: string): void {
        if (email == null) {
            return;
        }

        // this.authenticationManager.signupB2C({ email });
        this.auth.loginWithRedirect()
    }

    public loginCustomer(): void {
        //this.authenticationManager.loginB2C();]
        this.auth.loginWithRedirect();
    }

    public logoutCustomerOrEmployee(): void {
        this.spinner.begin();
        this.auth.logout({
            logoutParams: {
                returnTo: window.location.origin,
            },
        });
        if ((sessionStorage.getItem('userActivityFlag') === '1')) {
            const activity: UserActivityRequest = {
                Id: null,
                userActivityType: UserActivityLogType.SignOut
            };
            if (sessionStorage.getItem('userActivityId')) {
                activity.Id = sessionStorage.getItem('userActivityId');
            }
            this.userProfileClient.userActivityLog(activity).onceDefined(() => {
                // this.notificationService.stop();
                // this.authenticationManager.forget();
                //this.authenticationManager.logoutB2C();
                this.auth.logout({
                    logoutParams: {
                        returnTo: window.location.origin,
                    },
                });
                // this.spinner.while(logout);
                // this.logoutEmployee();

                // this.router.navigate(['']);
                // this.auth.loginWithRedirect();
            }, () => {
                this.spinner.end();
            });
        } else {
            this.auth.logout({
                logoutParams: {
                    returnTo: window.location.origin,
                },
            });
            // this.notificationService.stop();
            // this.authenticationManager.forget();
            // this.authenticationManager.logoutB2C();

            // const logout = this.auth.logout();
            // this.spinner.while(logout);
            // this.logoutEmployee();
            // this.auth.loginWithRedirect();
        }
    }



    // public logoutEmployee() {
    //     let currentHost = window.location.origin;
    //     let landingPage = `https://${environment.auth0.domain}/v2/logout?client_id=${encodeURIComponent(environment.auth0.clientId)}&returnTo=${encodeURIComponent(
    //         currentHost)}`;
    //     window.location.href = landingPage;

    // }
}
