import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ViewMoreOptions } from '../models/viewMore';
import { enumViewMoreKey, enumViewMoreNames } from '../enums/viewMore';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { ContextualString } from '@concurrency/core';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { IndustryTearSheet } from 'src/app/_api/responses/industry-tear-sheet.response';
import { TearSheetService } from '../data/tear-sheet.service';
import { TearSheetContent } from '../models/tear-sheet-content.model';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { IntlCompanyListComponent } from 'src/app/benchmarking/modals/intl-company-list/intl-company-list.component';
import { ngbDateStructToString, Spinner } from '@concurrency/angular';
import { FilesClient } from 'src/app/_api/clients/files.client';
import { BenchmarkingService } from 'src/app/_navigator/data/service/benchmarking.service';
import { CommonDate, DateStruct } from 'src/app/_navigator/common/date-struct';
import { ContainerTypeEnum } from '../../resources-library/enum/container-type.enum';
import { ResourcesLibraryComponent } from '../../resources-library/resources-library.component';
import { IndustryAssumptionsComponent } from 'src/app/benchmarking/components/industry-assumptions/industry-assumptions.component';

@Component({
    selector: 'view-more-options',
    templateUrl: './view-more-options.component.html',

})
export class ViewMoreOptionsComponent implements OnInit, OnChanges {


    public isTriggerd = false;
    public linkForCapitalStructure!: string
    @Input() tabIndex!: number;
    @Input() valuationDate!: DateStruct;
    public viewMoreOptions: Suggestion<string>[] = [];
    public viewMore = new ContextualString();
    public optimalCapStructureFileStartDate = new CommonDate(2015,12,31).asDate();

    constructor(
        private tearSheetService: TearSheetService,
        private benchmarkingService: BenchmarkingService,
        private primeManager: PrimeManager,
        private spinner: Spinner,
        private filesClient: FilesClient
    ) { }

    public viewMoreOptionsData: ViewMoreOptions[] = [
        new ViewMoreOptions(enumViewMoreKey.CompanyList, enumViewMoreNames.companyList),
        new ViewMoreOptions(enumViewMoreKey.Assumptions, enumViewMoreNames.Assumptions),
    ]


    public viewMoreConfig: CommonInputConfig = {

        name: 'Supplementary Data',
        label: 'Supplementary Data',
        placeholder: 'Select Supplementary Data',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true,
        readonly: true
    };



    ngOnInit(): void {
        this.updateViewMoreData(0, this.valuationDate, true);

    }


    ngOnChanges() {
        this.updateViewMoreData(this.tabIndex, this.valuationDate);
    }

    public setViewMoreOptions() {
        this.viewMoreOptions = [];
        this.viewMoreOptionsData.forEach((data: any) => {
            this.viewMoreOptions.push({
                id: data.key,
                name: data.name,
                value: data.name,
                type: ComboboxDataType.String,

            });
        });
    }

    public onChangeViewMoreOptions(tearSheet: TearSheetContent, value: number,): void {

        if (value == enumViewMoreKey.CompanyList && !this.isTriggerd) {
            this.isTriggerd = true;
            if (tearSheet && Object.keys(tearSheet).length !== 0) {

                this.primeManager.openDialog(IntlCompanyListComponent, {
                    companies: { ...tearSheet.Companies },
                    region: tearSheet.Region,
                    currency: tearSheet.Currency,
                    sicCode: tearSheet.SicCode,
                    gicCode: tearSheet.GicCode,
                    onClose: () => this.reSetViewMoreOptions()
                });
            }
        } else if (value == enumViewMoreKey.OptimalCapitalStructure && !this.isTriggerd) {
            this.isTriggerd = true;
            this.GetCapitalFileDownloadLinkFromValuationDate(ngbDateStructToString(this.benchmarkingService.benchMarkingValuationDate as DateStruct).split('T')[0]);
        } else if (value == enumViewMoreKey.Assumptions && !this.isTriggerd) {
            this.isTriggerd = true;
            if (tearSheet && Object.keys(tearSheet).length !== 0) {
                this.primeManager.openDialog(IndustryAssumptionsComponent, {
                    companies: tearSheet.Companies,
                    region: tearSheet.Region,
                    currency: tearSheet.Currency,
                    sicCode: tearSheet.SicCode,
                    gicCode: tearSheet.GicCode,
                    onClose: () => this.reSetViewMoreOptions()
                });
            }
        }


    }

    public reSetViewMoreOptions() {
        // this.setViewMoreOptions();
        this.isTriggerd = false;
        this.viewMore = new ContextualString();
    }

    public updateViewMoreData(index: number, valuationDate: DateStruct, InitialTab?: boolean) {
        const industry: IndustryTearSheet = this.tearSheetService.getTearSheetData()[index];
        let isUpdated = false;

        let valDate = CommonDate.fromStruct(valuationDate).asDate();
        let optimalCapStrucFileDownload: boolean = valDate >= this.optimalCapStructureFileStartDate;
        if (industry.Region == 'United States' && optimalCapStrucFileDownload && !this.viewMoreOptionsData.find(d => d.key == enumViewMoreKey.OptimalCapitalStructure)) {
            isUpdated = true;
            this.viewMoreOptionsData.push(new ViewMoreOptions(enumViewMoreKey.OptimalCapitalStructure, enumViewMoreNames.OptimalCapitalStructure))
        }
        else if (industry.Region != 'United States' && this.viewMoreOptionsData.find(d => d.key == enumViewMoreKey.OptimalCapitalStructure)) {
            isUpdated = true
            this.viewMoreOptionsData = this.viewMoreOptionsData.filter(d => d.key != enumViewMoreKey.OptimalCapitalStructure);
        }

        if (isUpdated || InitialTab) {
            this.setViewMoreOptions()
        }

    }

    public GetCapitalFileDownloadLinkFromValuationDate(valuationDate: string): void {
        const baseName = 'Kroll_OptimalCapitalStructure'
        let year = new Date(valuationDate).getUTCFullYear();
        //as JS uses 0 indexing for month, hence adding + 1 
        let month = new Date(valuationDate).getUTCMonth() + 1;
        let day = new Date(valuationDate).getUTCDate();
        let selectYearOfFile: number = 1990;//intitalizing with arbitrary year

        if (Number(month) == 12 && Number(day) == 31) {
            selectYearOfFile = year + 1;
        } else if (Number(month) == 12 && Number(day) == 30) {
            selectYearOfFile = year;
        } else {
            selectYearOfFile = year;
        }

        if (selectYearOfFile) {
            this.linkForCapitalStructure = `${baseName}_${selectYearOfFile}.xlsx`;
            this.getStorageFile(this.linkForCapitalStructure);
        }

    }

    public getStorageFile(fileName: string): void {
        this.spinner.begin();
        this.filesClient.getStorageFile(fileName, ContainerTypeEnum.resources).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
            this.reSetViewMoreOptions();
            this.spinner.end();
        });
    }

}
