// eslint-disable  max-len
// import { ComaparableCompanyFilter } from 'src/app/beta/beta.types';
import { HelpConfig, helpTextKeys, InputText } from 'src/app/international-estimate/data/model/input-text';
import { Help } from './help.model';


//Set the Initial HelpText Data with empty Text and Title to handle Exception
export let HelpText: { [key: string]: Help } = helpTextKeys.reduce((acc, key) => {
    acc[key] = new HelpConfig('', '')
    return acc;
}, {} as { [key: string]: Help });


//using updateHelpText will update the HelpText with actual Data once Data Received from Api 
export const updateHelpText = (data: { [key: string]: Help }) => HelpText = data;





