import { WorkbookSheetRow, WorkbookSheetRowCell } from '@progress/kendo-angular-excel-export';
import { betaBackGround } from 'src/app/estimate/exports/summary-export/util/summary-helpers';

export class CocInputExcelUtill {

    public static krollshadow = '#4d4d4f';
    public static LightGray = '#ECEEEF'
    public static krollblue = '#14487F';

    public static styleMainHeader(title: string, colSpan?: number): WorkbookSheetRowCell {

        const headerCell: WorkbookSheetRowCell = {
            value: title,
            colSpan: colSpan,
            color: this.krollblue,
            bold: true,
            fontSize: 16,
            verticalAlign: 'center',
            fontFamily: 'Arial'
        }

        return headerCell;
    }

    public static tableHeadCellFormater(Headercelldata: any[]): WorkbookSheetRowCell[] {
        const sheethederCell =
            Headercelldata.map((s: any, index: number) => {
                return this.styletblHeader(s, 1, index) as WorkbookSheetRowCell;
            });
        return sheethederCell;
    }

    private static styletblHeader(title: string, colSpan: number, index?: number): WorkbookSheetRowCell {

        const headerCell: WorkbookSheetRowCell = {
            value: title,
            colSpan: colSpan,
            color: this.krollshadow,
            bold: true,
            background: betaBackGround,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            fontSize: 14.66,
            ...this.mapBordersToCell()
        }

        if (index && index != 0) {
            headerCell.textAlign = 'right'
        }

        return headerCell;
    }


    public static getEmptyCell(): WorkbookSheetRowCell[] {
        const cell: WorkbookSheetRowCell = {
            value: '',
        };

        const result: WorkbookSheetRowCell[] = [];
        result.push(cell);

        return result;
    }

    public static getEmptyRow(): WorkbookSheetRow {
        const row: WorkbookSheetRow = {
            cells: this.getEmptyCell()
        }
        return row;
    }

    public static PercentileCell(value: string | number, align: string = "right"): WorkbookSheetRowCell {
        return {
            value: +value,
            color: this.krollshadow,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            fontSize: 14.66,
            textAlign: align,
            format: '0.00%',
            ...this.mapBordersToCell()

        } as WorkbookSheetRowCell
    }

    public static textCell(value: string | number, align: string = "right", bold: boolean = false): WorkbookSheetRowCell {
        return {
            value: value,
            color: this.krollshadow,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            fontSize: 14.66,
            textAlign: align,
            bold: bold,
            ...this.mapBordersToCell()

        } as WorkbookSheetRowCell
    }

    public static numberCell(value: string | number, align: string = "right"): WorkbookSheetRowCell {
        return {
            value: +value,
            color: this.krollshadow,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            fontSize: 14.66,
            textAlign: align,
            ...this.mapBordersToCell()


        } as WorkbookSheetRowCell
    }

    public static decimalCell(value: string | number, align: string = "right"): WorkbookSheetRowCell {
        return {
            value: +value,
            color: this.krollshadow,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            fontSize: 14.66,
            textAlign: align,
            format: '0.00',
            ...this.mapBordersToCell()

        } as WorkbookSheetRowCell
    }

    private static mapBordersToCell(): any {
        return {
            borderBottom: { size: 1, color: "#ECEEEF" },
            borderLeft: { size: 1, color: "#ECEEEF" },
            borderRight: { size: 1, color: "#ECEEEF" },
            borderTop: { size: 1, color: "#ECEEEF" },
        };
    }

}