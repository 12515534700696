import { BetaCompanyInfo, BetaEstimateResponse, BetaSelections, BetaValues } from 'src/app/_api/responses/beta-estimate.response';

export class BetaEstimate {
    public EstimateId: string;
    public UserProfileId: string;
    public Name: string;
    public ValuationDate: string;
    public CurrencyOfWacc: string;
    public SubjectCompanyLocation: string;
    public ReturnFrequency: string;
    public LookbackPeriod: string;
    public Marketindex: string;
    public BetaReleverType: string;
    public IsActive: boolean;
    public Created: Date;
    public Updated: Date;
    public Companies: BetaCompanyInfo[];
    public Selections: BetaSelections[];
    public Values: BetaValues[];
    public currencyWithAcronom: string = '';

    constructor(data: BetaEstimateResponse) {
        this.EstimateId = data.EstimateId;
        this.UserProfileId = data.UserProfileId;
        this.Name = data.Name;
        this.ValuationDate = data.ValuationDate;
        this.CurrencyOfWacc = data.CurrencyOfWacc;
        this.SubjectCompanyLocation = data.SubjectCompanyLocation;
        this.ReturnFrequency = data.ReturnFrequency;
        this.LookbackPeriod = data.LookbackPeriod;
        this.Marketindex = data.Marketindex;
        this.BetaReleverType = data.BetaReleverType;
        this.IsActive = data.IsActive;
        this.Created = data.Created;
        this.Updated = data.Updated;
        this.Companies = data.Companies;
        this.Selections = data.Selections;
        this.Values = data.Values;
        this.currencyWithAcronom = '';
    }
}
