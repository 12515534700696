import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { TrendsOverTimeTableData } from 'src/app/benchmarking/data/trends-over-time-table-data';
import { TrendsOverTimeService } from './data/trends-over-time.service';
import { TearSheetUtil } from './util/tear-sheet.util';

@Component({
    selector: 'trends-over-time-table',
    templateUrl: './trends-over-time-table.component.html',
    styleUrls: [ './trends-over-time-table.component.scss' ]
})
export class TrendsOverTimeTableComponent implements OnChanges {
    @Input() public tableData!: TrendsOverTimeTableData;
    @Input() public metricTitle!: string;
    @Input() public medianLatestFilter = false;
    @Input() public sicCompositeFilter = false;
    @Input() public largeCompositeFilter = false;
    @Input() public smallCompositeFilter = false;
    @Input() public highFinancialRiskFilter = false;
    public totTableData: any[] = [];

    constructor(
        public trendsOverTimeService: TrendsOverTimeService
    ) {}

    ngOnChanges(): void {
        this.totTableData = this.restructureTableData();
    }

    public restructureTableData(): any[] {
        const restructuredData:any[] = [];
        const isBeta = TearSheetUtil.isBeta(this.tableData.trendsOverTimeData[0][0].Metric);
        this.tableData.trendsOverTimeData.forEach(industry => {
                const data = {
                    "DataAsOf": industry.map(item => item.DataAsOf), 
                    "Median": industry.map(item => TearSheetUtil.roundTearSheetValue(item.Metric.MedianLatest, isBeta)),
                    "SICComposite": industry.map(item => TearSheetUtil.roundTearSheetValue(item.Metric.SICCompositeLatest, isBeta)),
                    "LargeComposite": industry.map(item => TearSheetUtil.roundTearSheetValue(item.Metric.LargeCompositeLatest, isBeta)),
                    "SmallComposite": industry.map(item => TearSheetUtil.roundTearSheetValue(item.Metric.SmallCompositeLatest, isBeta)),
                    "HighFinanacialRisk": industry.map(item => TearSheetUtil.roundTearSheetValue(item.Metric.HighFinancialRiskLatest, isBeta)),
                    "IsHighFinancialRiskExist": !!industry.find(item => item.Metric.HighFinancialRiskLatest != null),
                    "IsMedianExist": !!industry.find(item => item.Metric.MedianLatest != null),
                    "IsSmallCompositeExist": !!industry.find(item => item.Metric.SmallCompositeLatest != null),
                    "IsLargeCompositeExist": !!industry.find(item => item.Metric.LargeCompositeLatest != null),
                    "IsSICCompositeExist": !!industry.map(item => item.Metric.SICCompositeLatest != null),
                };
                restructuredData.push(data);
            })
        return restructuredData;
    }; 
}

