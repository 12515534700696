import { ApiClient } from '@concurrency/angular';
import { Observable } from 'rxjs/internal/Observable';
import { environment } from 'src/environments/environment';
import { Gic } from '../responses/gic.response';
import { Auth0ApiClient } from '../api.client';
import { ViewInputBetasByRegion } from '../responses/industry.response';
import { IntlDebtBetasDataByRegionAndCurrency } from '../responses/debt-betas-by-region-currency.response';
import { DebtBetaTrendsOverTimeByRegionAndCurrency } from '../responses/debt-betas-trends-over-time-by-region-currency.response';

export class IntlIndustryClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public read(countryId: number, currencyId: number, dataAsOf: string): Observable<Gic[]> {
        return this.get(`IntlIndustry?countryId=${countryId}&currencyId=${currencyId}&dataAsOf=${dataAsOf}`);
    }

    public getIndustryBetas(regions: string[], currencyCode: string, dataAsOf: string): Observable<ViewInputBetasByRegion[]> {
        return this.post(`IntlIndustry/IndustryBetas?currencyCode=${currencyCode}&dataAsOf=${dataAsOf}`, regions);
    }

    public getDebtBetasData(regions: string[], currencyCode: string, dataAsOf: string): Observable<IntlDebtBetasDataByRegionAndCurrency[]> {
        return this.post(`IntlIndustry/DebtBetas?currencyCode=${currencyCode}&dataAsOf=${dataAsOf}`, regions);
    }

    public getIntlDebtBetasTrendsOverTime(region: string, currencyCode: string, dataAsOf: string): Observable<DebtBetaTrendsOverTimeByRegionAndCurrency> {
        return this.get(`IntlIndustry/DebtBetasTrendsOverTime?region=${region}&currencyCode=${currencyCode}&dataAsOf=${dataAsOf}`);
    }
}
