import { Injectable } from '@angular/core';
import { CocInputsSharedService } from './cocInputShared.service';
import { ExcelExportComponent, Workbook, WorkbookSheet, WorkbookSheetRow, WorkbookSheetRowCell } from '@progress/kendo-angular-excel-export';
import { BetaLeverage, RiskPremiumReportRiskStudy, RiskPremiumReportSizeStudy, enumExcelExportSheets } from '../cocViewInputs';
import { betaBackGround } from 'src/app/estimate/exports/summary-export/util/summary-helpers';
import { NumberFormatUtil } from 'src/app/_navigator/data/util/number-format.util';
import { valueConversion } from 'src/app/beta/export/levered-betas';
import * as moment from 'moment';
import { BetaIndustryData, DecilesPortfolio, HighFinancialRiskStudy, LeveredPortfolio, RiskPremiumPortfolio, RiskPremiumSizeStudyPortfolio, UnleveredPortfolio } from '../models/cocInputsTabs';
import { InputType } from 'src/app/_api/responses/input.response';
import { MeasureClient } from 'src/app/_api/clients/measure.client';
import { Spinner } from '@concurrency/angular';
import { ERPSupplementary, ErpSupplymetryDetailData, LongTermErpData } from 'src/app/_api/responses/erp.supplymentary.data';
import { CocInputsNumberUtil } from '../coc-inputs-number-util';
import { forkJoin } from 'rxjs';
import { RPRsharedService } from './rpr-shared-service';
import { SizePremiumService } from './size-premium.service';
import { crspDecileCharacter, spRPRCharacter } from '../commonHeaders';
import { EnumSubsType } from '../models/userSubscriptionTypes';
import { CostOfEquityClient } from 'src/app/_api/clients/costOfEquity.client';
import { CountryRiskPremium, TierMethodologyData } from '../models/countryRiskPremium';
import { crpDataMap } from '../model-map-functions';
import { crpExcelSheet } from '../export/crp-export-sheet';
import { DataSourceDetails, DataSourcePayLoad } from '../models/DataSourceDetails';
import { ExportAboutSheet } from '../export/coc-inputs-aboutSheet';
import { CocInputExcelUtill } from '../export/cocinputs-export-utill';
import { CommonExcelExportUtil } from 'src/app/_navigator/common/common-excel-export-util';


@Injectable({
    providedIn: 'root'
})
export class CocinputsExcelExportService {


    public krollshadow = '#4d4d4f';
    public LightGray = '#ECEEEF'
    public krollblue = '#14487F';
    public columnWidth = 125;
    public arrayTempSpecifiTypePortfolio!: RiskPremiumSizeStudyPortfolio[];
    public aboutData!: DataSourceDetails;
    public dataAsOf: any;
    public sizePremiumDataAsof: any
    public rpOverRfRateDataAsOf: any
    public spRPRSCharacteristicData: any
    public spCrspCharacteristicData: any
    public crpData!: CountryRiskPremium[]
    public crpSuplyTier!: TierMethodologyData[]
    decileKeyElements = ['id', 'lowBreakPoint', 'highBreakPoint', 'sizePremium']
    decileSuplyKeyElements = ['id', 'lowBreakPoint', 'highBreakPoint', 'numberOfCompanies', 'arithmeticMeanReturn', 'geometricReturn', 'standardDeviationOfReturns', 'olsBeta', 'sumBeta', 'totalDecileMarketCapitalization']
    SP_RPSS_Decile_KeyelEments = ['id', 'lowBreakPoint', 'highBreakPoint', 'portofolioSize', 'numberOfCompanies', 'arithameticMeanReturn', 'geometricMeanReturn', 'standardDeviation', 'sumBetaSince1963', 'avgUnleverdBeta', 'avgDebtMvc', 'avgDebtEquity', 'avgOperatingMarigin', 'avgCvOfOperatingMarigin', 'avgCeOfRoe'];

    public RPRSSsupplyDataHeadersExport = ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Avg. Portfolio Size ($M)', 'Number of companies', 'Arithmetic Mean Return', 'Geometric Mean Return', 'Standard Deviation of Returns', 'Sum Beta (Since 1963)', 'Avg.Unleverd Beta', 'Avg.Debt/MVIC', 'Avg.Debt/Equity', 'Avg.Operating Margin', 'Avg.CV of Operating Margin', 'Avg.CV of ROE'];


    hrfKeyElements = ['zScorePortfolio', 'zone', 'industry', 'sizePremium'];
    hrf_Supp_KeyElements = ['zScorePortfolio', 'zone', 'industry', 'arithmeticMeanReturn', 'geometricMeanReturn', 'standardDeviationOfReturns', 'sumBeta', 'avgDebtToMVIC', 'avgDebtToEquity', 'avgOperatingMargin'];
    supplyHighFincRiskStdy = ['Z-Score Portfolio', 'Zone', 'Industry', 'Artihmetic Mean Return', 'Geometric Mean Return', 'Standard Deviation of Returns', 'Sum Beta(Since 1963)', 'Avg. Debt/MVIC', 'Avg. Debt/Equity', 'Avg. Operating Margin'];

    filterHrfElementd = ['zScorePortfolio', 'zone', 'industry', 'sizePremium', 'sumBeta',]

    public RPRRSsupplyDataHeaders =
        ['Portfolio', 'Low End Breakpoint', 'High End Breakpoint', 'Number of companies', 'Avg. Portfolio Size ($M)', 'Arithmetic Mean Return', 'Geometric Mean Return', 'Standard Deviation of Returns', 'Sum Beta (Since 1963)', 'Avg.Debt/MVIC'];



    public rpRFRRiskReportStudyKeyElements = ['id', 'lowBreakPoint', 'highBreakPoint', 'leveredRPORFR']

    public rpSuppRFRRiskReportStudyKeyElements = ['id', 'lowBreakPoint', 'highBreakPoint', 'portofolioSize', 'numberOfCompanies', 'arithameticMeanReturn', 'geometricMeanReturn', 'standardDeviation', 'sumBetaSince1963', 'avgDebtMvc']



    constructor(private cocInpustSharedService: CocInputsSharedService, private measureClient: MeasureClient, private spinner: Spinner, private rPRsharedService: RPRsharedService, private sizePremiumService: SizePremiumService, private csostOfEquityClient: CostOfEquityClient) { }

    //#region  Sheet Creation common methods

    public sheetCreation(name: string) {

        let commonSheet: WorkbookSheet = {
            name: name,
            columns: Array(12).fill({ width: this.columnWidth }),

        }


        return commonSheet
    }

    public getHeaderDetails(tittle: string, date: any, value: string, header: string[]): WorkbookSheetRow[] {

        let commonComoanyHeader: WorkbookSheetRow[] = [


            {
                cells: [this.styleMainHeader(tittle + moment(date).format('MM/DD/YYYY'), 3)]
            },
            {
                cells: [{}]
            },

            this.commonCompanyRankedBy(value),

            {
                cells: this.tableHeadCellFormater(header)
            }


        ]




        return commonComoanyHeader;

    }


    public commonCompanyRankedBy(value: string): WorkbookSheetRow {
        return {


            cells: [

                {
                    value: 'Companies Ranked by',
                    bold: true,
                    fontFamily: 'Arial',
                    color: this.krollshadow,

                },
                {
                    value: value,
                    bold: true,
                    color: this.krollblue,
                    fontFamily: 'Arial',

                },

            ]
        }
    }

    getFormat(key: string): string {


        let numberformatField = ['id', 'numberOfCompanies'];

        let camSeparateFormatField = ['totalDecileMarketCapitalization']

        let percentileformatField = ['arithmeticMeanReturn', 'geometricReturn', 'standardDeviationOfReturns', 'sizePremium', 'avgDebtMvc', 'avgDebtEquity', 'avgOperatingMarigin', 'avgOperatingMarigin', 'avgCeOfRoe', 'arithameticMeanReturn', 'geometricMeanReturn', 'standardDeviation', 'avgCvOfOperatingMarigin', 'avgDebtToMVIC', 'avgDebtToEquity', 'avgOperatingMargin', 'leveredRPORFR'];

        let decimalFormatField = ['highBreakPoint', 'lowBreakPoint', 'olsBeta', 'portofolioSize', 'sumBeta', 'sumBetaSince1963', 'avgUnleverdBeta']

        return percentileformatField.includes(key) ? '0.00%' : decimalFormatField.includes(key) ? '#,##0.00' : camSeparateFormatField.includes(key) ? '#,##0' : '';

    }

    private getSizePremiumCell(rowData: any, element: string): WorkbookSheetRowCell {
        return {
            value: this.SizepremiumValueConversion(rowData[element], element),
            color: this.krollshadow,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            fontSize: 14.66,
            format: this.getFormat(element),
            ...this.mapBordersToCell(),

        } as WorkbookSheetRowCell
    }


    private SizepremiumValueConversion(value: any, key: string): number | string {

        if (key == 'arithmeticMeanReturn' || key == 'geometricReturn' || key == 'standardDeviationOfReturns' ||
            key == 'arithameticMeanReturn' || key == 'geometricMeanReturn' || key == 'standardDeviation' || key == 'avgDebtMvc' || key == 'avgDebtEquity' ||
            key == 'avgOperatingMarigin' || key == 'avgCvOfOperatingMarigin' || key == 'avgCeOfRoe' ||
            key == 'avgDebtToMVIC' || key == 'avgDebtToEquity' || key == 'avgOperatingMargin') {
            //return valueConversion(value / 100)
            return CocInputsNumberUtil.ConvertToFixedFractionDigits(value, true, 2) / 100;
        } else if (key == 'id' && !isNaN(value)) {
            return parseInt(value)
        } else if (key == 'sizePremium' && !isNaN(value)) {
            return CocInputsNumberUtil.ConvertToFixedFractionDigits(value, false, 2) / 100;
        }
        else {
            return value ? CocInputsNumberUtil.ConvertToFixedFractionDigits(value, false, 2) : 'N/A'
        }
    }

    //#endregion



    //#region  Inputs

    exportInputs(): WorkbookSheet[] {
        const workbook = new Workbook()
        workbook.sheets = [
            {
                name: 'Inputs',
                columns: Array(12).fill({ width: this.columnWidth }),
                rows: [

                    {
                        cells: [this.styleMainHeader('Inputs', 1)]
                    },
                    {
                        cells: [
                            {
                                value: 'Valuation Date',
                                fontFamily: 'Arial',
                                bold: true,
                                color: this.krollshadow,
                                ...this.mapBordersToCell()
                            },
                            {
                                value: moment(this.cocInpustSharedService.cocInputValues.valuationDate).format('MM/DD/YYYY'),
                                fontFamily: 'Arial',
                                color: this.krollshadow,
                                ...this.mapBordersToCell()
                            },
                        ]
                    },
                    {
                        cells: [
                            {
                                value: 'Investor Perspective',
                                fontFamily: 'Arial',
                                bold: true,
                                color: this.krollshadow,
                                ...this.mapBordersToCell()
                            },
                            {
                                value: "" + this.cocInpustSharedService.cocInputValues.investorPerspectiveregion,
                                fontFamily: 'Arial',
                                color: this.krollshadow,
                                ...this.mapBordersToCell()
                            },
                        ]
                    },
                    {
                        cells: [
                            {
                                value: 'Currency of WACC',
                                fontFamily: 'Arial',
                                bold: true,
                                color: this.krollshadow,
                                ...this.mapBordersToCell()
                            },
                            {
                                value: "" + this.cocInpustSharedService.cocInputValues.currencyOfWACC,
                                fontFamily: 'Arial',
                                color: this.krollshadow,
                                ...this.mapBordersToCell()
                            },
                        ]
                    }
                ]
            }
        ]

        return workbook.sheets;
    }





    //#endregion


    //#region   RiskFree Rate

    exportRiskFreeRate(type: number, name: string): WorkbookSheet[] {

        const workbook = new Workbook()
        const headers = ['Data Point', 'Value', 'Data as of']
        let riskfreegenericdata;
        let header = 'Risk-free Rates'

        if (type === enumExcelExportSheets.RFR) {
            riskfreegenericdata = this.cocInpustSharedService.rf;
        } else {
            header = 'Equity Risk Premium';
            riskfreegenericdata = this.cocInpustSharedService.erp
        }
        workbook.sheets = [
            {
                name: name,
                columns: Array(12).fill({ width: this.columnWidth }),
                rows: [

                    {
                        cells: [this.styleMainHeader(header, 1)]
                    },
                    {
                        cells: []
                    },

                    {
                        cells: this.tableHeadCellFormater(headers)
                    },
                    ...this.riskFreeRateTableDataFormater(riskfreegenericdata)

                ]
            }
        ]

        if (type != enumExcelExportSheets.RFR && (this.cocInpustSharedService.usrPerms.SubsType == EnumSubsType.Coc || this.cocInpustSharedService.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.cocInpustSharedService.cocInputValues.investorPerspectiveregion == "United States" && this.cocInpustSharedService.isviewSupplymentrylinks)) {
            workbook.sheets.push(this.exportErpSupplymentryData())
        }

        return workbook.sheets;

    }

    private rowDataFormatter(rowdata: any): WorkbookSheetRowCell[] {
        const eachCRowData = Object.keys(rowdata).map(element => {
            return {

                value: element == 'value' ? (rowdata[element] / 100) : rowdata[element],
                color: this.krollshadow,
                fontSize: 14.66,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                format: element == 'value' ? '0.00%' : '',
                ...this.mapBordersToCell()
            } as WorkbookSheetRowCell
        })

        return eachCRowData;
    }

    // private riskFreeRateTableDataFormaterRFR(tableData: any[]): WorkbookSheetRow[] {

    //     const dataRows = tableData.map(item => {
    //         const data = { cells: this.rowDataFormatter(item) }
    //         return data as WorkbookSheetRow;
    //     });
    //     return dataRows;
    // }

    private riskFreeRateTableDataFormater(tableData: any[]): WorkbookSheetRow[] {

        const dataRows = tableData.map(item => {
            const data = { cells: this.rowDataFormatter(item) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }

    //#endregion


    //#region ERPSupplymentryExportData

    public exportErpSupplymentryData() {
        const erpSupplySheet: WorkbookSheet = {
            ...this.sheetCreation('ERP-Supp'),
            rows: [
                {
                    cells: [this.styleMainHeader("Supplementary Equity Risk Premium Data - Data as of " + moment(this.cocInpustSharedService.erpSupplymentryData.lstErpSupplymentry[0].DataAsOf).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },
                {
                    cells: [
                        this.styleMainHeader("Historical Long-term ERPs with Different Start and End Dates", 3)
                    ]
                },
                ...this.erpSupplyHeaders(),
                ...this.mapErpSupplyTermData(this.cocInpustSharedService.erpSupplymentryData.lstErpSupplymentry),
                {}, {}, {}, {}, {},
                {
                    cells: [
                        this.styleMainHeader("Long-term ERPs and World War II Bias ERPs", 3)
                    ]
                },
                {
                    cells: this.tableHeadCellFormater(['ERP Estimate', 'Period Dates', 'Arithmetic Average', 'Geometric Average'])
                },
                ...this.mapErpSupplyTermData(this.cocInpustSharedService.erpSupplymentryData.lstLongTermData),
                {}, {}, {}, {}, {},
                {
                    cells: [
                        this.styleMainHeader("ERPs with Different Bill/Bond Maturities", 3)
                    ]
                },
                ...this.erpSupplyBillBondHeaders(),
                ...this.mapErpBillBondSupplyData(this.cocInpustSharedService.erpSupplymentryData.lstlongTemBillBonds)
            ]
        }

        return erpSupplySheet;
    }

    public mapErpBillBondSupplyData(data: ERPSupplementary[]): WorkbookSheetRow[] {
        var billBondData = data.map(d => {
            return {
                BillBondMaturity: d.BillBondMaturity,
                PeriodDates: d.PeriodDates,
                ArithmeticAverage: d.ArithmeticAverage,
                GeometricAverage: d.GeometricAverage,
                StandardDeviation: d.StandardDeviation,
                StandardError: d.StandardError,
                StockArithmeticMeanReturne: d.StockArithmeticMeanReturne,
                StockGeometricMeanReturn: d.StockGeometricMeanReturn,
                StockstandardDeviation: d.StockstandardDeviation,
                BondArithmeticMeanReturne: d.BondArithmeticMeanReturne,
                BondGeometricMeanReturn: d.BondGeometricMeanReturn,
                BondstandardDeviation: d.BondstandardDeviation
            } as ERPSupplementary
        });

        const dataRows = billBondData.map(item => {

            const data = { cells: this.getCellDataErpSupply(item) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }


    public mapErpSupplyTermData(data: ERPSupplementary[] | LongTermErpData[]): WorkbookSheetRow[] {
        const dataRows = data.map(item => {
            const data = { cells: this.getCellDataErpSupply(item) }
            return data as WorkbookSheetRow;
        });
        return dataRows;

    }

    private getCellDataErpSupply(rowdata: any): WorkbookSheetRowCell[] {
        const eachCRowData = Object.keys(rowdata).filter(s => s != 'DataAsOf').map(element => {
            return {
                value: rowdata[element] ? (element != 'PeriodDates' && element != 'YearsLength' && element != 'ErpEstimate' && element != 'BillBondMaturity') ? CocInputsNumberUtil.ConvertToFixedFractionDigits(rowdata[element], true, 2) / 100 : rowdata[element] : (element != 'BillBondMaturity') ? 'N/A' : '',
                color: this.krollshadow,
                fontSize: 14.66,
                verticalAlign: 'center',
                textAlign: (element == 'YearsLength' || element == 'ErpEstimate' || element == 'BillBondMaturity') ? 'left' : 'right',
                fontFamily: 'Arial',
                format: (element != 'PeriodDates' && element != 'YearsLength' && element != 'ErpEstimate' && element != 'BillBondMaturity') ? '0.00%' : '',
                ...this.mapBordersToCell()
            } as WorkbookSheetRowCell
        })

        return eachCRowData;
    }


    public erpSupplyHeaders(): WorkbookSheetRow[] {
        const headerRow: WorkbookSheetRow[] =
            [
                {
                    cells: [
                        this.styrlErpSupplyHeader("Length (Yrs.)", 1, 2, 'left'),
                        this.styrlErpSupplyHeader("Period Dates", 1, 2),
                        this.styrlErpSupplyHeader("Historical Long-term ERP", 4, 1, 'center'),
                        this.styrlErpSupplyHeader("Large-cap Stocks", 3, 1, 'center'),
                        this.styrlErpSupplyHeader("Long-term Government Bonds", 3, 1, 'center')

                    ]
                },
                {
                    cells: [


                        this.styrlErpSupplyHeader("Arithmetic Average", 1, 1),
                        this.styrlErpSupplyHeader("Geometric Average", 1, 1),
                        this.styrlErpSupplyHeader("Standard Deviation", 1, 1),
                        this.styrlErpSupplyHeader("Standard Error", 1, 1),
                        this.styrlErpSupplyHeader("Arithmetic Mean Return", 1, 1),
                        this.styrlErpSupplyHeader("Geometric Mean Return", 1, 1),
                        this.styrlErpSupplyHeader("Standard Deviation", 1, 1),
                        this.styrlErpSupplyHeader("Arithmetic Mean Return", 1, 1),
                        this.styrlErpSupplyHeader("Geometric Mean Return", 1, 1),
                        this.styrlErpSupplyHeader("Standard Deviation", 1, 1),
                    ]
                }

            ]

        return headerRow;
    }

    public erpSupplyBillBondHeaders() {
        const headerRow: WorkbookSheetRow[] = [
            {
                cells: [
                    this.styrlErpSupplyHeader("Bill/Bond Maturity", 1, 2),
                    this.styrlErpSupplyHeader("Period Dates", 1, 2),
                    this.styrlErpSupplyHeader("Historical Long-term ERP", 4, 1),
                    this.styrlErpSupplyHeader("Large-cap Stocks", 3, 1),
                    this.styrlErpSupplyHeader("Government Bill/Bonds", 3, 1)
                ]
            },
            {
                cells: [
                    this.styrlErpSupplyHeader("Arithmetic Average", 1, 1),
                    this.styrlErpSupplyHeader("Geometric Average", 1, 1),
                    this.styrlErpSupplyHeader("Standard Deviation", 1, 1),
                    this.styrlErpSupplyHeader("Standard Error", 1, 1),
                    this.styrlErpSupplyHeader("Arithmetic Average", 1, 1),
                    this.styrlErpSupplyHeader("Geometric Average", 1, 1),
                    this.styrlErpSupplyHeader("Standard Deviation", 1, 1),
                    this.styrlErpSupplyHeader("Arithmetic Average", 1, 1),
                    this.styrlErpSupplyHeader("Geometric Average", 1, 1),
                    this.styrlErpSupplyHeader("Standard Deviation", 1, 1),
                ]
            }
        ]
        return headerRow;
    }


    public styrlErpSupplyHeader(title: string, colSpan: number, rowspan: number, alignment: 'left' | 'center' | 'right' = 'right') {
        const headerCell: WorkbookSheetRowCell = {

            rowSpan: rowspan,
            textAlign: alignment,
            fontFamily: 'Arial',
            value: title,
            colSpan: colSpan,
            color: this.krollshadow,
            bold: true,
            verticalAlign: 'center',
            fontSize: 14.66,
            background: betaBackGround,
            ...this.mapBordersToCell()

        }

        if (colSpan > 1) {
            // headerCell.borderLeft = { size: 1, color: this.LightGray };
            // headerCell.borderRight = { size: 1, color: this.LightGray };
            // headerCell.borderTop = { size: 1, color: this.LightGray };
            // headerCell.borderBottom = { size: 1, color: this.LightGray };
            headerCell.background = "#ffffff";
        }

        return headerCell
    }

    //#endregion




    //#region exportSizePremiumDeciles
    exportSizePremiumDeciles(): WorkbookSheet[] {
        const workbook = new Workbook();
        workbook.sheets = [] as WorkbookSheet[];
        var PrimaryDataTable, SupplymentaryDataTable;
        const headers = ['Decile', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Size Premium'];
        const suppHeaders = ['Decile', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Number of Companies', 'Arithmetic Return', 'Geometric Return', 'Standard Deviation of Returns', 'OLS Beta', 'Sum Beta', 'Total Decile Market Capitalization ($T)']
        PrimaryDataTable = {
            name: 'SP-CRSP',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [this.styleMainHeader('CRSP Deciles Size Study - Data as of ' + moment(this.cocInpustSharedService.sizePremiumValuationDate).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },
                {
                    cells: [
                        {
                            value: 'Companies Ranked by',
                            bold: true,
                            fontFamily: 'Arial',
                            color: this.krollshadow,

                        },
                        {
                            value: 'Market Value of Common Equity',
                            bold: true,
                            color: this.krollblue,
                            fontFamily: 'Arial',

                        },
                    ]
                },
                {
                    cells: this.tableHeadCellFormater(headers)
                },

                ...this.commonDecilRows(false)



            ]
        } as WorkbookSheet;


        SupplymentaryDataTable = {
            ...this.sheetCreation('SP-CRSP-Supp'),
            rows: [
                {
                    cells: [this.styleMainHeader('Supplementary CRSP Deciles Size Study Data - Data as of ' + moment(this.cocInpustSharedService.sizePremiumValuationDate).format('MM/DD/YYYY'), 3)]

                },
                {},
                {
                    cells: [{ ...this.styleMainHeader("Summary Statistics", 3), fontSize: 14.66 }]
                },
                this.commonCompanyRankedBy('Market Value of Common Equity'),
                {
                    cells: this.tableHeadCellFormater(suppHeaders)
                },
                ...this.commonDecilRows(true),
                //...this.mapSpCrspCharasteristic() //Hiding characteristics data for CRSP Deciles Size Study as per US-323812


            ]
        } as WorkbookSheet;

        if ((this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.Coc && this.cocInpustSharedService.isviewSupplymentrylinks == false) ||
            (this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.IneAndCoc && this.cocInpustSharedService.cocInputValues.investorPerspectiveregion === 'United States' && this.cocInpustSharedService.isviewSupplymentrylinks == false)) {
            workbook.sheets.push(PrimaryDataTable);
        } else {
            workbook.sheets.push(PrimaryDataTable);
            workbook.sheets.push(SupplymentaryDataTable);
        }

        return workbook.sheets

    }

    public commonDecilRows(isSuppData = false): WorkbookSheetRow[] {

        let decilesheetRow: WorkbookSheetRow[] = [


            ...this.decilesDataTableFormater(this.cocInpustSharedService.arraydecilesData, isSuppData),
            {
                cells: [
                    {
                        value: 'Breakdown of CRSP Deciles 1 - 10',
                        bold: true,
                        background: betaBackGround,
                        colSpan: 4,
                        fontFamily: 'Arial',
                        color: this.krollshadow

                    }]
            },
            ...this.decilesDataTableFormater(this.cocInpustSharedService.arraydecilesGrouping, isSuppData),
            {
                cells: [
                    {
                        value: 'Breakdown of CRSP 10th Decile',
                        bold: true,
                        background: betaBackGround,
                        colSpan: 4,
                        fontFamily: 'Arial',
                        color: this.krollshadow

                    }]
            },
            ...this.decilesDataTableFormater(this.cocInpustSharedService.arraydecilesSplit, isSuppData),
        ]

        return decilesheetRow;
    }

    private decilesDataTableFormater(tableData: any[], isSupplymentry = false): WorkbookSheetRow[] {
        const dataRows = tableData.map(item => {
            const data = { cells: this.decilesCellsFormating(item, isSupplymentry) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }

    private decilesCellsFormating(rowdata: any, isSupplemetryData: boolean) {

        let eachCRowData: any[] = []

        if (isSupplemetryData) {
            eachCRowData = this.decileSuplyKeyElements.map((element: any) => {
                return this.getSizePremiumCell(rowdata, element)
            })
        } else {
            eachCRowData = this.decileKeyElements.map((element: any) => {
                return this.getSizePremiumCell(rowdata, element)
            })
        }

        return eachCRowData;

    }

    private commonSizeStudtSupplyData(sheetName: string, date: any): WorkbookSheet {
        let sheet: WorkbookSheet = {
            ...this.sheetCreation(sheetName),
            rows: [
                {
                    cells: [this.styleMainHeader('Supplementary Risk Premium Report Size Study Data - Data as of ' + moment(date).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },
                {
                    cells: [{ ...this.styleMainHeader("Summary Statistics", 3), fontSize: 14.66 }]
                },
                ...this.mapSpecificCompanyRankedBy(true),
                ...this.decileSPrprsCharacteristicData(this.spRPRSCharacteristicData)

            ]

        }

        return sheet;
    }

    private mapSpCrspCharasteristic(): WorkbookSheetRow[] {
        const crspCharacteristicRows: WorkbookSheetRow[] = []
        var isTenXandYExist = false;

        if (this.spCrspCharacteristicData.find((d: any) => d.Decile == '10w')?.Decile) {
            isTenXandYExist = true;
        }
        const objKeys = [
            "DecileSubCatogery",
            "MarketValueofEquity",
            "BookValueofEquity",
            "MarketToBook",
            "MarketValueofInvestedCapital",
            "DebttoMVIC",
            "TotalAssets",
            "Sales",
            "FiveyearAverageNetIncome",
            "FiveyearAverageEBITDA",
            "ReturnonEquity",
            "OLSBeta",
            "SumBeta",
        ]
        crspCharacteristicRows.push(
            {
            },
            {
                cells: [{ ...this.styleMainHeader("Characteristics of Companies that Comprise 10w, 10x, 10y, and 10z", 6), fontSize: 14.66 }]
            },
            {
                cells: this.tableHeadCellFormater(crspDecileCharacter)
            });

        if (isTenXandYExist == false) {
            crspCharacteristicRows.push(
                {
                    cells: [

                        {

                            value: 'N/A',
                            color: this.krollshadow,
                            verticalAlign: 'center',
                            fontFamily: 'Arial',
                            textAlign: 'center',
                            fontSize: 14.66,
                            colSpan: 13


                        },
                    ]
                },
                {
                    cells: [{
                        value: '10X',
                        color: this.krollshadow,
                        verticalAlign: 'center',
                        fontFamily: 'Arial',
                        fontSize: 14.66,
                        colSpan: 13,
                        bold: true,
                        background: betaBackGround

                    }]
                },
                {
                    cells: [{

                        value: 'N/A',
                        color: this.krollshadow,
                        verticalAlign: 'center',
                        textAlign: 'center',
                        fontFamily: 'Arial',
                        fontSize: 14.66,
                        colSpan: 13


                    }]
                },
                {
                    cells: [{
                        value: '10Y',
                        color: this.krollshadow,
                        verticalAlign: 'center',
                        fontFamily: 'Arial',
                        fontSize: 14.66,
                        colSpan: 13,
                        bold: true,
                        background: betaBackGround
                    }]
                },


            )
        }



        this.spCrspCharacteristicData.forEach((obj: any, index: number) => {
            if (index != 0 && obj.Decile !== this.spCrspCharacteristicData[index - 1].Decile) {
                crspCharacteristicRows.push({
                    cells: [{
                        value: obj.Decile,
                        color: this.krollshadow,
                        verticalAlign: 'center',
                        fontFamily: 'Arial',
                        fontSize: 14.66,
                        colSpan: 13,
                        bold: true,
                        background: betaBackGround
                    }]
                })
            }
            crspCharacteristicRows.push({
                cells: objKeys.map(d => {
                    return {
                        value: this.formatcrspCharacterValue(d, obj[d]),
                        color: this.krollshadow,
                        verticalAlign: 'center',
                        fontFamily: 'Arial',
                        fontSize: 14.66,
                        format: (d == "ReturnonEquity" || d == "DebttoMVIC") ? '0.00%' : '#,##0.00'
                    } as WorkbookSheetRowCell
                })
            })
        });
        return crspCharacteristicRows;
    }

    private formatcrspCharacterValue(key: string, value: any): any {
        if (key == "ReturnonEquity" || key == "DebttoMVIC") {
            return value ? CocInputsNumberUtil.ConvertToFixedFractionDigits(value, true, 2) / 100 : 'N/A';
        } else {
            return value ? CocInputsNumberUtil.ConvertToFixedFractionDigits(value, false, 2) : 'N/A';
        }


    }

    //#endregion


    //#region SIZE PREMIUM RISK REPORT SIZE STUDY
    private exportSizePremiumRPRSS(): WorkbookSheet[] {
        const workbook = new Workbook();
        workbook.sheets = [] as WorkbookSheet[];
        var PrimaryDataTable, SupplymentaryDataTable;

        PrimaryDataTable = {
            name: 'SP-RPRS',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [this.styleMainHeader('Risk Premium Report Size Study - Data as of ' + moment(this.cocInpustSharedService.sizePremiumValuationDate).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },

                ...this.mapSpecificCompanyRankedBy(false)

            ]

        } as WorkbookSheet;

        SupplymentaryDataTable = this.commonSizeStudtSupplyData('SP-RPRS-Supp', this.cocInpustSharedService.sizePremiumValuationDate);

        if ((this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.Coc && this.cocInpustSharedService.isviewSupplymentrylinks == false) ||
            (this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.IneAndCoc && this.cocInpustSharedService.cocInputValues.investorPerspectiveregion === 'United States' && this.cocInpustSharedService.isviewSupplymentrylinks == false)) {
            workbook.sheets.push(PrimaryDataTable);
        } else {
            workbook.sheets.push(PrimaryDataTable);
            workbook.sheets.push(SupplymentaryDataTable);
        }

        return workbook.sheets;
    }


    private mapSpecificCompanyRankedBy(isSupplymentry: boolean): WorkbookSheetRow[] {
        let RPRSSsheetRows: WorkbookSheetRow[] = [];


        Object.keys(RiskPremiumReportSizeStudy).forEach((key) => {
            let type = RiskPremiumReportSizeStudy[key] as InputType
            let tempData = this.cocInpustSharedService.arrayportfolios.filter(item => item.InputType === InputType[type]);
            this.arrayTempSpecifiTypePortfolio = tempData.map((port: any, index: number) => {
                return {
                    id: "" + port.Id,
                    name: port.Name,
                    lowBreakPoint: index == (tempData.length - 1) ? "<" + NumberFormatUtil.numberWithCommas(+port.Upper, 2) : port.Lower,
                    highBreakPoint: index === 0 ? '>' + NumberFormatUtil.numberWithCommas(port.Lower, 2) : port.Upper,
                    sizePremium: (port.Result * 100),
                    leveredRPORFR: port.Result * 100,
                    numberOfCompanies: port.NumberOfCompanies,
                    arithameticMeanReturn: port.ArithmeticMeanReturn,
                    geometricMeanReturn: port.GeometricMeanReturn,
                    sumBetaSince1963: port.SumBetaSince1963,
                    standardDeviation: port.StandardDeviationOfReturns,
                    avgUnleverdBeta: port.UnleveredBeta,
                    avgOperatingMarigin: port.AverageOperatingMargin,
                    avgDebtEquity: port.AverageDebtToMvoe,
                    avgDebtMvc: port.AverageDebtMvic,
                    avgCvOfOperatingMarigin: port.AverageCovom,
                    avgCeOfRoe: port.AverageCovroe,
                    portofolioSize: port.PortofolioSize
                } as RiskPremiumSizeStudyPortfolio
            });
            if (isSupplymentry) {
                RPRSSsheetRows.push(
                    ...this.sizePremiumsheetRowsRPRSS(key, this.arrayTempSpecifiTypePortfolio, isSupplymentry),
                    {
                        cells: [{}]
                    }
                )
            } else {
                RPRSSsheetRows.push(
                    ...this.sizePremiumsheetRowsRPRSS(key, this.arrayTempSpecifiTypePortfolio, isSupplymentry),
                    this.regressionHeader(),
                    this.regressionEquationMappingCellMapping(this.cocInpustSharedService.rprRegressionAppendixB[type], key, 'Smoothed Premium'),
                    {
                        cells: [{}]
                    }
                );
            }
        });

        return RPRSSsheetRows;
    }


    private sizePremiumsheetRowsRPRSS(cmpnyRankedBy: string, tblData: RiskPremiumSizeStudyPortfolio[], isSupplymentry: boolean): WorkbookSheetRow[] {
        const headers = isSupplymentry ? [...this.RPRSSsupplyDataHeadersExport] : ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Size Premium'];

        //Removing ($M)
        if (cmpnyRankedBy == 'Employees') {
            headers[1] = 'Low End Breakpoint'
            headers[2] = 'High End Breakpoint'
        }
        let sheetrows: WorkbookSheetRow[] = [
            this.commonCompanyRankedBy(cmpnyRankedBy),
            {
                cells: this.tableHeadCellFormater(headers),
            },
            ...this.SizepremiumRPRSSDataTableFormater(tblData, isSupplymentry),

        ]

        return sheetrows;

    }

    private SizepremiumRPRSSDataTableFormater(tableData: RiskPremiumSizeStudyPortfolio[], isSupplemetryData: boolean): WorkbookSheetRow[] {
        const dataRows = tableData.map(item => {
            const data = { cells: this.spRPRSSCellsFormating(item, isSupplemetryData) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }

    private spRPRSSCellsFormating(rowdata: any, isSupplemetryData: boolean): WorkbookSheetRowCell[] {
        let eachCRowData: any[] = [];
        if (isSupplemetryData) {
            eachCRowData = this.SP_RPSS_Decile_KeyelEments.map(element => {
                return this.getSizePremiumCell(rowdata, element);
            })
        } else {
            eachCRowData = this.decileKeyElements.map(element => {
                return this.getSizePremiumCell(rowdata, element);
            })
        }


        return eachCRowData;

    }

    private regressionHeader(): WorkbookSheetRow {
        let headers = ['Constant', 'Coefficient', "", 'Regression Equation']

        let rows: WorkbookSheetRow =
        {
            cells: headers.map((cell, index) => { return this.styletblHeader(cell, 1, 1) })
        }
        return rows
    }


    regressionEquationMappingCellMapping(objData: any, cmpanyRankedBy: any, premiumType: string): WorkbookSheetRow {

        let regresionEquation = `${premiumType} = ${(objData.Constant * 100).toFixed(3)}%  ${(objData.Coefficient * 100).toFixed(3)}% * Log(${cmpanyRankedBy})`
        let regressionDataRow: WorkbookSheetRow = {
            cells: [
                {
                    value: objData.Constant,
                    color: this.krollshadow,
                    verticalAlign: 'center',
                    fontFamily: 'Arial',
                    fontSize: 14.66,
                    format: '##.000\%',
                    ...this.mapBordersToCell(),
                },
                {
                    value: objData.Coefficient,
                    color: this.krollshadow,
                    verticalAlign: 'center',
                    fontFamily: 'Arial',
                    fontSize: 14.66,
                    format: '##.000\%',
                    ...this.mapBordersToCell(),
                },
                {
                    value: '',
                    ...this.mapBordersToCell(),
                },
                {
                    value: regresionEquation,
                    color: this.krollshadow,
                    verticalAlign: 'center',
                    fontFamily: 'Arial',
                    fontSize: 14.66,
                    colSpan: 2,
                    ...this.mapBordersToCell(),

                },


            ]
        }
        return regressionDataRow;
    }

    private decileSPrprsCharacteristicData(characteristicData: any): WorkbookSheetRow[] {
        const Characteristicsrows: WorkbookSheetRow[] = [
            {
            },
            {
                cells: [{ ...this.styleMainHeader("Size Characteristics of Companies that Comprise Portfolio’s 23, 24, and 25", 4), fontSize: 14.66 }]
            },
            {
                cells: this.tableHeadCellFormater(spRPRCharacter)
            }
        ];
        let portkeys = ["LargestCompany", "p95", "p75", "p50", "p25", "p5", 'SmallestCompany'];
        let orderFinalKeys = [
            "MarketValueOfEquity",
            "BookValueOfEquity",
            "MVIC",
            "TotalAssets",
            "Sales",
            "FiveYearsAvgIncome",
            "FiveYearsAvgEbitda",
            "NumOfEmp",]
        Object.keys(this.spRPRSCharacteristicData).filter(s => s != 'dataAsOf').forEach((key, index) => {
            if (index > 0) {
                Characteristicsrows.push({
                    cells: [{
                        value: key,
                        colSpan: 9,
                        color: this.krollshadow,
                        bold: true,
                        background: betaBackGround,
                        verticalAlign: 'center',
                        fontFamily: 'Arial',
                        fontSize: 14.66,
                        ...this.mapBordersToCell(),
                    }]
                })
            }
            Characteristicsrows.push(...this.formSpRPRSEachCharacteristicRow(characteristicData[key], portkeys, orderFinalKeys))
        });
        return Characteristicsrows;
    }
    private formSpRPRSEachCharacteristicRow(data: any, portfolioTypeKeys: string[], finalKeys: string[]) {
        const specifportRows: WorkbookSheetRow[] = [];
        if (data && data.length > 0) {
            portfolioTypeKeys.forEach((portKeys, index) => {
                const portfolioData = data[portKeys];
                const tempRow: WorkbookSheetRow = {}
                const eachRowcellsDataData = finalKeys.map(specificKeys => {
                    {
                        return {
                            value: portfolioData[specificKeys] ? CocInputsNumberUtil.ConvertToFixedFractionDigits(portfolioData[specificKeys], false, 2) : 'N/A',
                            color: this.krollshadow,
                            verticalAlign: 'center',
                            fontFamily: 'Arial',
                            fontSize: 14.66,
                            format: '#,##0.00',
                            ...this.mapBordersToCell(),
                        } as WorkbookSheetRowCell
                    }
                });
                eachRowcellsDataData.unshift({
                    value: this.rPRsharedService.portfolioTypes[index],
                    color: this.krollshadow,
                    verticalAlign: 'center',
                    fontFamily: 'Arial',
                    fontSize: 14.66,
                    ...this.mapBordersToCell(),
                })
                tempRow.cells = eachRowcellsDataData;
                specifportRows.push(tempRow);
            });
        } else {
            specifportRows.push({});
        }

        return specifportRows;
    }



    //#endregion


    //#region COMMON SP-HFR
    private exportspHighFinancialRiskStudy(data: any, headerValue: string, name: string, dataAsOf: string): WorkbookSheet[] {
        const workbook = new Workbook();
        workbook.sheets = [] as WorkbookSheet[];
        const headers = ['Z-Score Portfolio', 'Zone', 'Industry', headerValue];
        var PrimaryDataTable, SupplymentaryDataTable;

        PrimaryDataTable = {
            name: name,
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [

                {
                    cells: [this.styleMainHeader('High-Financial-Risk Study - Data as of ' + moment(dataAsOf).format('MM/DD/YYYY'), 3)]

                },
                {
                    cells: []
                },
                {
                    cells: [
                        {
                            value: 'Companies Ranked by',
                            bold: true,
                            fontFamily: 'Arial',
                            color: this.krollshadow,

                        },
                        {
                            value: 'z-score',
                            bold: true,
                            color: this.krollblue,
                            fontFamily: 'Arial',
                        },
                    ]
                },

                {
                    cells: this.tableHeadCellFormater(headers)
                },
                ...this.getRowsFromData(data, false)

            ]
        } as WorkbookSheet;

        SupplymentaryDataTable = {
            ...this.sheetCreation(name + '-Supp'),
            rows: [
                {
                    cells: [this.styleMainHeader('Supplementary-High-Financial-Risk Study Data - Data as of ' + moment(dataAsOf).format('MM/DD/YYYY'), 3)]

                },
                {
                    cells: []
                },
                this.commonCompanyRankedBy('z-score'),
                {
                    cells: this.tableHeadCellFormater(this.supplyHighFincRiskStdy)
                },
                ...this.getRowsFromData(data, true)
            ]

        } as WorkbookSheet;

        if ((this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.Coc && this.cocInpustSharedService.isviewSupplymentrylinks == false) ||
            (this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.IneAndCoc && this.cocInpustSharedService.cocInputValues.investorPerspectiveregion === 'United States' && this.cocInpustSharedService.isviewSupplymentrylinks == false)) {
            workbook.sheets.push(PrimaryDataTable);
        } else {
            workbook.sheets.push(PrimaryDataTable);
            workbook.sheets.push(SupplymentaryDataTable);
        }

        return workbook.sheets;
    }

    private getRowsFromData(arayData: HighFinancialRiskStudy[], isSupplymentry: boolean): WorkbookSheetRow[] {

        const dataRows = arayData.map(item => {
            const data = { cells: this.getZscoreCellFormater(item, isSupplymentry) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }


    private getZscoreCellFormater(rowdata: any, isSupplemetryData: boolean) {
        let eachCRowData: any[] = [];
        if (isSupplemetryData) {
            let row = { ...rowdata };
            Object.keys(row).filter(d => !this.filterHrfElementd.includes(d)).map(s => row[s] = row[s] / 100);
            eachCRowData = this.hrf_Supp_KeyElements.map(element => {
                return this.getSizePremiumCell(row, element);
            })
        } else {
            eachCRowData = this.hrfKeyElements.map(element => {
                return this.getSizePremiumCell(rowdata, element);
            })
        }

        return eachCRowData;

    }
    //#endregion


    //#region  RPoverRfr-RPRS

    private exportrpoverRfr() {
        const workbook = new Workbook();
        workbook.sheets = [] as WorkbookSheet[];
        var PrimaryDataTable, SupplymentaryDataTable;

        PrimaryDataTable = {
            name: 'RPoverRfr-RPRS',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [this.styleMainHeader('Risk Premium Report Size Study - Data as of ' + moment(this.cocInpustSharedService.rporfrDataAsOf).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },

                ...this.getAllSheetRows()


            ]

        } as WorkbookSheet;

        SupplymentaryDataTable = this.commonSizeStudtSupplyData('RPoverRfr-RPRS-Supp', this.cocInpustSharedService.rporfrDataAsOf);

        if ((this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.Coc && this.cocInpustSharedService.isviewSupplymentrylinks == false) ||
            (this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.IneAndCoc && this.cocInpustSharedService.cocInputValues.investorPerspectiveregion === 'United States' && this.cocInpustSharedService.isviewSupplymentrylinks == false)) {
            workbook.sheets.push(PrimaryDataTable);
        } else {
            workbook.sheets.push(PrimaryDataTable);
            workbook.sheets.push(SupplymentaryDataTable);
        }

        return workbook.sheets;
    }


    private getAllSheetRows(): WorkbookSheetRow[] {
        let rprsstudy: WorkbookSheetRow[] = [];
        Object.keys(RiskPremiumReportSizeStudy).forEach((key) => {
            let type = RiskPremiumReportSizeStudy[key] as InputType;

            let arrayRPRSSLeveredTypePortfoio = this.cocInpustSharedService.arrayLeveredPortfolios.filter(item => item.InputType === InputType[type]).map(port => {
                return {
                    id: + port.Id,
                    name: port.Name,
                    dataAsOf: port.DataAsOf,
                    lowBreakPoint: port.Lower,
                    highBreakPoint: port.Upper,
                    leveredRPORFR: port.Result * 100,
                } as LeveredPortfolio
            });

            let arrayRPRSSUnleveredTypePortfolio = this.cocInpustSharedService.arrayUnleveredPortfolios.filter(item => item.InputType === InputType[type]).map(port => {
                return {
                    id: "" + port.Id,
                    name: port.Name,
                    lowBreakPoint: port.Lower,
                    highBreakPoint: port.Upper,
                    unleveredRPORFR: port.Result * 100,
                } as UnleveredPortfolio
            });

            let arrayRPSTypePortfolio = arrayRPRSSLeveredTypePortfoio.map((leveredItem, index) => {
                const unleveredItem = arrayRPRSSUnleveredTypePortfolio.find(unleveredItem => +unleveredItem.id === leveredItem.id);
                return {
                    id: "" + leveredItem.id,
                    name: leveredItem.name,
                    dataAsOf: leveredItem.dataAsOf,
                    lowBreakPoint: leveredItem.lowBreakPoint,
                    highBreakPoint: leveredItem.highBreakPoint,
                    leveredRPORFR: leveredItem.leveredRPORFR,
                    unleveredRPORFR: unleveredItem?.unleveredRPORFR
                } as RiskPremiumPortfolio

            });

            // arrayRPSTypePortfolio.map((item, index) => {
            //     item.lowBreakPoint = (index === arrayRPSTypePortfolio.length - 1) ? "<" + item.highBreakPoint : item.highBreakPoint
            //     item.highBreakPoint = (index === 0) ? "<" + item.highBreakPoint : item.highBreakPoint
            // })
            arrayRPSTypePortfolio[0].highBreakPoint = '>' + NumberFormatUtil.numberWithCommas(+arrayRPSTypePortfolio[0].lowBreakPoint, 2)
            arrayRPSTypePortfolio[arrayRPSTypePortfolio.length - 1].lowBreakPoint = '<' + NumberFormatUtil.numberWithCommas(+arrayRPSTypePortfolio[arrayRPSTypePortfolio.length - 1].highBreakPoint, 2)

            rprsstudy.push(
                ...this.getEachCompanyRows(key, arrayRPSTypePortfolio),
                this.regressionHeader(),
                this.regressionEquationMappingCellMapping(this.cocInpustSharedService.rprRegressionAppendixA[type], key, 'Levered Smoothed Premium'),
                this.regressionEquationMappingCellMapping(this.cocInpustSharedService.rprRegressionAppendixC[type], key, 'Unlevered Smoothed Premium'),
                { cells: [{}] }

            );
        });
        return rprsstudy
    }


    private getEachCompanyRows(cmpanyRankedBy: string, araydata: RiskPremiumPortfolio[]): WorkbookSheetRow[] {
        const headers = ['Portfolio', 'Low End Breakpoint ($M)', 'High End Breakpoint ($M)', 'Levered Risk Premium over the Risk-free Rate', 'Unlevered Risk Premium over the Risk-free Rate'];


        if (cmpanyRankedBy == 'Employees') {
            headers[1] = 'Low End Breakpoint'
            headers[2] = 'High End Breakpoint'
        }

        let sheetrows: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: 'Companies Ranked by',
                        bold: true,
                        fontFamily: 'Arial',
                        color: this.krollshadow,

                    },
                    {
                        value: cmpanyRankedBy,
                        bold: true,
                        color: this.krollblue,
                        fontFamily: 'Arial',

                    },
                ]
            },
            {
                cells: this.tableHeadCellFormater(headers),
            },
            ...this.RpOverRfrRiskPremOverRSstudy(araydata)

        ]

        return sheetrows;
    }

    private RpOverRfrRiskPremOverRSstudy(tableData: RiskPremiumPortfolio[]): WorkbookSheetRow[] {
        const dataRows = tableData.map(item => {
            const data = { cells: this.RpOverRfrRiskPremOverRSstudyCellsFormating(item) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }

    private RpOverRfrRiskPremOverRSstudyCellsFormating(rowdata: any): WorkbookSheetRowCell[] {
        const eachCRowData = Object.keys(rowdata).filter(key => key !== 'dataAsOf' && key !== 'name').map(element => {
            return {

                value: element == 'id' ? (+rowdata[element]) : (element == 'leveredRPORFR' || element == 'unleveredRPORFR') ? CocInputsNumberUtil.ConvertToFixedFractionDigits(rowdata[element], false, 2) / 100 : CocInputsNumberUtil.ConvertToFixedFractionDigits(rowdata[element], false, 2),
                color: this.krollshadow,
                fontSize: 14.66,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                ...this.mapBordersToCell(),
                format: (element !== 'id' && element !== 'leveredRPORFR' && element !== 'unleveredRPORFR') ? '#,##0.00' : element == 'id' ? '' : '0.00%'

            } as WorkbookSheetRowCell
        })

        return eachCRowData;

    }




    //#endregion


    //#region  RPoverRFR-rpriskS

    private exportrpoverRiskPRRiskStudy() {
        const workbook = new Workbook();
        workbook.sheets = [] as WorkbookSheet[];

        var PrimaryDataTable, SupplymentaryDataTable;

        PrimaryDataTable = {
            name: 'RPOverRfr-RPRR',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [this.styleMainHeader('Risk Premium Report Risk Study - Data as of ' + moment(this.cocInpustSharedService.rporfrDataAsOf).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },

                ...this.getRPoverRiskFreeRaterows(false)


            ]

        } as WorkbookSheet;

        SupplymentaryDataTable = {
            ...this.sheetCreation('RPOverRfr-RPRR-Supp'),
            rows: [
                {
                    cells: [this.styleMainHeader('Supplementary Risk Premium Report Risk Study Data - Data as of ' + moment(this.cocInpustSharedService.rporfrDataAsOf).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },
                ...this.getRPoverRiskFreeRaterows(true)

            ]
        } as WorkbookSheet;

        if ((this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.Coc && this.cocInpustSharedService.isviewSupplymentrylinks == false) ||
            (this.cocInpustSharedService.usrPerms.SubsType === EnumSubsType.IneAndCoc && this.cocInpustSharedService.cocInputValues.investorPerspectiveregion === 'United States' && this.cocInpustSharedService.isviewSupplymentrylinks == false)) {
            workbook.sheets.push(PrimaryDataTable);
        } else {
            workbook.sheets.push(PrimaryDataTable);
            workbook.sheets.push(SupplymentaryDataTable);
        }

        return workbook.sheets;
    }

    public getRPoverRiskFreeRaterows(isSupplymentry: boolean): WorkbookSheetRow[] {
        let rprsRiskstudy: WorkbookSheetRow[] = [];
        Object.keys(RiskPremiumReportRiskStudy).forEach((key) => {
            let type = RiskPremiumReportRiskStudy[key] as InputType;
            let arrayReportRiskStudy = this.cocInpustSharedService.arrayRiskStudyPortfollios.filter(item => item.InputType === InputType[type]).map((port: any) => {
                return {
                    id: + port.Id,
                    name: port.Name,
                    lowBreakPoint: port.Lower,
                    highBreakPoint: port.Upper,
                    leveredRPORFR: port.Result,
                    numberOfCompanies: port.NumberOfCompanies,
                    arithameticMeanReturn: port.ArithmeticMeanReturn,
                    geometricMeanReturn: port.GeometricMeanReturn,
                    sumBetaSince1963: port.SumBetaSince1963,
                    standardDeviation: port.StandardDeviationOfReturns,
                    portofolioSize: port.PortofolioSize,
                    avgDebtMvc: port.AverageDebtMvic,
                } as LeveredPortfolio
            });
            arrayReportRiskStudy[0].highBreakPoint = '>' + NumberFormatUtil.numberWithCommas
                (+arrayReportRiskStudy[0].lowBreakPoint * 100, 2) + "%"
            arrayReportRiskStudy[arrayReportRiskStudy.length - 1].lowBreakPoint = '<' + NumberFormatUtil.numberWithCommas(+arrayReportRiskStudy[arrayReportRiskStudy.length - 1].highBreakPoint * 100, 2) + "%"

            if (isSupplymentry) {
                rprsRiskstudy.push(
                    ...this.getEachRPRRSCompanyRows(key, arrayReportRiskStudy, isSupplymentry),
                    { cells: [{}] }
                )
            } else {
                rprsRiskstudy.push(
                    ...this.getEachRPRRSCompanyRows(key, arrayReportRiskStudy, isSupplymentry),
                    this.regressionHeader(),
                    this.regressionEquationMappingCellMapping(this.cocInpustSharedService.rprRegressionAppendixD[type], key, 'Smoothed Premium'),
                    { cells: [{}] }
                );
            }



        });
        return rprsRiskstudy;

    }

    private getEachRPRRSCompanyRows(cmpanyRankedBy: string, araydata: LeveredPortfolio[], isSupplymentry: boolean): WorkbookSheetRow[] {
        const headers = isSupplymentry ? this.RPRRSsupplyDataHeaders : ['Portfolio', 'Low End Breakpoint', 'High End Breakpoint', 'Levered Risk Premium over the Risk-free Rate'];


        // if (cmpanyRankedBy == 'Coefficient of Variation of Operating Margin' || cmpanyRankedBy == 'Operating Margin') {
        //     headers[1] = 'Low End Breakpoint'
        //     headers[2] = 'High End Breakpoint'
        // }


        let sheetrows: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: 'Companies Ranked by',
                        bold: true,
                        fontFamily: 'Arial',
                        color: this.krollshadow,

                    },
                    {
                        value: cmpanyRankedBy,
                        bold: true,
                        color: this.krollblue,
                        fontFamily: 'Arial',

                    },
                ]
            },
            {
                cells: this.tableHeadCellFormater(headers),
            },
            ...this.RpOverRfrRiskPremOverRISKSstudytableRows(araydata, isSupplymentry)

        ]

        return sheetrows;
    }

    private RpOverRfrRiskPremOverRISKSstudytableRows(tblData: LeveredPortfolio[], isSuplemtry: boolean): WorkbookSheetRow[] {
        const dataRows = tblData.map((item, index) => {
            const data = { cells: this.RPOVERRFRCellformating(item, isSuplemtry, index, tblData.length) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }

    private RPOVERRFRCellformating(rowdata: any, isSupplymentry: boolean, index: number, lengthArr: number): WorkbookSheetRowCell[] {
        // const eachCRowData = Object.keys(rowdata).filter(key => key !== 'dataAsOf' && key !== 'name').map(element => {
        //     return this.getRpOverRfreeRateCell(rowdata, element)
        // })


        let eachCRowData: any[] = [];
        if (isSupplymentry) {
            eachCRowData = this.rpSuppRFRRiskReportStudyKeyElements.map(element => {
                return this.getRpOverRfreeRateCell(rowdata, element, index, lengthArr);
            })
        } else {
            eachCRowData = this.rpRFRRiskReportStudyKeyElements.map(element => {
                return this.getRpOverRfreeRateCell(rowdata, element, index, lengthArr);
            })
        }

        return eachCRowData;



    }

    public getRpOverRfreeRateCell(rowdata: any, element: string, index: number, lengthArr: number) {
        return {

            // value: (element == 'lowBreakPoint' || element == 'highBreakPoint' || element == 'leveredRPORFR' || element == 'arithameticMeanReturn' || element == 'geometricMeanReturn' || element == 'standardDeviation' || element == 'avgDebtMvc') ? CocInputsNumberUtil.ConvertToFixedFractionDigits(rowdata[element], true, 2) : (element == 'sumBetaSince1963') ? CocInputsNumberUtil.ConvertToFixedFractionDigits(rowdata[element], false, 2) : +rowdata[element],
            value: this.getRpOverRfreeRateCellValue(rowdata[element], element, index, lengthArr),
            color: this.krollshadow,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            format: (element == 'lowBreakPoint' || element == 'highBreakPoint' || element == 'leveredRPORFR' || element == 'arithameticMeanReturn' || element == 'geometricMeanReturn' || element == 'standardDeviation' || element == 'avgDebtMvc') ? '0.00%' : '',
            fontSize: 14.66,
            ...this.mapBordersToCell(),

        } as WorkbookSheetRowCell
    }

    public getRpOverRfreeRateCellValue(value: any, element: string, index: number, lengthArr: number): number {
        if ((index == 0 && (element === 'lowBreakPoint' || element === 'highBreakPoint')) ||
            (index == (lengthArr - 1) && (element === 'lowBreakPoint' || element === 'highBreakPoint'))) {
            return value;
        } else if (element == 'lowBreakPoint' || element == 'highBreakPoint' || element == 'leveredRPORFR' ||
            element == 'arithameticMeanReturn' || element == 'geometricMeanReturn' || element == 'standardDeviation' || element == 'avgDebtMvc') {
            return CocInputsNumberUtil.ConvertToFixedFractionDigits(value, true, 2) / 100
        } else if (element == 'sumBetaSince1963') {
            return CocInputsNumberUtil.ConvertToFixedFractionDigits(value, false, 2)
        } else {
            return +value;
        }
    }


    //#endregion


    //#region  IndustryBetasSheet
    exportIndustryBetaSheet(): WorkbookSheet[] {
        const workbook = new Workbook()
        const headers = ['Industry', 'Full Information', 'OLS', 'Sum', 'Vasicek Adjusted', 'Blume Adjusted', 'Peer Group'];
        workbook.sheets = [{
            name: 'Industry-Betas',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [this.styleMainHeader('Industry-level Betas - Data as of ' + moment(this.cocInpustSharedService.industryBetasValuationDate).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },
                {
                    cells: [{}]
                },
                {
                    cells: this.commonBetaCell()

                },
                {
                    cells: this.geoGraphicCoverage()
                },

                {
                    cells: this.tableHeadCellFormater(headers).concat({}, ...this.tableHeadCellFormater(['Full Information', 'OLS', 'Sum', 'Vasicek Adjusted', 'Blume Adjusted', 'Peer Group']))
                },
                ...this.mapIndustryBetaRowData(this.cocInpustSharedService.leveredBetas, this.cocInpustSharedService.getUnleverdBetaforExport())



            ]
        }]

        return workbook.sheets;
    }

    mapIndustryBetaRowData(industryData: BetaIndustryData[], unleverdData: BetaIndustryData[]) {


        const dataRows = industryData.map((item, index) => {
            const data = { cells: this.mapIndustryBetaCellData(item).concat({}, this.mapUnlverdIndustryCellData(unleverdData[index])) }
            return data as WorkbookSheetRow;
        });


        return dataRows;
    }

    private mapUnlverdIndustryCellData(rowData: any) {
        const ulverdeachCRowData = Object.keys(rowData).filter(e => e != 'industry').map(element => {
            let value = rowData[element];
            return {
                value: this.getBetaValue(element, value),
                color: this.krollshadow,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
                textAlign: 'right',
                format: (element != 'industry' && value != 0) ? '0.00' : '',
                ...this.mapBordersToCell()
            } as WorkbookSheetRowCell
        })

        return ulverdeachCRowData;
    }

    private mapIndustryBetaCellData(rowdata: any) {
        const eachCRowData = Object.keys(rowdata).map(element => {
            let value = rowdata[element];
            return {
                value: this.getBetaValue(element, value),
                color: this.krollshadow,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
                textAlign: element === 'industry' ? 'left' : 'right',
                format: (element != 'industry' && value != 0) ? '0.00' : '',
                ...this.mapBordersToCell()
            } as WorkbookSheetRowCell
        })

        return eachCRowData;

    }

    private getBetaValue(key: string, value: any) {
        if (key != 'industry') {
            return value == 0 ? 'N/A' : CocInputsNumberUtil.ConvertToFixedFractionDigits(value, false, 2);
        } else {
            return value;
        }
    }

    //#endregion


    private commonBetaCell() {
        let rowCells: WorkbookSheetRowCell[] = [
            {
                value: 'Beta Leverage',
                colSpan: 1,
                color: this.krollshadow,
                bold: true,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
            },
            {
                value: 'Levered',
                colSpan: 1,
                color: this.krollblue,
                bold: true,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
            }, {}, {}, {}, {}, {}, {},
            {
                value: 'Unlevered',
                colSpan: 1,
                color: this.krollblue,
                bold: true,

                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
            }
        ]

        return rowCells;
    }

    private geoGraphicCoverage() {
        let rowCells: WorkbookSheetRowCell[] = [
            {
                value: 'Geographic Coverage',
                colSpan: 1,
                color: this.krollshadow,
                bold: true,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
            },
            {
                value: "" + this.cocInpustSharedService.betaIndustryGeoCoverage.value,
                colSpan: 1,
                color: this.krollshadow,
                bold: true,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
            }, {}, {}, {}, {}, {}, {},
            {
                value: "" + this.cocInpustSharedService.betaIndustryGeoCoverage.value,
                colSpan: 1,
                color: this.krollshadow,
                bold: true,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
            }
        ]
        return rowCells;
    }


    //#region  Industry-RP

    private export_Industry_RP(): WorkbookSheet[] {
        const workbook = new Workbook()

        workbook.sheets = [{
            name: 'Industry-RP',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [this.styleMainHeader('Industry Risk Premium (RPi) - Data as of ' + moment(this.cocInpustSharedService.industryBetasValuationDate).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },
                ...this.mapRPIData()
            ]
        }]

        return workbook.sheets
    }

    private mapRPIData(): WorkbookSheetRow[] {

        let rpisheetRows: WorkbookSheetRow[] = [];
        const headers = ['Industry', 'Full Information', 'OLS', 'Sum', 'Vasicek Adjusted', 'Blume Adjusted', 'Peer Group'];
        const unleverdHeadrs = ['Full Information', 'OLS', 'Sum', 'Vasicek Adjusted', 'Blume Adjusted', 'Peer Group'];
        let dataOption = this.cocInpustSharedService.industryRiskPremia.value ? ("" + this.cocInpustSharedService.industryRiskPremia.value) : this.cocInpustSharedService.initialIndustryRiskPremium;

        let rpLeverdData = this.cocInpustSharedService.getERPIRPValuesforExport(dataOption, BetaLeverage.LeveredBetas)
        let rpUnlverdDataOption = this.cocInpustSharedService.getERPIRPValuesforExport(dataOption, BetaLeverage.UnleveredBetas)
        let sheetRow: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: `ERP used in RPi`,
                        colSpan: 1,
                        color: this.krollshadow,
                        bold: true,
                        verticalAlign: 'center',
                        fontFamily: 'Arial',
                        fontSize: 14.66,
                    },
                    {
                        value: dataOption,
                        colSpan: 1,
                        color: this.krollshadow,
                        verticalAlign: 'center',
                        fontFamily: 'Arial',
                        fontSize: 14.66,
                    },

                ]
            },
            {
                cells: this.commonBetaCell()
            },
            {
                cells: this.geoGraphicCoverage()
            },
            {
                cells: this.tableHeadCellFormater(headers).concat({}, ...this.tableHeadCellFormater(unleverdHeadrs))
            },
            ...this.mapRpiRowData(rpLeverdData, rpUnlverdDataOption),

        ];
        rpisheetRows.push(...sheetRow);


        return rpisheetRows;


    }

    private mapRpiRowData(rpileverdData: any[], rpiUnleverdData: any[]): WorkbookSheetRow[] {
        const dataRows = rpileverdData.map((item, index) => {
            const data = { cells: this.mapRpiCellData(item).concat({}, this.mapRpiUnleverdData(rpiUnleverdData[index])) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }

    private mapRpiCellData(rowdata: any) {
        const eachCRowcellData = Object.keys(rowdata).map(element => {
            let value = rowdata[element];
            return {
                value: this.getRpValue(element, value),
                color: this.krollshadow,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
                textAlign: element === 'industry' ? 'left' : 'right',
                format: (element != 'industry' && value != 0) ? '0.00%' : '',
                ...this.mapBordersToCell(),

            } as WorkbookSheetRowCell
        })

        return eachCRowcellData;

    }

    private mapRpiUnleverdData(rowdata: any) {
        const eachCRowcellData = Object.keys(rowdata).filter(key => key != 'industry').map(element => {
            let value = rowdata[element];
            return {
                value: this.getRpValue(element, value),
                color: this.krollshadow,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
                textAlign: element === 'industry' ? 'left' : 'right',
                format: (element != 'industry' && value != 0) ? '0.00%' : '',
                ...this.mapBordersToCell(),

            } as WorkbookSheetRowCell
        })

        return eachCRowcellData;

    }

    private getRpValue(key: string, value: any) {
        if (key != 'industry') {
            return value == 0 ? 'N/A' : CocInputsNumberUtil.ConvertToFixedFractionDigits(value, false, 2) / 100;
        } else {
            return value;
        }
    }

    //#endregion


    //#region Debt-Betas

    private exportDebtBetas() {
        const workbook = new Workbook()

        workbook.sheets = [{
            name: 'Debt-Betas',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [this.styleMainHeader('Debt Betas by Credit Rating - Data as of ' + moment(this.cocInpustSharedService.usDebtBetasValuationDate).format('MM/DD/YYYY'), 3)]
                },
                {
                    cells: [{}]
                },
                {
                    cells: this.tableHeadCellFormater(['Moodys Ratings', 'Value']),
                },
                ...this.mapDebtDataRows(this.cocInpustSharedService.usDebtBetaValues)
            ]
        }]

        return workbook.sheets
    }

    private mapDebtDataRows(debtData: any): WorkbookSheetRow[] {
        const dataRows = debtData.map((item: any) => {
            const data = { cells: this.mapDebtCellData(item) }
            return data as WorkbookSheetRow;
        });
        return dataRows;
    }

    private mapDebtCellData(objData: any): WorkbookSheetRowCell[] {
        const eachCRowcellData = Object.keys(objData).map(element => {
            let value = objData[element];
            return {
                value: (element != 'moodysRatings') ? CocInputsNumberUtil.ConvertToFixedFractionDigits(value, false, 2) : value,
                color: this.krollshadow,
                verticalAlign: 'center',
                fontFamily: 'Arial',
                fontSize: 14.66,
                textAlign: 'right',
                format: (element != 'moodysRatings') ? '0.00' : '',
                ...this.mapBordersToCell(),

            } as WorkbookSheetRowCell
        });

        return eachCRowcellData;
    }

    //#endregion


    //#region common shared
    private styleMainHeader(title: string, colSpan: number): WorkbookSheetRowCell {

        const headerCell: WorkbookSheetRowCell = {
            value: title,
            colSpan: colSpan,
            color: this.krollblue,
            bold: true,
            fontSize: 16,
            verticalAlign: 'center',
            fontFamily: 'Arial'
        }

        return headerCell;
    }

    private styletblHeader(title: string, colSpan: number, index?: number): WorkbookSheetRowCell {

        const headerCell: WorkbookSheetRowCell = {
            value: title,
            colSpan: colSpan,
            color: this.krollshadow,
            bold: true,
            background: betaBackGround,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            fontSize: 14.66,
            ...this.mapBordersToCell(),
        }

        if (index && index != 0) {
            headerCell.textAlign = 'right'
        }

        return headerCell;
    }

    private tableHeadCellFormater(Headercelldata: any[]): WorkbookSheetRowCell[] {

        const sheethederCell =
            Headercelldata.map((s: any, index: number) => {
                return this.styletblHeader(s, 1, index) as WorkbookSheetRowCell;
            });
        return sheethederCell;
    }

    //#endregion


    generateWorkBook(component: ExcelExportComponent, type: number) {
        this.spinner.begin();
        const dataSourcePayload = this.getDataSourcePayload();

        var arayObservable = [
            this.measureClient.getAboutExcelDetails(dataSourcePayload),
        ]
        if (type == enumExcelExportSheets.SPRiskPremiumReportSizeStudy || type == enumExcelExportSheets.RiskPremiumReportSizeStudy) {
            arayObservable.push(this.rPRsharedService.getRFRCharacteristicsData(this.cocInpustSharedService.cocInputValues.valuationDate))
        }
        if (type == enumExcelExportSheets.CRSPDecilesSizeStudy) {
            arayObservable.push(this.sizePremiumService.getCharacteristicData(this.cocInpustSharedService.cocInputValues.valuationDate))
        }
        if (type == enumExcelExportSheets.CRP) {
            arayObservable.push(this.csostOfEquityClient.getCountryRiskPremiumData(this.cocInpustSharedService.cocInputValues.investorCountryId, this.cocInpustSharedService.cocInputValues.valuationDate))
            arayObservable.push(this.csostOfEquityClient.getTirerMethodologyData(this.cocInpustSharedService.cocInputValues.investorCountryId, this.cocInpustSharedService.cocInputValues.valuationDate));
        }
        this.spinner.begin();
        this.rpOverRfRateDataAsOf = this.cocInpustSharedService.riskPremiumOverRFRValuationDate
        forkJoin(arayObservable).subscribe((results) => {
            this.aboutData = results[0];
            this.aboutData.SubType = this.cocInpustSharedService.usrPerms.SubsType
            this.spRPRSCharacteristicData = results[1];
            this.spCrspCharacteristicData = results[1];
            if (type == enumExcelExportSheets.CRP) {
                this.crpData = results[1];
                this.crpSuplyTier = results[2];
            }
            this.spinner.end();
            this.generateSpesificSheet(component, type)
        });


    }

    generateSpesificSheet(component: ExcelExportComponent, type: number) {
        const workbook = new Workbook();
        let sheet: WorkbookSheet[] = []
        if (type == enumExcelExportSheets.RFR) {
            sheet = this.exportRiskFreeRate(type, 'RFR');
        } else if (type == enumExcelExportSheets.ERP) {
            sheet = this.exportRiskFreeRate(type, 'ERP');
        } else if (type == enumExcelExportSheets.CRSPDecilesSizeStudy) {
            sheet = this.exportSizePremiumDeciles()
        } else if (type == enumExcelExportSheets.SPRiskPremiumReportSizeStudy) {
            sheet = this.exportSizePremiumRPRSS();
        } else if (type == enumExcelExportSheets.SPHighFinancialRiskStudy) {
            sheet = this.exportspHighFinancialRiskStudy(this.cocInpustSharedService.arrayHFRSZscore, 'Size Premium', 'SP-HFR', this.cocInpustSharedService.sizePremiumValuationDate);
        } else if (type == enumExcelExportSheets.RiskPremiumReportSizeStudy) {
            sheet = this.exportrpoverRfr();
        } else if (type == enumExcelExportSheets.RiskPremiumReportRiskStudy) {
            sheet = this.exportrpoverRiskPRRiskStudy()
        }
        else if (type == enumExcelExportSheets.HighFinancialRiskStudy) {
            sheet = this.exportspHighFinancialRiskStudy(this.cocInpustSharedService.arrayHFRSZscoreRPORFR, 'Risk Premium over the Risk-free Rate', 'RPoverRfr-HFR', this.cocInpustSharedService.rporfrDataAsOf);

        } else if (type === enumExcelExportSheets.Betas) {
            sheet = this.exportIndustryBetaSheet();
        } else if (type === enumExcelExportSheets.IndustryRiskPremium) {
            sheet = this.export_Industry_RP();
        } else if (type === enumExcelExportSheets.DebtBetas) {
            sheet = this.exportDebtBetas();
        }
        else if (type === enumExcelExportSheets.CRP) {
            if (this.cocInpustSharedService.showCrpRvData) {
                sheet = this.exportcrpData();
            }
        }
        workbook.sheets = [
            ...this.exportInputs(),
            ...sheet,
            ExportAboutSheet.getAboutDataSourceSheet(this.aboutData)
        ]
        workbook.sheets = CommonExcelExportUtil.getUpdatedCopyRigtsSheet(workbook).sheets;
        component.save(workbook);
    }



    exportAllData(component: ExcelExportComponent) {
        this.sizePremiumDataAsof = this.cocInpustSharedService.sizePremiumValuationDate;
        const dataSourcePayload = this.getDataSourcePayload();
        this.spinner.begin();

        const observables = [this.measureClient.getAboutExcelDetails(dataSourcePayload),
        this.rPRsharedService.getRFRCharacteristicsData(this.cocInpustSharedService.cocInputValues.valuationDate),
        this.sizePremiumService.getCharacteristicData(this.cocInpustSharedService.cocInputValues.valuationDate)]

        if (this.cocInpustSharedService.usrPerms.SubsType != EnumSubsType.Coc) {
            observables.push(this.csostOfEquityClient.getCountryRiskPremiumData(this.cocInpustSharedService.cocInputValues.investorCountryId, this.cocInpustSharedService.cocInputValues.valuationDate),
                this.csostOfEquityClient.getTirerMethodologyData(this.cocInpustSharedService.cocInputValues.investorCountryId, this.cocInpustSharedService.cocInputValues.valuationDate))
        }

        forkJoin(observables).subscribe((results) => {
            this.aboutData = results[0];
            this.aboutData.SubType = this.cocInpustSharedService.usrPerms.SubsType;
            this.spRPRSCharacteristicData = results[1]
            this.spCrspCharacteristicData = results[2]
            this.crpData = results[3]
            this.crpSuplyTier = results[4]
            this.spinner.end()
            const workbook = new Workbook();
            if (this.cocInpustSharedService.usrPerms.SubsType == EnumSubsType.Ine || this.cocInpustSharedService.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.cocInpustSharedService.cocInputValues.investorPerspectiveregion != "United States") {
                workbook.sheets = [...this.ExportBasedOnDataAvailable()]
            } else {
                workbook.sheets = [
                    ...this.exportInputs(),
                    ...this.exportRiskFreeRate(enumExcelExportSheets.RFR, 'RFR'),
                    ...this.exportRiskFreeRate(enumExcelExportSheets.ERP, 'ERP'),
                    ...this.exportSizePremiumDeciles(),
                    ...this.exportSizePremiumRPRSS(),
                    ...this.exportspHighFinancialRiskStudy(this.cocInpustSharedService.arrayHFRSZscore, 'Size Premium', 'SP-HFR', this.cocInpustSharedService.sizePremiumValuationDate),
                    ...this.exportrpoverRfr(),
                    ...this.exportrpoverRiskPRRiskStudy(),
                    ...this.exportspHighFinancialRiskStudy(this.cocInpustSharedService.arrayHFRSZscoreRPORFR, 'Risk Premium over the Risk-free Rate', 'RPoverRfr-HFR', this.cocInpustSharedService.rporfrDataAsOf),
                    ...this.exportIndustryBetaSheet(),
                    ...this.export_Industry_RP()
                ]

                //Export US Debt Betas only, when Investor perspective AND GeoCoverage is Unite States
                if (this.cocInpustSharedService.usDebtBetaValues?.length > 0 && this.cocInpustSharedService.betaIndustryGeoCoverage.value === "United States") {
                    workbook.sheets.push(...this.exportDebtBetas());
                }

                if (this.cocInpustSharedService.showCrpRvData && this.cocInpustSharedService.usrPerms.SubsType != EnumSubsType.Coc) {
                    workbook.sheets.push(...this.exportcrpData());
                }

                workbook.sheets.push(ExportAboutSheet.getAboutDataSourceSheet(this.aboutData));

            }
            workbook.sheets = CommonExcelExportUtil.getUpdatedCopyRigtsSheet(workbook).sheets;
            component.save(workbook);
        });

    }


    public ExportBasedOnDataAvailable(): WorkbookSheet[] {

        const sheets = [];
        sheets.push(...this.exportInputs())

        if (this.cocInpustSharedService.usrPerms.SubsType == EnumSubsType.Ine || (this.cocInpustSharedService.usrPerms.SubsType == EnumSubsType.IneAndCoc && this.cocInpustSharedService.cocInputValues.investorPerspectiveregion != "United States")) {

            if (this.cocInpustSharedService.rf.length > 0) {
                sheets.push(...this.exportRiskFreeRate(enumExcelExportSheets.RFR, 'RFR'))
            }
            if (this.cocInpustSharedService.erp.length > 0) {
                sheets.push(...this.exportRiskFreeRate(enumExcelExportSheets.ERP, 'ERP'))
            }
            if (this.cocInpustSharedService.intlIndustryBetasResponse.length > 0 && this.cocInpustSharedService.intlIndustryBetasResponse[0].ViewInputBetas?.length > 0) {
                sheets.push(...this.exportIndustryBetaSheet(),
                    ...this.export_Industry_RP())
                //...this.exportDebtBetas())
            }

            if (this.cocInpustSharedService.showCrpRvData && this.crpData?.length > 0) {
                sheets.push(...this.exportcrpData());
            }

            sheets.push(ExportAboutSheet.getAboutDataSourceSheet(this.aboutData));
        }

        return sheets;





    }

    public exportcrpData(): WorkbookSheet[] {
        const objWorkBook = new Workbook();
        objWorkBook.sheets = [
            {
                name: 'CRP-and-RV',
                columns: Array(12).fill({ width: this.columnWidth }),
            }
        ]

        if (this.crpData.length > 0) {
            if (this.crpData.length > 0) {
                const investdetails = crpDataMap(this.crpData);
                objWorkBook.sheets[0].rows = crpExcelSheet.getCrpSheet(investdetails);
            }
            objWorkBook.sheets.push(this.exportCrpSupplyData())
        }


        return objWorkBook.sheets;
    }


    public exportCrpSupplyData(): WorkbookSheet {
        this.crpData.forEach(obj => {
            obj.Tier = this.crpSuplyTier.find(item => obj.InvesteeContryId == item.InvesteeCountryID)?.Tier;
        });
        const sheet: WorkbookSheet = {
            name: 'CRP-and-RV-Supp',
            columns: Array(12).fill({ width: this.columnWidth }),
            rows: crpExcelSheet.getCrpSupplySheetData(this.crpData)
        }

        return sheet;
    }

    public getDataSourcePayload(): DataSourcePayLoad {

        const dataSourcePayload: DataSourcePayLoad = {
            DataAsof: this.cocInpustSharedService.cocInputValues.valuationDate,
            RfCountries: ExportAboutSheet.intlSheetReuiredCountries.RiskFreeRateCountries,
            ErpCountries: ExportAboutSheet.intlSheetReuiredCountries.ErpCountries,
            SubscriptionType: this.cocInpustSharedService.usrPerms.SubsType
        }

        return dataSourcePayload;
    }


    //#region Beta   Company Export

    generateCompanyExcelSheet(component: ExcelExportComponent, betaType: string, Industry: string) {
        this.spinner.begin()
        const dataSourcePayload = this.getDataSourcePayload();
        this.measureClient.getAboutExcelDetails(dataSourcePayload).onceDefined((data) => {
            this.aboutData = data;
            this.aboutData.SubType = this.cocInpustSharedService.usrPerms.SubsType;
            this.spinner.end()
            const workbook = new Workbook()

            workbook.sheets = [
                ...this.exportInputs(),
                {
                    name: 'Industry-Betas-Co-List',
                    rows: [
                        {
                            cells: [this.styleMainHeader("Industry Company List - Data as of " + moment(this.cocInpustSharedService.industryBetasValuationDate).format('MM/DD/YYYY'), 3)]
                        },
                        {

                        },

                        {
                            cells: [this.getSheetHeaderName('Industry', 1), this.styleMainHeader(Industry, 1),]
                        },
                        {
                            cells: [this.getSheetHeaderName('Beta Type', 1), this.styleMainHeader(betaType, 1),]
                        },
                        {
                            cells: [this.styletblHeader("Company", 1)]
                        },
                        ...this.getCompanyRows(),

                    ]
                },
                ExportAboutSheet.getAboutDataSourceSheet(this.aboutData)
            ]
            component.save(workbook);
        });

    }


    getCompanyRows(): WorkbookSheetRow[] {
        const CompanyRows = this.cocInpustSharedService?.BetaComapniesInfo.map((value => {
            return {
                cells: [{
                    value: value,
                    color: this.krollshadow,
                    verticalAlign: 'center',
                    fontFamily: 'Arial',
                    fontSize: 14.66,
                }] as WorkbookSheetRowCell[]
            } as WorkbookSheetRow
        }));

        return CompanyRows;

    }


    getSheetHeaderName(title: string, colSpan = 1) {
        const headerCell: WorkbookSheetRowCell = {
            value: title,
            colSpan: colSpan,
            color: this.krollshadow,
            bold: true,
            verticalAlign: 'center',
            fontFamily: 'Arial',
            fontSize: 14.66
        }

        return headerCell;
    }



    //#endregion

    private mapBordersToCell(): any {
        return {
            borderBottom: { size: 1, color: "#ECEEEF" },
            borderLeft: { size: 1, color: "#ECEEEF" },
            borderRight: { size: 1, color: "#ECEEEF" },
            borderTop: { size: 1, color: "#ECEEEF" },
        };
    }


}



