import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { BetaEstimate } from 'src/app/_navigator/data/model/beta-estimate.model';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { BetaEstimatesListState } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-state';
import { EstimatesListState } from 'src/app/_navigator/estimates-list-store/estimates-list-state';
import { EstimateModule } from './estimate-module.model';
import { MenuDetails } from '../home.menu.model';
import { MenuType } from '../menu.type';
import { EstimateTitle, EstimateType } from 'src/app/_api/enums/estimate-type';
import { NewEstimateModalComponent } from '../_modals/new-estimate-modal.component';
import { CreateEstimate } from 'src/app/_navigator/estimates-list-store/estimates-list-actions';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { EstimateSharedService } from './estimate-shared-service';
import { NewBetaEstimateModalComponent } from '../_modals/new-beta-estimate-modal.component';
import { CreateBetaEstimate } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-actions';

@Component({
    templateUrl: './estimates.component.html'
})
export class EstimatesComponent extends SubscriberEntity implements OnInit {
    public estimateModules: EstimateModule[] = [];
    public currentModule!: EstimateModule;
    public module = {} as MenuDetails;
    public sectionHeader = 'Cost of Capital Tools';
    public Header!: string;

    @Select(EstimatesListState.get) public estimates!: Observable<Estimate[] | undefined>;
    @Select(BetaEstimatesListState.get) public betaEstimates!: Observable<BetaEstimate[] | undefined>;

    constructor(
        private router: Router,
        private modalManager: ModalManager,
        private dataStore: DataStore,
        private store: Store,
        private spinner: Spinner,
        private estimateSharedService: EstimateSharedService) {
        super();
    }

    public ngOnInit(): void {
        this.dataStore.countries.onceDefined((data) => {
            this.estimateSharedService.setCountries(data);
        });

        // this.estimates.onceDefined(() => {
        //     if (this.router.url.includes('us-estimates')) {
        //         this.module.type = MenuType.UsCostOfCapitalEstimates;
        //     } else if (this.router.url.includes('intl-estimates')) {
        //         this.module.type = MenuType.InternationalCostOfCapitalEstimate;
        //     } else if (this.router.url.includes('beta-estimates')) {
        //         this.module.type = MenuType.CompanyLevelBeta;
        //     }
        // });
    }



    public launchModule(): void {
        const module = this.estimateSharedService.module;
        if (module == MenuType.UsCostOfCapitalEstimates) {
            this.openNewEstimateModal(EstimateType.USEstimate, 'estimate');
        } else if (module == MenuType.InternationalCostOfCapitalEstimate) {
            this.openNewEstimateModal(EstimateType.InternationalEstimate, 'international-estimate');
        } else if (module === MenuType.CompanyLevelBeta) {
            this.openNewBetaEstimateModal(EstimateType.BetaEstimate, 'beta');
        }
    }

    private openNewEstimateModal(type: EstimateType, route: string): void {
        const modal = this.modalManager.open<string, NewEstimateModalComponent>(NewEstimateModalComponent);
        modal.once((name) => {
            const request = this.store.dispatch(new CreateEstimate(name, type));
            request.once(() => {
                this.dataStore.setIsIndustryAnalysis(false);
                this.router.navigate([route]);
            });
            this.spinner.while(request);
        });
    }

    private openNewBetaEstimateModal(type: EstimateType, route: string): void {
        const modal = this.modalManager.open<string, NewBetaEstimateModalComponent>(NewBetaEstimateModalComponent);
        modal.once((name) => {
            const request = this.store.dispatch(new CreateBetaEstimate(name, type));
            request.once(() => {
                this.router.navigate([route]);
            });
            this.spinner.while(request);
        });
    }
}
