import { NgModule } from '@angular/core';
import { DialogModule } from 'primeng/dialog';
import { CommonModules } from '../_config/common-modules';
import { BenchmarkingService } from '../_navigator/data/service/benchmarking.service';
import { TrendsOverTimePDFExportService } from '../_navigator/data/service/trends-over-time-pdf-export.service';
import { AcceptTermsModalComponent } from './_modals/accept-terms-modal.component';
import { TermsComponent } from './_modals/term-model.component';
import { ArchiveEstimateModalComponent } from './_modals/archive-estimate-model.component';
import { ExpirationModalComponent } from './_modals/expiration-modal.component';
import { NewBetaEstimateModalComponent } from './_modals/new-beta-estimate-modal.component';
import { NewEstimateModalComponent } from './_modals/new-estimate-modal.component';
import { PrePurchaseModalComponent } from './_modals/pre-purchase-modal.component';
import { PurchaseRedirectModalComponent } from './_modals/purchase-redirect-modal.component';
import { BuyNowComponent } from './buy-now/buy-now.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { CustomerLoginComponent } from './customer-login.component';
import { DashboardModuleComponent } from './dashboard/dashboard-module.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { BetaEstimatesTableComponent } from './estimates/beta-estimates-table.component';
import { BetaEstimatesComponent } from './estimates/beta-estimates.component';
import { CocEstimatesTableComponent } from './estimates/coc-estimates-table.component';
import { EstimatesComponent } from './estimates/estimates.component';
import { IntlEstimatesComponent } from './estimates/intl-estimates.component';
import { UsEstimatesComponent } from './estimates/us-estimates.component';
import { TrendsOverTimecountryriskExcelExportService } from './exports/excel/trends-over-time-country-risk-excel-export.service';
import { TrendsOverTimeExcelExportService } from './exports/excel/trends-over-time-excel-export.service';
import { Trendsovertimefishereffectexcelexportservice } from './exports/excel/trends-over-time-fishereffect-excel-export.service';
import { HomeComponent } from './home.component';
import { ProductNewsComponent } from './product-news/product-news.component';
import { PurchaseComponent } from './purchase.component';
import { RedirectComponent } from './redirect/redirect.component';
import { ResourcesLibraryComponent } from './resources-library/resources-library.component';
import { ResourceLibraryService } from './resources-library/resources-library.service';
import { ChartComponent } from './tear-sheet/chart.component';
import { TearSheetService } from './tear-sheet/data/tear-sheet.service';
import { TrendsOverTimeService } from './tear-sheet/data/trends-over-time.service';
import { IndustryDataChartComponent } from './tear-sheet/industry-data-chart.component';
import { IndustryDataTableComponent } from './tear-sheet/industry-data-table.component';
import { LineChartComponent } from './tear-sheet/line-chart.component';
import { MetricCategoryComponent } from './tear-sheet/metric-category.component';
import { MetricRowComponent } from './tear-sheet/metric-row.component';
import { MetricComponent } from './tear-sheet/metric.component';
import { TrendsOverTimeCountryRiskPremiaComponent } from './tear-sheet/modals/trends-over-time-country-risk-premia.component';
import { TrendsOverTimeFishereffectComponent } from './tear-sheet/modals/trends-over-time-fishereffect/trends-over-time-fishereffect.component';
import { TrendsOverTimeModalComponent } from './tear-sheet/modals/trends-over-time-modal.component';
import { StatisticsCategoryComponent } from './tear-sheet/statistics-category.component';
import { StatisticsComponent } from './tear-sheet/statistics.component';
import { TearSheetComponent } from './tear-sheet/tear-sheet.component';
import { TrendsOverTimeCountryRiskTableComponent } from './tear-sheet/trends-over-time-country-risk-table.component';
import { TrendsOverTimeFishereffectTableComponent } from './tear-sheet/trends-over-time-fishereffect-table.component';
import { TrendsOverTimeTableComponent } from './tear-sheet/trends-over-time-table.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzCollapseModule } from 'ng-zorro-antd/collapse';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { ViewMoreOptionsComponent } from './tear-sheet/view-more-options/view-more-options.component';
import { IndustryInputTableComponent } from './tear-sheet/industry-input-table.component';
import { CommonEstimateTableComponent } from '../_navigator/common/commonTable/common-estimate-table/common-estimate-table.component';
import { EstimateSharedService } from './estimates/estimate-shared-service';


export const HomeDefinition: NgModule = {
    declarations: [
        HomeComponent,
        DashboardComponent,
        EstimatesComponent,
        ResourcesLibraryComponent,
        CustomerLoginComponent,
        PurchaseComponent,
        ContactUsComponent,
        BuyNowComponent,
        NewEstimateModalComponent,
        NewBetaEstimateModalComponent,
        AcceptTermsModalComponent,
        TermsComponent,
        PrePurchaseModalComponent,
        PurchaseRedirectModalComponent,
        ExpirationModalComponent,
        ArchiveEstimateModalComponent,
        RedirectComponent,
        TearSheetComponent,
        MetricComponent,
        MetricCategoryComponent,
        StatisticsComponent,
        StatisticsCategoryComponent,
        IndustryDataTableComponent,
        IndustryDataChartComponent,
        ChartComponent,
        MetricRowComponent,
        DashboardModuleComponent,
        CocEstimatesTableComponent,
        BetaEstimatesTableComponent,
        BetaEstimatesComponent,
        UsEstimatesComponent,
        IntlEstimatesComponent,
        TrendsOverTimeModalComponent,
        TrendsOverTimeCountryRiskPremiaComponent,
        TrendsOverTimeFishereffectComponent,
        TrendsOverTimeTableComponent,
        TrendsOverTimeCountryRiskTableComponent,
        TrendsOverTimeFishereffectTableComponent,
        LineChartComponent,
        ProductNewsComponent,
        ViewMoreOptionsComponent,
        IndustryInputTableComponent,
        CommonEstimateTableComponent

    ],
    entryComponents: [
        NewEstimateModalComponent,
        NewBetaEstimateModalComponent,
        AcceptTermsModalComponent,
        TermsComponent,
        PrePurchaseModalComponent,
        PurchaseRedirectModalComponent,
        ExpirationModalComponent,
        ArchiveEstimateModalComponent,
        TrendsOverTimeModalComponent,
        TrendsOverTimeCountryRiskPremiaComponent,
        TrendsOverTimeFishereffectComponent,

    ],
    imports: [
        ...CommonModules,
        DialogModule,
        NzButtonModule,
        NzLayoutModule,
        NzMenuModule,
        NzBreadCrumbModule,
        NzCollapseModule,
        NzIconModule,
        NzTabsModule,
    ],
    providers: [
        TearSheetService,
        BenchmarkingService,
        TrendsOverTimePDFExportService,
        TrendsOverTimeService,
        TrendsOverTimeExcelExportService,
        TrendsOverTimecountryriskExcelExportService,
        Trendsovertimefishereffectexcelexportservice,
        ResourceLibraryService,
        EstimateSharedService
    ]
};
@NgModule(HomeDefinition)
export class HomeModule { }
