import { Injectable } from '@angular/core';
import {
    ExcelExportComponent,
    Workbook,
    WorkbookSheet,
    WorkbookSheetColumn,
    WorkbookSheetRow,
    WorkbookSheetRowCell
} from '@progress/kendo-angular-excel-export';
import { ExcelExportUtil } from 'src/app/estimate/exports/util/excel-export.util';
import { Regionrisk } from '../../../benchmarking/data/trends-over-time-table-data';
import { TearSheetService } from '../../tear-sheet/data/tear-sheet.service';
import { Select } from '@ngxs/store';
import { EstimateState } from 'src/app/_navigator/estimate-store/estimate-state';
import { Observable } from 'rxjs';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { EstimateSummaryState } from 'src/app/_navigator/estimate-summary/estimate-summary-state';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { CommonExcelExportUtil } from 'src/app/_navigator/common/common-excel-export-util';
import * as moment from 'moment';

@Injectable()
export class TrendsOverTimecountryriskExcelExportService {
    private moduleName = 'International-estimate'; // TODO rename this based on subscription or url
    public columnWidth = 125;       // width of each cell in grid system
    public krollblue = '#14487f'; // color of all blue items
    public grayBackGround = '#F6F6F7'
    public numberFormat = '0.00%';   // number format for cells
    public estimate: EstimateSummary | undefined
    constructor(
        private tearSheetService: TearSheetService
    ) { }

    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;


    private saveWorksheet(component: ExcelExportComponent, tableData: Regionrisk, countryriskarray: any[] = [], CcrAvgarrayeur: any[] = [], CcrAvgarraymsci: any[] = [], CcrAvgarrayspc: any[] = [], dateArray: any[] = [], YieldSpreadeur: any[] = [], YieldSpreadmsci: any[] = [], YieldSpreadspc: any[] = [], VolatilityAvgeur: any[] = [], VolatilityAvgmsci: any[] = [], VolatilityAvgspc: any[] = [], YieldSpread: any[] = [], volatality: any[] = []): void {
        let excelExport = this.getWorksheet(tableData, countryriskarray, CcrAvgarrayeur, CcrAvgarraymsci, CcrAvgarrayspc, dateArray, YieldSpreadeur, YieldSpreadmsci, YieldSpreadspc, VolatilityAvgeur, VolatilityAvgmsci, VolatilityAvgspc, YieldSpread, volatality);
        excelExport = this.tearSheetService.setDefaultExcel(excelExport);
        component.save(excelExport);
    }

    private getWorksheet(tableData: Regionrisk, countryriskarray: any[] = [], CcrAvgarrayeur: any[] = [], CcrAvgarraymsci: any[] = [], CcrAvgarrayspc: any[] = [], dateArray: any[] = [], YieldSpreadeur: any[] = [], YieldSpreadmsci: any[] = [], YieldSpreadspc: any[] = [], VolatilityAvgeur: any[] = [], VolatilityAvgmsci: any[] = [], VolatilityAvgspc: any[] = [], YieldSpread: any[] = [], volatality: any[] = []): Workbook {
        const workbook = new Workbook();

        workbook.sheets = [{
            name: `Trends Over Time`,
            columns: Array(8).fill({ width: this.columnWidth }),
            rows: [
                ...this.getHeader(),
                ...this.buildTableData(tableData, countryriskarray, CcrAvgarrayeur, CcrAvgarraymsci, CcrAvgarrayspc, dateArray, YieldSpreadeur, YieldSpreadmsci, YieldSpreadspc, VolatilityAvgeur, VolatilityAvgmsci,
                    VolatilityAvgspc, YieldSpread, volatality),
                ...CommonExcelExportUtil.getKrollCopyrightsRows(),
            ]
        },
        this.getAaboutSheet()
        ];

        return workbook;
    }

    private getHeader(): WorkbookSheetRow[] {

        const rows: WorkbookSheetRow[] = [
            {
                cells: [
                    {
                        value: 'Investor Country: ',
                        bold: true,
                        color: this.krollblue,
                    },
                    {
                        value: this.estimate?.InvestorCountryName,
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Investee Country: ',
                        bold: true,
                        color: this.krollblue,
                    },
                    {
                        value: this.estimate?.InvesteeCountryName,
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Cash Flow Currency: ',
                        bold: true,
                        color: this.krollblue,
                    },
                    {
                        value: this.estimate?.CashFlowCurrency,
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Valuation Date: ',
                        bold: true,
                        color: this.krollblue,
                    },
                    {
                        value: this.estimate?.ValuationDate,
                    }
                ]
            },
            {
                cells: [
                    {
                        value: 'Data as of: ',
                        bold: true,
                    },
                    {
                        value: this.estimate?.CountryRiskPremiaDataAsOf,
                    }
                ]
            },
            {
                cells: [{}]
            },
            {
                cells: [
                    {
                        value: `Country Risk Indications from a ${this.estimate?.CountryOfInputsName} Perspective`,
                        bold: true,
                        color: this.krollblue,
                        ...ExcelExportUtil.mapBordersToCell()
                    }
                ]
            }
        ]


        return rows;
    }

    private getCompLabels(tabledata: Regionrisk, sectionHeader: string): WorkbookSheetRow[] {
        const country = 'Country';
        const EuromoneyRegion = 'Euromoney Region';
        const MSCI = 'MSCI';
        const Sp = 'Sp';
        const crediRating = 'Credit Rating'

        // TODO create workbook sheet row builder
        const row: WorkbookSheetRow[] = [
            {
                cells: [

                    this.buildScenarioHeader(sectionHeader)
                ]
            },

            {
                cells: [{
                    value: country + ' (' + tabledata.trendsOverTimeData[0] + ')',
                    colSpan: 1,
                    ...ExcelExportUtil.mapBordersToCell()
                }]
            },
            {
                cells: [{
                    value: EuromoneyRegion + ' (' + tabledata.trendsOverTimeData[1] + ')',
                    colSpan: 1,
                    ...ExcelExportUtil.mapBordersToCell()
                }]
            },
            {
                cells: [{
                    value: MSCI + ' (' + tabledata.trendsOverTimeData[2] + ')',
                    colSpan: 1,
                    ...ExcelExportUtil.mapBordersToCell()
                }]
            },
            {
                cells: [{
                    value: crediRating + ' (' + tabledata.trendsOverTimeData[3] + ')',
                    colSpan: 1,
                    ...ExcelExportUtil.mapBordersToCell()
                }]
            }];

        return row;
    }

    private buildMetricTableRow(tableData: any): WorkbookSheetRow[] {
        const tableRow: WorkbookSheetRow[] = [];
        let row: WorkbookSheetRow = {};
        row.cells = [];
        let cl: WorkbookSheetColumn = {};

        // TODO construct builder to build this out and return the entire result row
        if (this.numberFormat === '0.00%') {
            tableData = tableData / 100;
        }
        cl = ExcelExportUtil.createCell(tableData, 1, this.numberFormat);
        row.cells.push(cl);
        tableRow.push(row);
        row = {};
        row.cells = [];
        return tableRow;
    }

    private buildScenarioHeader(table: string): WorkbookSheetRowCell {

        const cell: WorkbookSheetRowCell = {
            value: table,
            bold: true,
            background: this.grayBackGround,
            ...ExcelExportUtil.mapBordersToCell()

        }

        return cell;
    }

    private buildDataAsOfTitle(title: string, width: number): WorkbookSheetRow[] {

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: title,
                colSpan: width,
                bold: true,
                background: this.grayBackGround,
                textAlign: 'right',
                ...ExcelExportUtil.mapBordersToCell()
            }]
        }];

        return row;
    }

    private buildTableData(tableData: Regionrisk, countryriskarray: any[] = [], CcrAvgarrayeur: any[] = [], CcrAvgarraymsci: any[] = [], CcrAvgarrayspc: any[] = [], dateArray: any[] = [], YieldSpreadeur: any[] = [], YieldSpreadmsci: any[] = [], YieldSpreadspc: any[] = [], VolatilityAvgeur: any[] = [], VolatilityAvgmsci: any[] = [], VolatilityAvgspc: any[] = [], YieldSpread: any[] = [], volatality: any[] = []): WorkbookSheetRow[] {
        let resultData: WorkbookSheetRow[] = [];
        let scenarioHeader: string = '';
        let rightSideTableData: WorkbookSheetRow[] = [];
        let combineCompLables: WorkbookSheetRow[] = [];
        const titleWidth = 1;
        let tableTitle;
        let tableRow;
        for (let i = 0; i < 3; i++) {
            if (i === 0 && (countryriskarray.length > 0 || CcrAvgarrayeur.length > 0 || CcrAvgarraymsci.length > 0 || CcrAvgarrayspc.length > 0)) {
                this.numberFormat = '0.00%';
                scenarioHeader = 'Country Credit Rating Model';
                for (let j = 0; j < dateArray.length; j++) {
                    tableTitle = this.buildDataAsOfTitle(dateArray[j], titleWidth);
                    if (countryriskarray.length > 0) {
                        tableRow = this.buildMetricTableRow(countryriskarray[j].y);
                        tableTitle = tableTitle.concat(tableRow);
                    }
                    if (CcrAvgarrayeur.length > 0) {
                        const ccreur = this.buildMetricTableRow(CcrAvgarrayeur[j].y);
                        tableTitle = tableTitle.concat(ccreur);
                    }
                    if (CcrAvgarraymsci.length > 0) {
                        const ccrmsci = this.buildMetricTableRow(CcrAvgarraymsci[j].y);
                        tableTitle = tableTitle.concat(ccrmsci);
                    }
                    if (CcrAvgarrayspc.length > 0) {
                        const ccrspc = this.buildMetricTableRow(CcrAvgarrayspc[j].y);
                        tableTitle = tableTitle.concat(ccrspc);
                    }
                    if (rightSideTableData.length > 0) {
                        rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
                    } else {
                        rightSideTableData = tableTitle;
                    }

                }
            }
            if (i === 1 && (YieldSpread.length > 0 || YieldSpreadeur.length > 0 || YieldSpreadmsci.length > 0 || YieldSpreadspc.length > 0) && YieldSpread.find(s => s.y !== 0)) {
                this.numberFormat = '0.00%';
                scenarioHeader = 'Country Yield Spread Model';
                for (let j = 0; j < dateArray.length; j++) {

                    tableTitle = this.buildDataAsOfTitle(dateArray[j], titleWidth);
                    if (YieldSpread.length > 0) {
                        tableRow = this.buildMetricTableRow(YieldSpread[j].y);
                        tableTitle = tableTitle.concat(tableRow);
                    }
                    if (YieldSpreadeur.length > 0) {
                        const ccreur = this.buildMetricTableRow(YieldSpreadeur[j].y);
                        tableTitle = tableTitle.concat(ccreur);
                    }
                    if (YieldSpreadmsci.length > 0) {
                        const ccrmsci = this.buildMetricTableRow(YieldSpreadmsci[j].y);
                        tableTitle = tableTitle.concat(ccrmsci);
                    }
                    if (YieldSpreadspc.length > 0) {
                        const ccrspc = this.buildMetricTableRow(YieldSpreadspc[j].y);

                        tableTitle = tableTitle.concat(ccrspc);
                    }
                    if (rightSideTableData.length > 0) {
                        rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
                    } else {
                        rightSideTableData = tableTitle;
                    }
                }
            }
            if (i === 2 && (VolatilityAvgeur.length > 0 || volatality.length > 0 || VolatilityAvgmsci.length > 0 || VolatilityAvgspc.length > 0)) {
                this.numberFormat = '0.00';
                scenarioHeader = 'Relative Volatility Model';
                for (let j = 0; j < dateArray.length; j++) {
                    tableTitle = this.buildDataAsOfTitle(dateArray[j], titleWidth);
                    if (volatality.length > 0) {
                        tableRow = this.buildMetricTableRow(volatality[j].y);
                        tableTitle = tableTitle.concat(tableRow);
                    }
                    if (VolatilityAvgeur.length > 0) {
                        const ccreur = this.buildMetricTableRow(VolatilityAvgeur[j].y);
                        tableTitle = tableTitle.concat(ccreur);
                    }
                    if (VolatilityAvgmsci.length > 0) {
                        const ccrmsci = this.buildMetricTableRow(VolatilityAvgmsci[j].y);
                        tableTitle = tableTitle.concat(ccrmsci);
                    }
                    if (VolatilityAvgspc.length > 0) {
                        const ccrspc = this.buildMetricTableRow(VolatilityAvgspc[j].y);
                        tableTitle = tableTitle.concat(ccrspc);
                    }
                    if (rightSideTableData.length > 0) {
                        rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
                    } else {
                        rightSideTableData = tableTitle;
                    }
                }
            }
            combineCompLables = this.concatenateRows(this.getCompLabels(tableData, scenarioHeader), rightSideTableData);

            resultData = resultData.concat(combineCompLables);
            rightSideTableData = [];
        }

        return resultData;

    }

    private concatenateRows(rowDataLeft: WorkbookSheetRow[], rowDataRight: WorkbookSheetRow[]): WorkbookSheetRow[] {
        const leftWidth: number = ExcelExportUtil.getWidthOfWidestRow(rowDataLeft);

        const rowData: WorkbookSheetRow[] = [];
        for (let i = 0; i < rowDataLeft.length; ++i) {
            if (i < rowDataRight.length) { // row in right side, add it to the left
                const leftCells = rowDataLeft[i].cells;
                const rightCells = rowDataRight[i].cells;

                if (leftCells && rightCells) {
                    let newCells: WorkbookSheetRowCell[] = [];

                    newCells = leftCells.concat(rightCells);
                    rowDataLeft[i].cells = newCells;
                }

                rowData.push(rowDataLeft[i]);
            }
        }

        //
        //  process any rows on right beyond the left
        //
        for (let j: number = rowDataLeft.length; j < rowDataRight.length; ++j) {
            const rightCells = rowDataRight[j].cells;

            if (rightCells) {
                let newCells: WorkbookSheetRowCell[] = ExcelExportUtil.getEmptyCell(leftWidth);
                newCells = newCells.concat(rightCells);
                rowDataRight[j].cells = newCells;
                rowData.push(rowDataRight[j]);
            }

        }

        return rowData;
    }

    public save(component: ExcelExportComponent, tabledata: Regionrisk, countryriskarray: any[] = [], CcrAvgarrayeur: any[] = [], CcrAvgarraymsci: any[] = [], CcrAvgarrayspc: any[] = [], dateArray: any[] = [], YieldSpreadeur: any[] = [], YieldSpreadmsci: any[] = [], YieldSpreadspc: any[] = [], VolatilityAvgeur: any[] = [], VolatilityAvgmsci: any[] = [], VolatilityAvgspc: any[] = [], YieldSpread: any[] = [], volatality: any[] = []): void {

        this.estimateSummarySelector.onceDefined((data) => {

            this.estimate = data;
            this.saveWorksheet(component, tabledata, countryriskarray, CcrAvgarrayeur, CcrAvgarraymsci, CcrAvgarrayspc, dateArray, YieldSpreadeur, YieldSpreadmsci, YieldSpreadspc, VolatilityAvgeur, VolatilityAvgmsci, VolatilityAvgspc, YieldSpread, volatality);
        });


    }

    private getAaboutSheet(): WorkbookSheet {
        const aboutSheet: WorkbookSheet = {
            name: `About`,
            columns: Array(8).fill({ width: this.columnWidth }),
            rows: [
                {
                    cells: [
                        {
                            value: 'Source ',
                            bold: true,
                            color: this.krollblue
                        },
                    ]
                },
                {
                    cells: [
                        {
                            value: 'Kroll Cost of Capital Navigator: International Cost of Capital Inputs Dataset',
                        },
                    ]
                },
                {
                    cells: [
                        {
                            value: `Exported on: ${moment().format('MM/DD/YYYY')}`
                        }
                    ]
                },
                {
                    cells: [{}]
                },
                {
                    cells: [
                        {
                            value: 'Data Sources ',
                            bold: true,
                            color: this.krollblue
                        },
                    ]
                },
                {
                    cells: [{
                        value: 'Data Sources used with permission. All rights reserved. Calculations performed by Kroll, LLC.'
                    }]
                },
                {
                    cells: [{

                    }]
                },
                ...CommonExcelExportUtil.getKrollCopyrightsRows(),
            ]
        }

        return aboutSheet;
    }

}


