import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { ContextualValue } from '@concurrency/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { Select } from '@ngxs/store';
import * as moment from 'moment';
import { Dialog } from 'primeng/dialog';
import { combineLatest, Observable, of } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { CurrencyClient } from 'src/app/_api/clients/currency.client';
import { RegionClient } from 'src/app/_api/clients/region.client';
import { MinimumDate } from 'src/app/_api/responses/minimum-date.response';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { CommonDate, DateStruct } from 'src/app/_navigator/common/date-struct';
import { CommonInputConfig } from 'src/app/_navigator/common/inputs/common-user-input.component';
import { ComboboxDataType } from 'src/app/_navigator/data/enum/combobox-data-type.enum';
import { Analysis } from 'src/app/_navigator/data/model/analysis.model';
import { IndustryDataSelection } from 'src/app/_navigator/data/model/industry-data-request.model';
import { Suggestion } from 'src/app/_navigator/data/model/suggestion.model';
import { BenchmarkingService } from 'src/app/_navigator/data/service/benchmarking.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { SubscriptionLevel } from 'src/app/_navigator/user/enum/subscription-level';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { User } from 'src/app/_navigator/user/model/user.model';
import { UserStore } from 'src/app/_navigator/user/store/user.store';
import { UserUtil } from 'src/app/_navigator/user/util/user.util';
import { ValuesState } from 'src/app/_navigator/values-store/values-state';
import { InputsTexts } from 'src/app/estimate/data/inputs-texts';
import { InputsUtil } from 'src/app/estimate/inputs.util';
import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';
import { TrendsOverTimeService } from 'src/app/home/tear-sheet/data/trends-over-time.service';
import { environment } from 'src/environments/environment';
import { BenchmarkingUtil } from '../../benchmarking.util';
import { BenchmarkScenario } from '../../data/benchmark-scenario';
import { BenchmarkingQueryBuilderService } from '../../data/benchmarking-query-builder.service';
import { IndustryInputDependencies } from '../../data/industry-input-dependencies';
import { ComboBoxDisablePropeties } from '../../data/common-interfaces.model';

@Component({
    templateUrl: './benchmarking-add-industries.component.html',
    styleUrls: ['./benchmarking-add-industries.component.scss']
})

export class BenchmarkingAddIndustriesComponent extends SubscriberEntity implements OnInit {
    private iniSubData: SubscriptionData | undefined;
    private usiSubData: SubscriptionData | undefined;
    private IsMod4: boolean | undefined = false;

    public display = true;
    public dialogHeader = 'Select an Industry (up to 5)';
    public currencyOptions: Suggestion<string>[] = [];
    public errorMessages: SafeHtml[] = [];
    public minimumDate = -1;
    public inputData!: IndustryInputDependencies;
    public valuationDate!: DateStruct;
    public regionComboOptions: Suggestion<string>[] = [];
    public regionStrings = new Array<string>();
    public currencyStrings = new Array<string>();
    public valuationDateConfig!: CommonInputConfig;
    public promptTitle = InputsTexts.highLevelAnalysis;
    public productNews: any;
    public scenarios!: IndustryDataSelection[];
    public showAddScenario = true;
    public searchTerm = '';
    public industries: ContextualValue<Industry>[] = [];
    public allIndustries: Industry[] = [];
    public _allIndustriesBlankSearch: Industry[] = [];
    public totalIndustries!: number;
    public moduleName!: string;
    public userSelectedIndustries!: Industry[];
    public IndustriesFromDB: Industry[] = new Array<Industry>();
    public maximumNumberOfIndustries = 5;
    public _check!: string;
    public _filterScenario!: Industry;
    public CodeId = 0;
    public Area = "";
    public CurrencyCode = "";
    public SaveAndContinue!: Function
    public isSic!: boolean;
    public readonly sortedCurrencies: string[] = [
        "USD",
        "EUR",
        "GBP",
    ];

    public readonly sortedRegions: string[] = [
        "United States",
        "World",
        "Eurozone",
        "European Union",
        "United Kingdom"
    ]

    @ViewChild(Dialog) public dialog!: Dialog;

    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;

    public regionConfig: CommonInputConfig = {
        name: '',
        label: 'Select Region',
        // help: '',
        placeholder: 'Select a Region',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: false,
        required: true
    };

    public currencyConfig: CommonInputConfig = {
        name: '',
        label: 'Select Currency',
        // help: HelpText.BenchmarkingCurrency,
        placeholder: 'Select a Currency',
        containerClassList: 'input-flex-display inline-disable-text',
        stackedDisplay: true,
        iconWithLabel: true,
        required: true
    };

    public industryConfig: CommonInputConfig = {
        name: InputsTexts.industry,
        help: HelpText.BenchmarkingIndustry,
        disabled: true,
        placeholder: '2020 - MODULE INDUSTRY',
        containerClassList: 'input-flex-display',
        iconWithLabel: true,
        stackedDisplay: true,
        required: true
    };

    constructor(
        private http: HttpClient,
        private sanitizer: DomSanitizer,
        private dataStore: DataStore,
        private userStore: UserStore,
        private regionClient: RegionClient,
        private currencyClient: CurrencyClient,
        public benchmarkingService: BenchmarkingService,
        private spinner: Spinner,
        public benchmarkingQueryBuilderService: BenchmarkingQueryBuilderService,
        public trendsOverTimeService: TrendsOverTimeService,
        public industryDataSharingService: IndustryDataSharingService
    ) {
        super();

    }

    private updateCurrencyOptions(region: string): void {
        this.currencyOptions = this.currencyOptions.map((currencyOption) => {
            const disableOptions: ComboBoxDisablePropeties | undefined = region === 'United States' ?
                                                this.disabledTextBasedOnValue(currencyOption.value, 'USD', ' - Currency Unavailable', false) :
                                                this.disabledTextBasedOnSubscription(currencyOption.value, 'USD', true, false);
            return {
                ...currencyOption,
                disabledText: disableOptions?.disabledText
            };
        });
    }

    private disabledTextBasedOnValue(item: string, USValue: string, disableText: string, showLockIcon: boolean): ComboBoxDisablePropeties | undefined {
        const props: ComboBoxDisablePropeties = {};
        if (item !== USValue) {
            if(showLockIcon) {
                props.enableLockIcon = true;
                props.disabled = true;
                return props;
            } else {
                props.disabledText = disableText;
                return props;
            }
        }
        return undefined;
    }

    private disabledTextBasedOnSubscription(item: string, USValue: string, currency: boolean, showLockIcon: boolean): ComboBoxDisablePropeties | undefined {
        const props: ComboBoxDisablePropeties = {};
        if (!this.iniSubData && item !== USValue) {
            if(showLockIcon) {
                props.enableLockIcon = true;
                props.disabled = true;
                return props;
            } else {
                props.disabledText = ' - An International subscription is required for this item.';
                return props;
            }
        }
        if (this.iniSubData && !this.usiSubData && item === USValue && currency !== true) {
            if(showLockIcon) {
                props.enableLockIcon = true;
                props.disabled = true;
                return props;
            } else {
                props.disabledText = ' - A US subscription is required for this item.';
                return props;
            }
        }

        if (this.iniSubData && this.usiSubData && item !== USValue || (currency === true && item !== 'USD')) {
            return this.disableTextBasedOnValuationDate(currency);
        }

        return undefined;
    }

    private disableTextBasedOnValuationDate(currency: boolean): ComboBoxDisablePropeties | undefined {
        const isInbetween = this.isUsiDateSelected();
        const props: ComboBoxDisablePropeties = {};
        if (isInbetween) {
            if(currency) {
                props.disabledText = ' - Currency Unavailable';
                return props;
            } else {
                props.disabledText = ' - data not available for Valuation Dates prior to 03/31/2015';
                return props;
            }
        }
        return undefined;
    }

    private isUsiDateSelected(): boolean {
        const iniStartDate = new Date('03 / 31 / 2015');
        const value = this.valuationDate;
        const compareDate = CommonDate.fromDate(iniStartDate).asDate().setHours(0, 0, 0, 0);
        const valuationDate = new CommonDate(value.year, value.month, value.day).asDate().setHours(0, 0, 0, 0);
        if (valuationDate < compareDate) {
            return true;
        } else {
            return false
        }
    }


    // private disableTextBasedonValuationDate(): string | undefined {
    //   const config = this.getValuationDateConfig(subsciption.productType);
    // }

    private getValuationDateConfig(productType: ProductType): CommonInputConfig {
        const defaultValuationDate = moment().toISOString();
        const config =
            UserUtil.getValuationDateInputConfig(
                CommonDate.fromString(defaultValuationDate),
                productType,
                this.minimumDate,
                this.inputData.user.subscriptionDetails,
                HelpText.BenchmarkingValuationDate
            );
        return config;
    }

    private isInBetweenDate(minDate: NgbDateStruct, maxDate: NgbDateStruct): boolean {
        const start = (CommonDate.fromStruct(minDate)).asDate().setHours(0, 0, 0, 0);
        const end = CommonDate.fromStruct(maxDate).asDate().setHours(0, 0, 0, 0);
        let compare = new Date().setHours(0, 0, 0, 0);
        if (this.valuationDate) {
            const value = this.valuationDate;
            compare = new CommonDate(value.year, value.month, value.day).asDate().setHours(0, 0, 0, 0);
        }
        if (compare >= start && compare <= end) {
            return true;
        } else {
            return false;
        }
    }

    private setValuationDateConfigBasedOnSubscription(config: CommonInputConfig): void {
        if (config.minimumValue && config.maximumValue && this.valuationDateConfig.minimumValue && this.valuationDateConfig.maximumValue) {
            if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) <= (CommonDate.fromStruct(this.valuationDateConfig.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)
                && CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >= CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig = {
                    ...this.valuationDateConfig,
                    ...config
                };
            } else if (CommonDate.fromStruct(config.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) >= CommonDate.fromStruct(this.valuationDateConfig.maximumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig.maximumValue = config.maximumValue;
            } else if (CommonDate.fromStruct(config.minimumValue as NgbDateStruct).asDate().setHours(0, 0, 0, 0) <= (CommonDate.fromStruct(this.valuationDateConfig.minimumValue as NgbDateStruct)).asDate().setHours(0, 0, 0, 0)) {
                this.valuationDateConfig.minimumValue = config.minimumValue;
            }
        }
    }

    private updateCurrentIndustries(
        currentIndustries: Observable<Industry[] | undefined>,
        scenario: IndustryDataSelection
    ): void {

        this.spinner.while(currentIndustries);

        currentIndustries.onceDefined((industries) => {
            this.benchmarkingQueryBuilderService.industries = industries;
            this.benchmarkingQueryBuilderService.area = scenario.Region.value;
            this.benchmarkingQueryBuilderService.currencyCode = scenario.Currency.value;
            this.industries.forEach((x) => x.value = InputsUtil.validateIndustry(industries, x.value));
            this.allIndustries = industries;
            if (BenchmarkingUtil.isSic(CommonDate.fromStruct(this.valuationDate).asString(), scenario.Region.value ? scenario.Region.value : '')) {
                this.allIndustries.forEach((x) => {
                    x.CurrencyCode = "USD",
                        x.Area = "United States";
                });
                this.isSic = true;
                this.sortAllIdustries(true);
            } else {
                this.isSic = false;
                this.allIndustries = this.allIndustries.filter((item) => item.CurrencyCode === scenario.Currency.value && item.Area === scenario.Region.value);
                // updating filtered list to service variable, b/c it is used to show filtered list
                // in grid when adding a search string like 'mid'
                this.benchmarkingQueryBuilderService.industries = this.allIndustries;
                this.sortAllIdustries();
            }
            this.totalIndustries = this.allIndustries.length;

            this._allIndustriesBlankSearch = this.allIndustries;

            if (this.searchTerm) {
                this.onTypeAheadChange(this.searchTerm);
            }

            this.allIndustries.forEach((x) => {
                this.userSelectedIndustries.forEach((y) => {
                    if (Number(x.CodeId) === Number(y.CodeId) && (x.Area === y.Area) && (x.CurrencyCode === y.CurrencyCode)) {
                        x.IsSelected = true;
                    }
                });
            });

            this.disabledRemainingCheckbox(this.userSelectedIndustries);
            // if (this.userSelectedIndustries.length >= this.maximumNumberOfIndustries) {
            //     this.allIndustries.forEach((x) => {
            //         this.userSelectedIndustries.forEach((y) => {
            //             x.IsDisabled = true;
            //             if (Number(x.CodeId) === Number(y.CodeId) && (x.Area === y.Area) && (x.CurrencyCode === y.CurrencyCode)) {
            //                 x.IsDisabled = false;
            //             }
            //         });
            //     });
            // }
        });
        this.sortAllIdustries(this.isSic);
    }


    private persistBenchmarkData(addIndustries: Industry[]): BenchmarkScenario[] {
        return addIndustries.map((scenario) => {
            if (scenario !== undefined) {
                return {
                    Region: scenario.Area ? scenario.Area : null,
                    Currency: scenario.CurrencyCode ? scenario.CurrencyCode : null,
                    Industry: scenario
                };
            } else {
                return {
                    Region: null,
                    Currency: null,
                    Industry: null
                };
            }
        });
    }

    private sortAllIdustries(isSic: boolean = false): void {
        if (isSic) {
            this.allIndustries = this.allIndustries.sort((a, b) => {
                for (let i = 0; i < Math.max(a.SicIndustryCode.length, b.SicIndustryCode.length); i++) {
                    const digitA = parseInt(a.SicIndustryCode[i]) || 0; // If a[i] is undefined, use 0
                    const digitB = parseInt(b.SicIndustryCode[i]) || 0; // If b[i] is undefined, use 0
                    if (digitA !== digitB) { return digitA - digitB; }
                }
                return a.SicIndustryCode.length - b.SicIndustryCode.length;
            });

            var stringSicCodeList = this.allIndustries.filter(s => !(!isNaN(+s.SicIndustryCode)));
            this.allIndustries = [...this.allIndustries.filter(s => !isNaN(+s.SicIndustryCode)), ...stringSicCodeList]


        } else {
            this.allIndustries = this.allIndustries.sort((a, b) => {
                for (let i = 0; i < Math.max(a.GicIndustryCode.length, b.GicIndustryCode.length); i++) {
                    const digitA = parseInt(a.GicIndustryCode[i]) || 0; // If a[i] is undefined, use 0
                    const digitB = parseInt(b.GicIndustryCode[i]) || 0; // If b[i] is undefined, use 0
                    if (digitA !== digitB) { return digitA - digitB; }
                }
                return a.GicIndustryCode.length - b.GicIndustryCode.length;
            });
            var stringGicCodeList = this.allIndustries.filter(s => !(!isNaN(+s.GicIndustryCode)));
            this.allIndustries = [...this.allIndustries.filter(s => !isNaN(+s.GicIndustryCode)), ...stringGicCodeList]
        }

    }

    public ngOnInit(): void {

        combineLatest([
            this.userStore.user,
            this.regionClient.read(),
            // this.currencyClient.readIndustry(),
            this.dataStore.analysis,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                user: x[0],
                regions: x[1],
                // currencyIndustries: x[2],
                analysis: x[2],
                values: x[3]
            }))
        ).onceDefined((data) => {

            const persistedScenarios = this.benchmarkingService.benchmarkingScenarios;

            this.scenarios = new Array<IndustryDataSelection>();

            if (persistedScenarios.length) {

                persistedScenarios.forEach((scenario) => {
                    this.scenarios = [
                        ...this.scenarios,
                        {
                            Currency: { value: scenario.Currency },
                            Region: { value: scenario.Region },
                            Industry: { value: scenario.Industry },
                            RegionConfig: { ...this.regionConfig },
                            CurrencyConfig: { ...this.currencyConfig }
                        } as IndustryDataSelection
                    ];
                });
            } else {
                this.scenarios.push({
                    Currency: { value: null },
                    Region: { value: null },
                    Industry: { value: null },
                    IndustryConfig: { ...this.industryConfig },
                    RegionConfig: { ...this.regionConfig },
                    CurrencyConfig: { ...this.currencyConfig }
                } as IndustryDataSelection);
            }

            this.userSelectedIndustries.forEach((item) => {
                this.IndustriesFromDB.push({
                    CodeId: item.CodeId,
                    GicIndustryCode: item.GicIndustryCode,
                    Sector: item.Sector,
                    SicIndustryCode: item.SicIndustryCode,
                    Area: item.Area,
                    CurrencyCode: item.CurrencyCode,
                    IsDisabled: item.IsDisabled,
                    IsSelected: item.IsSelected
                });
            });

            // const Mod4 = 'intlindustry-benchmarking';
            this.IsMod4 = this.moduleName === 'industry-benchmarking' ? false : true;
            if (data.analysis == null || data.user == null) {
                return;
            }

            this.inputData = {} as IndustryInputDependencies;
            this.inputData.user = data.user as User;

            this.iniSubData = data.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Ini);
            this.usiSubData = data.user.subscriptionDetails.find((sub) => sub.productType === ProductType.Usi);
            // this.subscriptionWithProPlusLevel = data.user.subscriptionDetails.find(
            //    (sub) => {
            //        return sub.level === SubscriptionLevel.Pro ||
            //            sub.level === SubscriptionLevel.Enterprise;
            //    }
            // );

            if (data.values) {
                if (this.iniSubData && !this.usiSubData) {
                    this.minimumDate = data.values.InternationalEstimates;
                }

                if (this.usiSubData && !this.iniSubData) {
                    this.minimumDate = data.values.UsIndustries;
                }

                if (this.usiSubData && this.iniSubData) {
                    this.minimumDate = data.values.InternationalEstimates;
                }
            }

            this.handleRegionChange(this.scenarios[0]);

            if (data.regions && data.regions.length > 0) {
                data.regions = data.regions.sort((a, b) => this.sortedRegions.indexOf(a) - this.sortedRegions.indexOf(b));
            }

            this.regionStrings = data.regions;




            this.regionStrings.forEach((r) => {
                const disableOptions: ComboBoxDisablePropeties | undefined = this.disabledTextBasedOnSubscription(r, 'United States', false, true);
                //region dropdown will have lock icon and conditional text message, and currency dropdown will always have text message
                this.regionComboOptions.push({
                    name: r,
                    value: r,
                    type: ComboboxDataType.String,
                    enableLockIcon: disableOptions?.enableLockIcon,
                    disabled: disableOptions?.disabled,
                    disabledText: disableOptions?.disabledText
                });
            });

            const req = this.currencyClient.readIndustry();



            this.spinner.begin();
            req.once(data => {
                this.spinner.end();
                if (data && data.length > 0) {
                    data = data.sort((a, b) => this.sortedCurrencies.indexOf(a) - this.sortedCurrencies.indexOf(b));
                }
                this.currencyStrings = data;
                this.currencyStrings.forEach((c) => {
                    const disableOptions: ComboBoxDisablePropeties | undefined = this.disabledTextBasedOnSubscription(c, 'USD', true, false); 
                    this.currencyOptions.push({
                        name: c,
                        value: c,
                        type: ComboboxDataType.String,
                        disabledText: disableOptions?.disabledText
                    });
                });
            })


            const analysis = data.analysis as Analysis;
            const date = CommonDate.fromString(analysis.ValuationDate);
            this.valuationDateConfig =
                UserUtil.getValuationDateInputConfig(
                    date,
                    this.iniSubData && this.IsMod4 ? ProductType.Ini : ProductType.Usi,
                    this.minimumDate,
                    this.inputData.user.subscriptionDetails,
                    HelpText.BenchmarkingValuationDate
                );
            if (this.iniSubData && this.iniSubData.level && this.usiSubData && this.usiSubData.level) {
                this.setValuationDateConfigBasedOnSubscription(this.getValuationDateConfig(this.usiSubData.productType));
                this.setValuationDateConfigBasedOnSubscription(this.getValuationDateConfig(this.iniSubData.productType));
            }
            this.valuationDateConfig.stackedDisplay = true;
            this.valuationDateConfig.iconWithLabel = true;
            this.valuationDateConfig.containerClassList = 'input-flex-display';

            if (this.usiSubData && !this.iniSubData) {

                if (!persistedScenarios.length) {
                    this.scenarios[0].Region.value = 'United States';
                    this.scenarios[0].Currency.value = 'USD';

                    this.scenarios[0].IndustryConfig.disabled = false;
                    this.handleRegionChange(this.scenarios[0]);
                }

                this.currencyOptions = this.currencyOptions.map((currencyOption) => {
                    const disableOptions: ComboBoxDisablePropeties | undefined = this.disabledTextBasedOnValue(currencyOption.value, 'USD', ' - Currency Unavailable', false);
                    return {
                        ...currencyOption,
                        disabledText: disableOptions?.disabledText
                    };
                });

                this.promptTitle = InputsTexts.highLevelUSAnalysis;
            } else if (!this.usiSubData && this.iniSubData) {
                this.promptTitle = InputsTexts.highLevelAnalysisInternational;
            }
        });

        this.http.get(environment.urls.contentManagement + '/Mod1-ProductNews.html', { responseType: 'text' }).subscribe((res) => {
            this.productNews = this.sanitizer.bypassSecurityTrustHtml(res.toString());
        });
    }

    public handleRegionChange(scenario: IndustryDataSelection): void {
        if (scenario.Region.value) {
            this.updateCurrencyOptions(scenario.Region.value);
        }
        this.validateValuationDateForAllScenarios();
        this.validateIndustryInput(scenario);
    }

    public userHandledRegionChange(scenario: IndustryDataSelection): void {

        if (scenario.Region.value) {
            scenario.Currency.value = '';
            this.benchmarkingQueryBuilderService.industries = [];
            this.allIndustries = [];
        }
    }
    public validateValuationDateForAllScenarios(): void {
        if (this.usiSubData && this.iniSubData) {
            this.errorMessages = [];
            let errorMessagesTemp: string[] = [];
            this.scenarios.forEach((scenario) => {
                if (scenario.Region.value && this.usiSubData && this.usiSubData.level && this.iniSubData && this.iniSubData.level) {
                    let subsciption: SubscriptionData | undefined;
                    if ((!this.IsMod4 && scenario.Region.value !== 'United States') || (this.IsMod4 && scenario.Region.value !== 'United States')) {
                        subsciption = this.iniSubData;
                    } else if (scenario.Region.value === 'United States') {
                        subsciption = this.usiSubData;
                    }
                    if (subsciption) {
                        const config = this.getValuationDateConfig(subsciption.productType);
                        if (config.minimumValue && config.maximumValue) {
                            const validation = this.isInBetweenDate(config.minimumValue as NgbDateStruct, config.maximumValue as NgbDateStruct);
                            if (!validation) {
                                scenario.CurrencyConfig = {
                                    ...this.currencyConfig,
                                    disabled: true,
                                    readonly: true
                                };
                                scenario.IndustryConfig = {
                                    ...this.industryConfig,
                                    disabled: true
                                };
                                errorMessagesTemp.push(this.setErrorMessage(scenario));
                            }
                        }
                    }
                }
            });
            errorMessagesTemp = errorMessagesTemp.filter((elem: string, index: number, self: string[]) => {
                return index === self.indexOf(elem);
            });
            errorMessagesTemp.forEach((x) => {
                this.errorMessages.push(this.sanitizer.bypassSecurityTrustHtml(x));
            });
        }
    }

    public setErrorMessage(scenario?: IndustryDataSelection): string {
        let errorMessage = "";
        if (this.iniSubData && this.usiSubData) {
            if ((this.usiSubData.level === SubscriptionLevel.Pro && this.iniSubData.level === SubscriptionLevel.Basic)
                || (this.usiSubData.level === SubscriptionLevel.Enterprise && this.iniSubData.level === SubscriptionLevel.Basic)) {
                errorMessage = "Only dates of <strong>12/31/2019</strong> and forward are available to International Industry Benchmarking Basic Subscribers";
            } else if ((this.usiSubData.level === SubscriptionLevel.Basic && this.iniSubData.level === SubscriptionLevel.Pro)
                || (this.usiSubData.level === SubscriptionLevel.Basic && this.iniSubData.level === SubscriptionLevel.Enterprise)) {
                errorMessage = "Only dates of <strong>12/31/2019</strong> and forward are available to US Industry Benchmarking Basic Subscribers";
            } else if ((this.usiSubData.level === SubscriptionLevel.Trial && this.iniSubData.level === SubscriptionLevel.Pro)
                || (this.usiSubData.level === SubscriptionLevel.Trial && this.iniSubData.level === SubscriptionLevel.Enterprise)
                || (this.usiSubData.level === SubscriptionLevel.Trial && this.iniSubData.level === SubscriptionLevel.Basic)) {
                errorMessage = "Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to US Industry Benchmarking Trial Subscribers";
            } else if ((this.usiSubData.level === SubscriptionLevel.Pro && this.iniSubData.level === SubscriptionLevel.Trial)
                || (this.usiSubData.level === SubscriptionLevel.Enterprise && this.iniSubData.level === SubscriptionLevel.Trial)) {
                errorMessage = "Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to International Industry Benchmarking Trial Subscribers";
            } else if (this.usiSubData.level === SubscriptionLevel.Basic && this.iniSubData.level === SubscriptionLevel.Trial && scenario && scenario.Region.value === 'United States') {
                errorMessage = "Only dates of <strong>12/31/19</strong>  and forward are available to US Industry Benchmarking Basic Subscribers.";
            } else if (this.usiSubData.level === SubscriptionLevel.Basic && this.iniSubData.level === SubscriptionLevel.Trial && scenario && scenario.Region.value !== 'United States') {
                errorMessage = "Only dates of <strong> 03/31/2016 - 07/31/2016</strong> are available to International Industry Benchmarking Trial Subscribers";
            } else if ((this.usiSubData.level === SubscriptionLevel.Pro || this.usiSubData.level === SubscriptionLevel.Enterprise)
                && (this.iniSubData.level === SubscriptionLevel.Pro || this.iniSubData.level === SubscriptionLevel.Enterprise)) {
                errorMessage = "Valuation dates prior to <strong> 03/31/2015</strong> are limited to US only options.";
            }
        } else if (!this.IsMod4 && !this.iniSubData) {
            errorMessage = "Only  available to International Industry Benchmarking Subscribers";
        } else if (this.IsMod4 && this.usiSubData) {
            if (this.usiSubData.level === SubscriptionLevel.Basic) {
                errorMessage = "Only dates of <strong>12/31/2019</strong> and forward are available to US Industry Benchmarking Basic Subscribers";
            }
        }
        return errorMessage;

    }

    public validateIndustryInput(scenario: IndustryDataSelection): void {
        if (!(scenario.Currency.value &&
            scenario.Region.value &&
            this.valuationDate)) {
            return;
        }

        scenario.IndustryConfig = {
            ...this.industryConfig,
            disabled: false
        };

        this.industryInputFocus(scenario);
    }

    public industryInputFocus(scenario: IndustryDataSelection): void {
        if (scenario.Currency.value === null ||
            scenario.Region.value === null ||
            this.valuationDate === null) {

            return;
        }

        scenario.Industry = {
            ...scenario.Industry,
            value: {} as Industry
        };

        const date = CommonDate.fromStruct(this.valuationDate);
        const request = this.dataStore.getIndustriesIntl(scenario.Region.value, scenario.Currency.value, date.asString());
        this.spinner.while(request);
        request.once(() => {
            const currentIndustries = this.dataStore.industriesInt;
            this.updateCurrentIndustries(currentIndustries, scenario);
        });

    }

    public onTypeAheadChange(value: string): void {
        if (this.allIndustries && this.allIndustries.length > 0) {
            this.benchmarkingQueryBuilderService.IndustriesZoneFilterTypeahead(of(value), this.moduleName, true)
                .onceDefined((results) => {
                    if (results.length && results.length > 0) {
                        this.allIndustries = results;
                    } else {
                        this.allIndustries = this._allIndustriesBlankSearch;
                    }
                    this.sortAllIdustries(this.isSic);
                });

        }
    }

    public removeSelectedIndustryFromChild = ($event: any): any => {
        this.CodeId = $event.CodeId;
        this.Area = $event.Area;
        this.CurrencyCode = $event.CurrencyCode;
        this.searchTerm = '';
        this.onTypeAheadChange('');
        this.benchmarkingQueryBuilderService.industries.forEach((x) => {
            if ((Number(x.CodeId) === Number($event.CodeId)) && (x.Area === $event.Area)
                && (x.CurrencyCode === $event.CurrencyCode)) {
                x.IsSelected = false;
            }
            x.IsDisabled = false;
        });

        this.userSelectedIndustries = this.userSelectedIndustries.filter((a) => this.filterScenario(a));

    }

    public checkCheckBoxvalue = ($event: any): void => {
        const checkValues = $event.target.value;
        const codeId = $event.target.id;
        const isChecked = $event.target.checked;
        let tempArray: any;
        tempArray = checkValues.split(' - ');
        const SICGICCode = tempArray[0].split(' ');
        const area = tempArray[2];
        const currencyCode = tempArray[3];
        this.CodeId = Number(codeId);
        this.Area = area;
        this.CurrencyCode = currencyCode;

        if (isChecked && this.userSelectedIndustries.length < this.maximumNumberOfIndustries) {
            this.userSelectedIndustries.push({
                CodeId: codeId,
                GicIndustryCode: SICGICCode[0] === 'GICS' ? SICGICCode[1] : '',
                SicIndustryCode: SICGICCode[0] === 'SIC' ? SICGICCode[1] : '',
                Sector: tempArray[1],
                IsSelected: true,
                IsDisabled: false,
                Area: area,
                CurrencyCode: currencyCode
            });

            this.allIndustries.forEach((x) => {
                this.userSelectedIndustries.forEach((y) => {
                    if (Number(x.CodeId) === Number(y.CodeId) && (x.Area === y.Area) && (x.CurrencyCode === y.CurrencyCode)) {
                        x.IsSelected = true;
                        x.IsDisabled = false;
                    }
                });
            });

        } else {
            this.allIndustries.forEach((x) => {
                if (Number(x.CodeId) === Number(codeId) && (x.Area === area) && (x.CurrencyCode === currencyCode)) {
                    x.IsSelected = false;
                }
            });
            this.userSelectedIndustries = this.userSelectedIndustries.filter((a) => this.filterScenario(a));

            this.allIndustries = this._allIndustriesBlankSearch;
        }
        this.disabledRemainingCheckbox(this.userSelectedIndustries);
        this.sortAllIdustries(this.isSic);
    }

    public filterScenario(scenario: Industry): any {
        return (Number(scenario.CodeId) !== Number(this.CodeId) || scenario.Area !== this.Area
            || scenario.CurrencyCode !== this.CurrencyCode);
    }

    public disabledRemainingCheckbox = (codeId: Industry[]): any => {
        const indCodeId = codeId.map((item) => Number(item.CodeId));

        if (indCodeId.length >= this.maximumNumberOfIndustries) {
            this.allIndustries.forEach((x) => {
                if (!x.IsSelected) {
                    x.IsDisabled = true;
                }
            });
        } else {
            this.allIndustries.forEach((x) => {
                x.IsDisabled = false;
            });
        }
    }

    public isDisableSubmit(): boolean {
        if (this.userSelectedIndustries.length > 0) {
            return false;
        } else {
            return true;
        }
    }

    public addIndustries = (addIndustries: Industry[]): void => {
        this.industryDataSharingService.setSharedBenchmarkingIndustries = addIndustries;
        this.benchmarkingService.setBenchmarkingScenarios = this.persistBenchmarkData(addIndustries);
        this._check = 'button';
        this.display = false;
        this.SaveAndContinue(this.userSelectedIndustries);
    }

    public close(): void {
        if (this._check === undefined) {
            if (this.IndustriesFromDB.length > 0) {
                this.industryDataSharingService.setSharedBenchmarkingIndustries = this.IndustriesFromDB;
            } else {
                const emptyIndustry: Industry[] = new Array<Industry>();
                emptyIndustry.push({
                    CodeId: 0,
                    GicIndustryCode: '',
                    SicIndustryCode: '',
                    Sector: '',
                    Area: '',
                    CurrencyCode: ''
                } as Industry);
                this.industryDataSharingService.setSharedBenchmarkingIndustries = emptyIndustry;
            }

        } else if (this._check === 'button') {
            this.display = false;
        }
    }
}
