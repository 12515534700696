import { Component, OnInit } from '@angular/core';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { UserClient } from 'src/app/_api/clients/user.client';
import { EstimateTitle, EstimateType } from 'src/app/_api/enums/estimate-type';
import TableAction from 'src/app/_navigator/common/commonTable/interface/tableActionInterface';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { EstimatesListState } from 'src/app/_navigator/estimates-list-store/estimates-list-state';
import { EstimateSharedService } from './estimate-shared-service';
import { ColumnItem } from 'src/app/_navigator/common/commonTable/interface/tableColumnInterface';
import { MenuType } from '../menu.type';

@Component({
    selector: 'intl-estimates',
    templateUrl: './intl-estimates.component.html'
})
export class IntlEstimatesComponent extends SubscriberEntity implements OnInit {
    public moduleEstimates!: Estimate[] | undefined;
    public hideestimates = false;
    @Select(EstimatesListState.get) public estimates!: Observable<Estimate[] | undefined>;

    constructor(private spinner: Spinner, private userProfileClient: UserClient, private estimateSharedService: EstimateSharedService) {
        super();
    }

    public ngOnInit(): void {
        this.estimateSharedService.setTitle(EstimateTitle.InternationalEstimate);
        this.estimateSharedService.setModuleType(MenuType.InternationalCostOfCapitalEstimate);

        this.estimates.onceDefined((estimates) => {
            this.moduleEstimates = estimates.filter((x) => x.EstimateType === EstimateType.InternationalEstimate);
            this.moduleEstimates = this.estimateSharedService.mapEstimateCountryData(this.moduleEstimates, EstimateType.InternationalEstimate);


        });
        const profileRequest = this.userProfileClient.read();
        this.spinner.while(profileRequest);
        profileRequest.onceDefined((profile) => {
            for (const i of profile.Subscriptions) {
                if (i.Type === 'IneTrial' || i.Type === 'IneBasic' || i.Type === 'InePro' || i.Type === 'IneEnterprise') {
                    const isAuthorized = new Date(i.End) > new Date();
                    this.hideestimates = isAuthorized;
                }
            }
        });
    }



    public intlEstimateTblColumns: ColumnItem<Estimate>[] = [
        {
            title: `Estimate Name`,
            compare: (a: Estimate, b: Estimate) => a.Name.localeCompare(b.Name),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'Name',
            type: 'estimateName'
        },
        {
            title: `Created/Modified Date`,
            compare: (a: Estimate, b: Estimate) => this.sortByDate(a, b, 'Updated'),
            sortDirections: ['ascend', 'descend'],
            sortOrder: 'descend',
            key: 'Updated',
            formate: 'MM/dd/yyyy',
            type: 'date'
        },
        {
            title: `Valuation Date`,
            compare: (a: Estimate, b: Estimate) => this.sortByDate(a, b, 'ValuationDate'),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'ValuationDate',
            formate: 'MM/dd/yyyy',
            type: 'date'
        },
        {
            title: `Investor Perspective`,
            compare: (a: Estimate, b: Estimate) => a.InvestorCountry?.localeCompare(b.InvestorCountry),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'InvestorCountry'
        },
        {
            title: `Investee Country`,
            compare: (a: Estimate, b: Estimate) => a.InvesteeCountry?.localeCompare(b.InvesteeCountry),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'InvesteeCountry'
        },
        {
            title: `Curency of Cash  Flows`,
            compare: (a: Estimate, b: Estimate) => a.CurrencycashFlow?.localeCompare(b.CurrencycashFlow),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'CurrencycashFlow'
        },
        {
            title: `COE Inputs Country`,
            compare: (a: Estimate, b: Estimate) => a.COEInputCountry?.localeCompare(b.COEInputCountry),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'COEInputCountry'
        },
        {
            title: `Industry`,
            key: 'Industries',
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            type: 'Array'
        },
    ]

    public sortByDate = (a: any, b: any, field: string) => {
        {
            if (a[field] < b[field]) {
                return -1;
            }
            if (a[field] > b[field]) {
                return 1;
            }
            return 0;
        }
    }
}
