import { Component, Input, OnInit } from '@angular/core';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { EstimateSummary } from 'src/app/_api/responses/estimate-summary.response';
import { EstimateSummaryState } from 'src/app/_navigator/estimate-summary/estimate-summary-state';
import { Regionrisk } from 'src/app/benchmarking/data/trends-over-time-table-data';

@Component({
    selector: 'trends-over-time-country-risk-table',
    templateUrl: './trends-over-time-country-risk-table.component.html',
    styleUrls: ['./trends-over-time-country-risk-table.component.scss']
})
export class TrendsOverTimeCountryRiskTableComponent implements OnInit {
    @Input() public tableData!: Regionrisk;
    @Input() public creditrating!: boolean;
    @Input() public yeild!: boolean;
    @Input() public volatility!: boolean;
    @Input() public Creditratingline!: boolean;
    @Input() public euroregionline!: boolean;
    @Input() public msciline!: boolean;
    @Input() public sprline!: boolean;
    @Input() public countryriskarray: any[] = [];
    @Input() public CcrAvgarrayeur: any[] = [];
    @Input() public CcrAvgarraymsci: any[] = [];
    @Input() public CcrAvgarrayspc: any[] = [];
    @Input() public dateArray: string[] = [];
    @Input() public YieldSpreadeur: any[] = [];
    @Input() public YieldSpreadmsci: any[] = [];
    @Input() public YieldSpreadspc: any[] = [];
    @Input() public VolatilityAvgeur: any[] = [];
    @Input() public VolatilityAvgmsci: any[] = [];
    @Input() public VolatilityAvgspc: any[] = [];
    @Input() public YieldSpread: any[] = [];
    @Input() public volatality: any[] = [];
    @Input() public title = 'Country Risk';

    private IsTableDataAvailabel:{[index: string]: boolean} = {"CCR_MODEL": false, "CYS_MODEL": false, "RV_MODEL": false}
    
    @Select(EstimateSummaryState.get) public estimateSummarySelector!: Observable<EstimateSummary | undefined>;
    public estimate: EstimateSummary | undefined;


    constructor() { }

    ngOnInit(): void {

        this.estimateSummarySelector.onceDefined((estimateData) => {
            this.estimate = estimateData;
        });

        this.IsTableDataAvailabel["CCR_MODEL"] = this.countryriskarray.length > 0 || this.CcrAvgarrayeur.length > 0 || 
                                                 this.CcrAvgarraymsci.length > 0 || this.CcrAvgarrayspc.length > 0;

        this.IsTableDataAvailabel["CYS_MODEL"] = this.YieldSpread.length > 0 || this.YieldSpreadeur.length > 0 ||
                                                 this.YieldSpreadmsci.length > 0 || this.YieldSpreadspc.length > 0;

        this.IsTableDataAvailabel["RV_MODEL"] = this.volatality.length > 0 || this.VolatilityAvgeur.length > 0 ||
                                                this.VolatilityAvgmsci.length > 0 || this.VolatilityAvgspc.length > 0;
    }
}
