import * as moment from 'moment';

import { WorkbookSheetRow, WorkbookSheetRowCell } from '@progress/kendo-ooxml';
import { IndustryTearSheet } from 'src/app/_api/responses/industry-tear-sheet.response';
import { IntlCompany, IntlCompanyData } from 'src/app/_api/responses/intl-company.response';
import { CompanyType, UsCompany, UsCompanyData } from 'src/app/_api/responses/us-company.response';
import { AssetsTypes, CurrencyTypes, SalesTypes } from 'src/app/benchmarking/modals/intl-company-list/enums/intl-company-list-enums';
import { MetricCategory } from 'src/app/home/tear-sheet/models/metric-category.model';
import { MetricStatistic } from 'src/app/home/tear-sheet/models/metric-statistic.model';
import { MetricTable } from 'src/app/home/tear-sheet/models/metric-table.model';
import { MetricValues } from 'src/app/home/tear-sheet/models/metric-values.model';
import { StatisticsCategory } from 'src/app/home/tear-sheet/models/statistics-category.model';
import { StatisticsGroup } from 'src/app/home/tear-sheet/models/statistics-group.model';
import { ExcelExportUtil } from './excel-export.util';
import { Category } from 'src/app/home/tear-sheet/enums/category';
import { IndustryAssumptions } from 'src/app/_api/responses/IndustryAssumption';
import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { enumAssumptions, industryAssumptionJSON } from 'src/app/home/tear-sheet/enums/viewMore';
import { AboutSpreadSheetData } from 'src/app/_api/responses/about-spread-sheet-data.response';

export class TearSheetExcelExportUtil {
    public static rowWidth = 12;           // max width of grid system
    public static categoryRowWidth = 8;    // width of Industry Statistics block
    public static dataRowWidth = 2;        // with of industry data block
    public static dataLargeFont = 48;      // font size for large number
    public static dataLargeRow = 60;       // cell height for large number
    public static columnWidth = 125;       // width of each cell in grid system
    public static orangeColor = '#14487f'; // color of all orange items
    public static numberFormat = '0.00';   // number format for cells
    public static numberwithcommaFormat = '0,000';
    public static numberwithoutcommaFormat = '0';
    public static krollshadow = '#4d4d4f';
    public static krollblue = '#14487F';
    public static grayBackGround = '#F6F6F7'

    public static getUSCompanyData(codeText: string, code: string, data: UsCompanyData, currency?: string): WorkbookSheetRow[] {
        const sicCompanies = data.Companies.filter((x) =>
            x.Type === CompanyType.Default ||
            x.Type === CompanyType.Large ||
            x.Type === CompanyType.Small
        );
        const smallCompanies = data.Companies.filter((x) => x.Type === CompanyType.Small);
        const largeCompanies = data.Companies.filter((x) => x.Type === CompanyType.Large);
        const hfrCompanies = data.Companies.filter((x) => x.Type === CompanyType.HighFinancialRisk);

        let rows = sicCompanies.map((company) => this.getUSCompanyDataRow(company, 'Industry Composite'));
        rows = rows.concat(smallCompanies.map((company) => this.getUSCompanyDataRow(company, 'Small Composite')));
        rows = rows.concat(largeCompanies.map((company) => this.getUSCompanyDataRow(company, 'Large Composite')));
        rows = rows.concat(hfrCompanies.map((company) => this.getUSCompanyDataRow(company, 'High-Financial-Risk')));

        const row: WorkbookSheetRow[] =
            [
                // {
                //     height: this.dataLargeRow,
                //     cells: [{
                //         value: `${codeText} ${code}`,
                //         color: this.orangeColor,
                //         fontSize: this.dataLargeFont,
                //         bold: true,
                //         colSpan: this.dataRowWidth
                //     }]
                // },
                {
                    cells: [{
                        value: `Company`,
                        bold: true,
                        background: this.grayBackGround,
                        ...this.mapBordersToCell()

                    }, {
                        value: currency ? `Assets ([${currency.toUpperCase()}] in millions)` : 'Assets',
                        bold: true,
                        background: this.grayBackGround,
                        ...this.mapBordersToCell()

                    }, {
                        value: currency ? `Sales ([${currency.toUpperCase()}] in millions)` : 'Sales',
                        bold: true,
                        background: this.grayBackGround,
                        ...this.mapBordersToCell()

                    }, {
                        value: `Type`,
                        bold: true,
                        background: this.grayBackGround,
                        ...this.mapBordersToCell()

                    }]
                }, ...rows];

        return row;
    }

    public static getHeader(data: IndustryTearSheet, isCompany?: boolean): WorkbookSheetRow[] {
        const row: WorkbookSheetRow[] = [
            {
                cells: [
                    isCompany ? {
                        value: `${(data.GicCode ? 'GICS ' + data.GicCode : 'SIC ' + data.SicCode) + ' - ' + data.Sector + ' - ' + data.Region + " - " + data.Currency}`,
                        bold: true,
                        color: this.krollblue,


                    } : {
                        value: `${(data.GicCode ? 'GICS ' + data.GicCode : 'SIC ' + data.SicCode) + ' - ' + data.Sector + ' - ' + data.Region + " - " + data.Currency}`,
                        bold: true,
                        color: this.krollblue,
                    }

                ]
            },
            {
                cells: [
                    {
                        value: `Data as of: ${moment(data.DataAsOf).format('MM/DD/YYYY')}`,
                        bold: true,
                        colSpan: 12,
                        // borderBottom: { size: 2, color: this.orangeColor }
                    },
                ]
            },
            {
                cells: [
                    {}
                ]

            }

        ];

        return row;
    }

    public static getFooter(moduleName: string): WorkbookSheetRow[] {
        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: ''
            }]
        }, {
            cells: [{
                value: moduleName === 'United States' ? 'Sourced from the Cost of Capital Navigator: U.S. Industry Benchmarking Module' :
                    'Exported from the Cost of Capital Navigator: International Industry Benchmarking Module',
                bold: true
            }]
        }, {
            cells: [{
                value: `Exported on : ${moment().format('MM/DD/YYYY')}`,
                bold: true
            }]
        }];

        return row;
    }

    public static getIndustryData(
        data: IndustryTearSheet,
        industryCode: string,
        industryLabel: string
    ): WorkbookSheetRow[] {
        const row: WorkbookSheetRow[] = [{
            height: this.dataLargeRow,
            cells: [{
                value: `${industryLabel} ${industryCode}`,
                color: this.orangeColor,
                fontSize: this.dataLargeFont,
                bold: true,
                colSpan: this.dataRowWidth
            }]
        }, {
            cells: [{
                value: `Region: ${data.Region}`,
                colSpan: this.dataRowWidth
            }]
        },
        {
            cells: [{
                value: `Currency: ${data.Currency}`,
                colSpan: this.dataRowWidth
            }]
        },
        {
            cells: [{
                value: `Number of Companies: ${data.NumberOfCompanies}`,
                colSpan: this.dataRowWidth
            }]
        }, {
            cells: [{
                value: data.Sector,
                color: this.orangeColor,
                bold: true,
                colSpan: this.dataRowWidth
            }]
        }];

        return row;
    }

    public static getIntlCompanyData(gicCode: string, data: IntlCompanyData, currency: string): WorkbookSheetRow[] {
        const companies = data.Companies.filter((x) =>
            x.Type === CompanyType.Default ||
            x.Type === CompanyType.Large ||
            x.Type === CompanyType.Small
        );
        const smallCompanies = data.Companies.filter((x) => x.Type === CompanyType.Small);
        const largeCompanies = data.Companies.filter((x) => x.Type === CompanyType.Large);
        const hfrCompanies = data.Companies.filter((x) => x.Type === CompanyType.HighFinancialRisk);

        let rows = companies.map((company) => this.getIntlCompanyDataRow(company, 'Industry Composite'));
        rows = rows.concat(smallCompanies.map((company) => this.getIntlCompanyDataRow(company, 'Small Composite')));
        rows = rows.concat(largeCompanies.map((company) => this.getIntlCompanyDataRow(company, 'Large Composite')));
        rows = rows.concat(hfrCompanies.map((company) => this.getIntlCompanyDataRow(company, 'High-Financial-Risk')));

        const row: WorkbookSheetRow[] = [
            //{
            //     height: this.dataLargeRow,
            //     cells: [{
            //         value: `GICS ${gicCode}`,
            //         color: this.orangeColor,
            //         fontSize: this.dataLargeFont,
            //         bold: true,
            //         colSpan: this.dataRowWidth
            //     }]
            // }, 
            {
                cells: [{
                    value: `Company`,
                    bold: true,
                    background: this.grayBackGround,
                    ...this.mapBordersToCell()
                }, {
                    value: `Assets ([${currency.toUpperCase()}] in millions)`,
                    bold: true,
                    background: this.grayBackGround,
                    ...this.mapBordersToCell()
                }, {
                    value: `Sales ([${currency.toUpperCase()}] in millions)`,
                    bold: true,
                    background: this.grayBackGround,
                    ...this.mapBordersToCell()
                }, {
                    value: `Type`,
                    bold: true,
                    background: this.grayBackGround,
                    ...this.mapBordersToCell()
                }]
            }, ...rows];



        return row;
    }

    public static concatenateRows(rowDataLeft: WorkbookSheetRow[], rowDataRight: WorkbookSheetRow[]): WorkbookSheetRow[] {
        const leftWidth: number = ExcelExportUtil.getWidthOfWidestRow(rowDataLeft);

        const rowData: WorkbookSheetRow[] = [];
        for (let i = 0; i < rowDataLeft.length; ++i) {
            if (i < rowDataRight.length) { // row in right side, add it to the left
                let newCells: WorkbookSheetRowCell[] = [];
                const leftCells = rowDataLeft[i].cells;
                const rightCells = rowDataRight[i].cells;
                if (leftCells && rightCells) {
                    newCells = leftCells.concat(rightCells);
                }
                rowDataLeft[i].cells = newCells;
            }
            rowData.push(rowDataLeft[i]);
        }

        if (rowData[0].cells && rowData[0].cells[0].rowSpan == 2) {


            // rowData.forEach((s, i) => {
            //     if (i > 0 && i < (rowData.length - 1)) {

            //     }
            // });
        }

        //
        //  process any rows on right beyond the left
        //
        for (let j: number = rowDataLeft.length; j < rowDataRight.length; ++j) {
            let newCells: WorkbookSheetRowCell[] = ExcelExportUtil.getEmptyCell(leftWidth);
            const rightCells = rowDataRight[j].cells;
            if (rightCells) {
                newCells = newCells.concat(rightCells);
            }
            rowDataRight[j].cells = newCells;
            rowData.push(rowDataRight[j]);
        }

        return rowData;
    }

    public static getStatisticsCategory(category: StatisticsGroup, index: number): WorkbookSheetRow[] {
        const rowData: WorkbookSheetRow[] = [];

        // title row
        let row: WorkbookSheetRow = {
            cells: [
                {

                    value: category.groupName,
                    colSpan: 3,
                    bold: true,
                    textAlign: "center",
                    // borderBottom: { size: 3, color: this.krollshadow },
                    color: this.krollshadow,
                    ...this.mapBordersToCell()
                }
            ]
        };
        if (index == 0) {
            row.cells?.unshift({
                colSpan: 2,
                rowSpan: 2,
                background: this.grayBackGround,
                ...this.mapBordersToCell()
            });
        }
        rowData.push(row);

        // table header
        row = {};
        row.cells = [
            {
                value: 'Geometric Mean',
                colSpan: 1,
                bold: true,
                background: this.grayBackGround,
                ...this.mapBordersToCell()
            }, {
                value: 'Arithmetic Mean',
                colSpan: 1,
                bold: true,
                background: this.grayBackGround,
                ...this.mapBordersToCell()
            }, {
                value: 'Standard Deviation',
                colSpan: 1,
                bold: true,
                background: this.grayBackGround,
                ...this.mapBordersToCell()
            }];

        rowData.push(row);

        category.statistics.forEach((x: MetricStatistic) => {
            row = {};
            row.cells = [
                {
                    value: parseFloat(x.geometricMean),
                    colSpan: 1,
                    format: this.numberFormat,
                    ...this.mapBordersToCell()
                }, {
                    value: parseFloat(x.arithmeticMean),
                    colSpan: 1,
                    format: this.numberFormat,
                    ...this.mapBordersToCell()
                }, {
                    value: parseFloat(x.standardDeviation),
                    colSpan: 1,
                    format: this.numberFormat,
                    ...this.mapBordersToCell()
                }];
            if (index === 0) {
                row.cells.unshift(
                    {
                        value: x.year,
                        colSpan: 2,
                        ...this.mapBordersToCell()
                    })
            }
            rowData.push(row);
        });

        return rowData;
    }

    public static getMetricCategoryTitle(title: string, width: number): WorkbookSheetRow[] {
        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: title,
                colSpan: width,
                color: this.orangeColor,
                bold: true
            }]
        }];

        return row;
    }

    public static getMetricTitle(title: string, width: number): WorkbookSheetRow[] {
        title = title.replace('<br />', ' ');
        title = title.replace('&nbsp;', ' ');
        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: title,
                colSpan: width,
                // borderBottom: { size: 3, color: this.krollshadow },
                color: this.krollshadow,
                textAlign: "center",
                bold: true,
                ...this.mapBordersToCell()
            }]
        }];

        return row;
    }

    public static getBetasMtericHeaders(title: string, width: number): WorkbookSheetRow[] {

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: title,
                colSpan: width,
                // borderBottom: { size: 3, color: this.krollshadow },
                color: this.krollshadow,
                textAlign: "center",
                bold: true,
                background: this.grayBackGround,
                ...this.mapBordersToCell()
            }]
        }];

        return row;
    }

    public static getStatisticsCategoryTitle(title: string): WorkbookSheetRow[] {
        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: title,
                colSpan: 8,
                // borderBottom: { size: 3, color: this.orangeColor },
                color: this.orangeColor,
                bold: true
            }]
        }];

        return row;
    }

    public static getCompLabels(): WorkbookSheetRow[] {
        const medianLabel = 'Median';
        const sicCompositeLabel = 'Industry Composite';
        const largeCompositeLabel = 'Large Composite';
        const smallCompositeLabel = 'Small Composite';
        const highFinancialRiskLabel = 'High-Financial-Risk';

        const row: WorkbookSheetRow[] = [
            {
                cells: [{
                    value: '',
                    colSpan: 2,
                    rowSpan: 2,
                    background: this.grayBackGround,
                    ...this.mapBordersToCell()
                }]
            },
            {
                cells: []
            },

            {
                cells: [{
                    value: medianLabel,
                    colSpan: 2,

                    ...this.mapBordersToCell()

                }]
            }, {
                cells: [{
                    value: sicCompositeLabel,
                    colSpan: 2,
                    ...this.mapBordersToCell()
                }]
            }, {
                cells: [{
                    value: largeCompositeLabel,
                    colSpan: 2,
                    ...this.mapBordersToCell()
                }]
            }, {
                cells: [{
                    value: smallCompositeLabel,
                    colSpan: 2,
                    ...this.mapBordersToCell()
                }]
            }, {
                cells: [{
                    value: highFinancialRiskLabel,
                    colSpan: 2,
                    ...this.mapBordersToCell()
                }]
            }];

        return row;
    }

    public static cleanFloat(value: string): any {
        const num: number = parseFloat(value);

        if (!isNaN(num)) {
            return num;
        }
        return "'–";
    }

    public static getMetricTableRow(metric: MetricTable): WorkbookSheetRow[] {
        const tableRow: WorkbookSheetRow[] = [];
        let row: WorkbookSheetRow = {};
        row.cells = [];
        let cl: WorkbookSheetRowCell = {};

        metric.columns.forEach((x) => {
            cl = {
                value: x,
                colSpan: 1,
                textAlign: 'right',
                bold: true,
                background: this.grayBackGround
            };
            if (row.cells) {
                row.cells.push(cl);
            }
        });
        if (row.cells.length > 0) {
            tableRow.push(row);
        }

        row = {};
        row.cells = [];

        cl = {
            value: this.cleanFloat(metric.medianLatest),
            colSpan: 1,
            format: this.numberFormat
        };
        row.cells.push(cl);
        if (metric.showAverageValues) {
            cl = {
                value: this.cleanFloat(metric.medianAverage),
                colSpan: 1,
                format: this.numberFormat
            };
            row.cells.push(cl);
        }

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = {
            value: this.cleanFloat(metric.sicCompositeLatest),
            colSpan: 1,
            format: this.numberFormat
        };

        row.cells.push(cl);
        if (metric.showAverageValues) {
            cl = {
                value: this.cleanFloat(metric.sicCompositeAvergae),
                colSpan: 1,
                format: this.numberFormat
            };
            row.cells.push(cl);
        }

        tableRow.push(row);

        row = {};
        row.cells = [];
        cl = {
            value: this.cleanFloat(metric.largeCompositeLatest),
            colSpan: 1,
            format: this.numberFormat
        };

        row.cells.push(cl);
        if (metric.showAverageValues) {
            cl = {
                value: this.cleanFloat(metric.largeCompositeAverage),
                colSpan: 1,
                format: this.numberFormat
            };
            row.cells.push(cl);
        }

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = {
            value: this.cleanFloat(metric.smallCompositeLatest),
            colSpan: 1,
            format: this.numberFormat
        };

        row.cells.push(cl);
        if (metric.showAverageValues) {
            cl = {
                value: this.cleanFloat(metric.smallCompositeAverage),
                colSpan: 1,
                format: this.numberFormat
            };
            row.cells.push(cl);
        }

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = {
            value: this.cleanFloat(metric.highFinancialRiskLatest),
            colSpan: 1,
            format: this.numberFormat
        };

        row.cells.push(cl);
        if (metric.showAverageValues) {
            cl = {
                value: this.cleanFloat(metric.highFinancialRiskAverage),
                colSpan: 1,
                format: this.numberFormat
            };
            row.cells.push(cl);
        }

        tableRow.push(row);

        tableRow.forEach((row) => {
            if (row.cells) {
                row.cells = row.cells.map(cell => ({
                    ...cell,
                    ...this.mapBordersToCell()
                }))
            }
        })
        return tableRow;
    }

    public static getMetricCategoryMetricTable(metric: MetricValues): WorkbookSheetRow[] {
        let tableData: WorkbookSheetRow[] = [];
        let tableRow: WorkbookSheetRow[] = [];

        metric.tables.forEach((x) => {
            tableRow = this.getMetricTableRow(x);
            if (tableData.length > 0) {
                tableData = this.concatenateRows(tableData, tableRow);
            } else {
                tableData = tableRow;
            }
        });

        return tableData;
    }

    public static getMetricCategoryTable(category: MetricCategory): WorkbookSheetRow[] {
        let titleData: WorkbookSheetRow[] = [];
        let tableData: WorkbookSheetRow[] = [];
        let resultData: WorkbookSheetRow[] = [];
        let titleWidth = 2;

        if (category.metricCategoryId === Category.BetasLevered || category.metricCategoryId === Category.BetasUnlevered) {
            category.metrics.forEach((x) => {
                tableData = this.getMetricCategoryMetricTable(x);
                titleWidth = ExcelExportUtil.getWidthOfWidestRow(tableData);
                titleData = this.getBetasMtericHeaders(x.name, titleWidth);
                tableData = titleData.concat(tableData);
                if (resultData.length > 0) {
                    resultData = this.concatenateRows(resultData, tableData);
                } else {
                    resultData = tableData;
                }
            });
            let metricsWidth = ExcelExportUtil.getWidthOfWidestRow(resultData)
            let metrictitleData = this.getMetricTitle(category.metricCategoryName, metricsWidth);
            resultData = metrictitleData.concat(resultData);

        } else {
            category.metrics.forEach((x) => {
                tableData = this.getMetricCategoryMetricTable(x);
                titleWidth = ExcelExportUtil.getWidthOfWidestRow(tableData);
                titleData = this.getMetricTitle(x.name, titleWidth);
                tableData = titleData.concat(tableData);
                if (resultData.length > 0) {
                    resultData = this.concatenateRows(resultData, tableData);
                } else {
                    resultData = tableData;
                }
            });
        }

        return resultData;

    }

    public static getMetricRowCategories(category: MetricCategory): WorkbookSheetRow[] {
        let rowResults: WorkbookSheetRow[] = [];
        let rowData: WorkbookSheetRow[] = [];
        let compData: WorkbookSheetRow[] = [];
        let tableData: WorkbookSheetRow[] = [];
        let categoryWidth = 8;

        tableData = this.getMetricCategoryTable(category);

        if (category.showCompositeLabels) {
            compData = this.getCompLabels();
            tableData = this.concatenateRows(compData, tableData)
        }

        categoryWidth = ExcelExportUtil.getWidthOfWidestRow(tableData);


        rowData = this.getMetricCategoryTitle(category.metricCategoryName, categoryWidth);
        rowResults = rowData.concat(tableData);




        return rowResults;
    }

    public static getIndustryStatisticsCategoryGroups(statisticsCategory: StatisticsCategory): WorkbookSheetRow[] {
        const rowSets: WorkbookSheetRow[][] = [];
        let rowSetCurrent: WorkbookSheetRow[] = [];
        let rowSetResult: WorkbookSheetRow[] = [];
        let rowWidth = 0;

        statisticsCategory.statisticsGroup.forEach((x, i) => {
            const rowSet: WorkbookSheetRow[] = this.getStatisticsCategory(x, i);
            const curWidth: number = ExcelExportUtil.getWidthOfWidestRow(rowSet);
            if (curWidth + rowWidth <= this.categoryRowWidth) {
                if (rowSetCurrent.length > 0) {
                    rowSetCurrent = this.concatenateRows(rowSetCurrent, rowSet);
                    rowWidth += curWidth;
                } else {
                    rowSetCurrent = rowSet;
                    rowWidth = curWidth;
                }
            } else {
                rowSets.push(rowSetCurrent);
                rowSetCurrent = rowSet;
                rowWidth = curWidth;
            }
        });

        if (rowSetCurrent.length > 0) {
            rowSets.push(rowSetCurrent);
        }

        rowSets.forEach((x: WorkbookSheetRow[]) => rowSetResult = rowSetResult.concat(x));

        return rowSetResult;
    }

    private static getIntlCompanyValues(company: IntlCompany): { sales: number, assets: number } {
        if (company && company.SalesUsd) {
            return { sales: company.SalesUsd, assets: company.AssetsUsd };
        }
        if (company && company.SalesEur) {
            return { sales: company.SalesEur, assets: company.AssetsEur };
        }
        if (company && company.SalesGbp) {
            return { sales: company.SalesGbp, assets: company.AssetsGbp };
        }

        return { sales: 0, assets: 0 };
    }

    private static intlAssetsHeader(currency: string): string {
        const currencyToLower = currency.toLowerCase();

        if (currencyToLower === CurrencyTypes.USD) {
            return AssetsTypes.USD;
        } else if (currencyToLower === CurrencyTypes.EUR) {
            return AssetsTypes.EUR;
        } else if (currencyToLower === CurrencyTypes.GBP) {
            return AssetsTypes.GBP;
        }

        return 'Assets';
    }

    private static intlSalesHeader(currency: string): string {
        const currencyToLower = currency.toLowerCase();

        if (currencyToLower === CurrencyTypes.USD) {
            return SalesTypes.USD;
        } else if (currencyToLower === CurrencyTypes.EUR) {
            return SalesTypes.EUR;
        } else if (currencyToLower === CurrencyTypes.GBP) {
            return SalesTypes.GBP;
        }

        return 'Sales';
    }

    private static getIntlCompanyDataRow(company: IntlCompany, type: string): WorkbookSheetRow {
        const assetsValue = this.getIntlCompanyValues(company).assets != null ? this.getIntlCompanyValues(company).assets.toFixed() : '0';
        const salesValue = this.getIntlCompanyValues(company).sales != null ? this.getIntlCompanyValues(company).sales.toFixed() : '0';
        return {
            cells: [{
                value: company.Name,
                ...this.mapBordersToCell()
            }, {
                value: parseFloat(assetsValue),
                format: assetsValue.length > 3 ? this.numberwithcommaFormat : this.numberwithoutcommaFormat,
                ...this.mapBordersToCell()
            }, {
                value: parseFloat(salesValue),
                format: salesValue.length > 3 ? this.numberwithcommaFormat : this.numberwithoutcommaFormat,
                ...this.mapBordersToCell()
            }, {
                value: type,
                ...this.mapBordersToCell()
            }]
        };
    }

    private static getUSCompanyDataRow(company: UsCompany, type: string): WorkbookSheetRow {
        const assetsValue = company.Assets != null ? company.Assets.toFixed() : company.AssetsUsd.toFixed();
        const salesValue = company.Sales != null ? company.Sales.toFixed() : company.SalesUsd.toFixed();
        return {
            cells: [{
                value: company.Name,
                ...this.mapBordersToCell()
            }, {
                value: parseFloat(assetsValue),
                format: assetsValue.length > 3 ? this.numberwithcommaFormat : this.numberwithoutcommaFormat,
                ...this.mapBordersToCell()
            }, {
                value: parseFloat(salesValue),
                format: salesValue.length > 3 ? this.numberwithcommaFormat : this.numberwithoutcommaFormat,
                ...this.mapBordersToCell()
            }, {
                value: type,
                ...this.mapBordersToCell()
            }]
        };
    }






    public static getAboutDataType(aboutdata: AboutSpreadSheetData[]): WorkbookSheetRow[] {

        let aboutDetails = aboutdata;
        const workBookSheetRows: WorkbookSheetRow[] = [];

        let headerrow: WorkbookSheetRow = {
            cells: [this.styleAboutHeaders("Data Point", 1), this.styleAboutHeaders("Investor Perspective Currency", 1), 
                    this.styleAboutHeaders("Source", 1), this.styleAboutHeaders("Module", 1)]
        }

        workBookSheetRows.push(headerrow);


        aboutDetails.forEach(data => {
            const row: WorkbookSheetRow = {
                cells: [{
                    value: data.DataPoint,
                    bold: true,
                    colSpan: 1,
                    // color: this.KrollShadow
                },
                {
                    value: data.InvestorPerspectiveCurrency,
                    colSpan: 1,
                    // color: this.KrollShadow
                },
                {
                    value: data.Source,
                    colSpan: 1,
                    // color: this.KrollShadow
                },
                {
                    value: data.Module,
                    colSpan: 1,
                    // color: this.KrollShadow
                }],
            };
            workBookSheetRows.push(row);
        });

        return workBookSheetRows;
    }
    //object cannot contain duplicate keys so renamed to shortcut and mapped to actual keyNames
    private static getDuplicateKeyName(key: string): string {
        let duplicateDummyData = ['Risk-free R', 'Size P', 'Cost of D', 'Long-term Growth E', 'Equity Risk P'];
        let duplicateKeyValue: any = {
            'Risk-free R': "Risk-free Rate*",
            'Size P': "Size Premium*",
            'Cost of D': "Cost of Debt",
            'Long-term Growth E': 'Long-term Growth Estimate',
            'Equity Risk P': 'Equity Risk Premium'

        };
        if (duplicateDummyData.some(s => s == key)) {
            return duplicateKeyValue[key];
        } else {
            return key;
        }


    }

    public static getAboutHeaders(moduleName: string): WorkbookSheetRow[] {

        let headerSheetRows: WorkbookSheetRow[] = [];
        let aboutDetails: any[] = this.aboutStaticDetails(moduleName == 'usiBenchmarking' ? 'UsiheaderData' : 'intlHeaderData');

        aboutDetails.forEach(s => {
            let tempRow: WorkbookSheetRow = {};
            if (s === 'Source' || s === 'Data Sources') {
                tempRow = {
                    cells: [this.styleAboutHeaders(s, 2)]
                };
            }

            else if (s === 'Exported on') {
                tempRow = {
                    cells: [{
                        // color:this.KrollShadow,
                        value: s + ": " + moment().format('MM/DD/YYYY')
                    },

                    ]
                };
            } else {

                tempRow = {
                    cells: [{
                        value: s,
                        // color:this.KrollShadow
                    }]
                };
            }
            headerSheetRows.push(tempRow)
        });

        return headerSheetRows;
    }

    public static getFooterData(moduleName: string): WorkbookSheetRow[] {

        let footerSheetRows: WorkbookSheetRow[] = [];
        let footerAdditionalDetails: any[] = this.aboutStaticDetails(moduleName == 'usiBenchmarking' ? 'UsiadditionalInfo' : 'intlAdditionalInfo');

        footerSheetRows.push({ cells: [{ value: "" }] }, { cells: [{ value: "" }] });
        const footerHeading: WorkbookSheetRow = {
            cells: [this.styleAboutHeaders('Additional Info & Methodology', 2)]
        }

        footerSheetRows.push(footerHeading);

        footerAdditionalDetails.forEach(el => {
            let tempRow: WorkbookSheetRow = {};
            tempRow = {
                cells: [{
                    value: el,
                    // color:this.KrollShadow,
                }]
            };
            footerSheetRows.push(tempRow);
        });


        return footerSheetRows;





    }

    private static aboutStaticDetails(modsectionName: string): any {
        let aboutStaticDetails: any = {

            'UsiheaderData': [`Source`, `Kroll Cost of Capital Navigator: U.S. Industry Benchmarking Dataset`, 'Exported on', "", `Data Sources`, `Data Sources used with permission. All rights reserved. Calculations performed by Kroll, LLC.`, ""],

            'UsiData': {
                'Company-level Financial Metrics': 'S&P Global Market Intelligence',
                'Risk-free Rate*': `Kroll Research. For more information, please visit the Cost of Capital Resource Center.`,
                'Risk-free R': `U.S. Federal Reserve`,
                'Equity Risk Premium*': `Kroll Research. For more information, please visit the Cost of Capital Resource Center.`,
                'Size Premium*': `The Center for Research in Security Prices`,
                'Size P': `S&P Global Market Intelligence`,
                'Cost of Debt': `S&P Global Market Intelligence`,
                'Cost of D': `Bloomberg`,
                'Fama French': `Fama/French Data Library`,
                'Market Index': `MSCI`,
                'Debt Betas': `Bloomberg`,
                'Tax Rates': `Professor John Graham, Duke University`,
                'Credit (and Synthetic Credit) Ratings': `S&P Global Market Intelligence`,
                'Long-term Growth Estimate': `U.S. Federal Reserve`,
                'Long-term Growth E': `S&P Global Market Intelligence`
            },

            'UsiadditionalInfo': [
                `For more information and the data assumptions used in this analysis, visit the Cost of Capital Navigator's Resources Library.`, `For general methodology, company screening process, and information on the median and industry composites, visit the Cost of Capital Navigator's Resources Library.`, `For more information on the calculation of the industry financial statistics used in this analysis, visit the Cost of Capital Navigator's Resource Library.`],


            'intlHeaderData': [`Source`, `Kroll Cost of Capital Navigator: U.S. and International Industry Benchmarking Datasets`, 'Exported on', "", `Data Sources`, `Data Sources used with permission. All rights reserved. Calculations performed by Kroll, LLC.`, ""],

            'intlData': {
                'Company-level Financial Metrics': 'S&P Global Market Intelligence',
                'Risk-free Rate*': `Kroll Research. For more information, please visit the Cost of Capital Resource Center.`,
                'Risk-free R': `U.S. Federal Reserve`,
                'Equity Risk Premium*': `Kroll Research. For more information, please visit the Cost of Capital Resource Center.`,
                'Equity Risk P': 'Credit Suisse Global Investment Returns Yearbook',
                'Cost of Debt': `Bloomberg`,
                'Market Index': `MSCI`,
                'Debt Betas': `Bloomberg`,
                'Tax Rates': `S&P Global Market Intelligence`,
                'Credit (and Synthetic Credit) Ratings': `S&P Global Market Intelligence`,
                'Long-term Growth Estimate': `S&P Global Market Intelligence`,
            },

            'intlAdditionalInfo': [
                `For more information and the data assumptions used in this analysis, visit the Cost of Capital Navigator's Resources Library.`, `For general methodology, company screening process, and information on the median and industry composites, visit the Cost of Capital Navigator's Resources Library.`, `For more information on the calculation of the industry financial statistics used in this analysis, visit the Cost of Capital Navigator's Resource Library.`]


        }
        return aboutStaticDetails[modsectionName];
    }

    private static styleAboutHeaders(title: string, colSpan: number): WorkbookSheetRowCell {

        const headerCell: WorkbookSheetRowCell = {
            value: title,
            colSpan: colSpan,
            color: this.krollblue,
            bold: true,
            fontSize: 18.5,
            verticalAlign: 'center'
        }

        return headerCell;
    }

    public static getIndustryAssumptionSheet(industryAssumptions: IndustryAssumptions, currency: string, dataAsof: Date, region: string, isFinancial: boolean): WorkbookSheetRow[] {

        const assumptionRows: WorkbookSheetRow[] = []
        const assumptionProp = ['CostOfEquity', 'CostOfDebt', 'DebtBeta']
        region = region == 'United States' ? 'U.S.' : region;
        var isDataAvailable = false;

        assumptionProp.forEach(s => {
            const objkey = s as keyof IndustryAssumptions;
            if (industryAssumptions[objkey].length > 0) {
                isDataAvailable = true;
            }
        });

        if (isDataAvailable) {
            const header: WorkbookSheetRow[] = [
                {
                    cells: [],
                },
                {
                    cells: [
                        {
                            value: `${region + ' Industry Assumptions Used'}`,
                            color: this.orangeColor,
                            bold: true,

                        }
                    ]
                },
                {
                    cells: [
                        {
                            value: `Data as of: ${moment(dataAsof).format('MM/DD/YYYY')}`,
                            bold: true
                        }
                    ]

                },
                {
                    cells: []
                }
            ]
            assumptionRows.push(...header)
            assumptionProp.forEach((d: string) => {
                const assumptions = industryAssumptionJSON;
                const key = d as keyof IndustryAssumptions;
                assumptionRows.push(...this.getEachAssumptionRows(assumptions[d], industryAssumptions[key], currency, isFinancial),)
            })
        }
        return assumptionRows;


    }


    private static getEachAssumptionRows(MetricCategory: string, metric: IndustryMetric[], currency: string, isFinancial: boolean): WorkbookSheetRow[] {
        const worksheetRow: WorkbookSheetRow[] = [];

        const tittleRow: WorkbookSheetRow = {
            cells: [
                {
                    value: MetricCategory + ":",
                    background: this.grayBackGround,
                    bold: true,
                    ...this.mapBordersToCell()
                },
                {
                    value: `${'Values in ' + currency}`,
                    background: this.grayBackGround,
                    bold: true,
                    textAlign: 'right',
                    ...this.mapBordersToCell()
                },
            ]
        }

        if (metric && metric.length > 0) {
            const dataRows = metric.map(d => this.mapValuesBasedonType(d));
            worksheetRow.push(tittleRow, ...dataRows)
        } else {
            const noDataRow: WorkbookSheetRow = {
                cells: [
                    {
                        value: 'No Data Available',
                        textAlign: 'center',
                        colSpan: 2,
                        ...this.mapBordersToCell()
                    }
                ]
            }
            worksheetRow.push(tittleRow, noDataRow);
        }
        return worksheetRow;
    }


    private static mapValuesBasedonType(metric: IndustryMetric): WorkbookSheetRow {

        let metricRow: WorkbookSheetRow;
        metricRow = {
            cells: [
                {
                    value: metric.MetricName,
                    textAlign: 'left',
                    ...this.mapBordersToCell()

                },
                {
                    value: metric.SICCompositeLatest ? metric.SICCompositeLatest : 0,
                    textAlign: 'right',
                    format: metric.MetricCategory !== enumAssumptions.DebtBeta ? '0.00%' : '0.00',
                    ...this.mapBordersToCell()

                }
            ]
        }

        return metricRow;


    }

    private static mapBordersToCell(): any {
        return {
            borderBottom: { size: 1, color: "#ECEEEF" },
            borderLeft: { size: 1, color: "#ECEEEF" },
            borderRight: { size: 1, color: "#ECEEEF" },
            borderTop: { size: 1, color: "#ECEEEF" },
        };
    }

}
