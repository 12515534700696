import { Component, OnInit } from '@angular/core';
import { SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs/internal/Observable';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { EstimatesListState } from 'src/app/_navigator/estimates-list-store/estimates-list-state';
import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';

@Component({ templateUrl: './new-estimate-modal.component.html' })
export class NewEstimateModalComponent extends SubscriberEntity implements OnInit {
    public name = '';
    public isDuplicate = false;
    public selection?: string;
    public isInValidInput = false;
    @Select(EstimatesListState.get) public estimates!: Observable<Estimate[] | undefined>;

    constructor(
        public industryDataSharingService: IndustryDataSharingService
    ) {
        super();
    }

    public ngOnInit(): void {
        const emptyIndustry: Industry[] = new Array<Industry>();
        emptyIndustry.push({
            CodeId: 0,
            GicIndustryCode: '',
            SicIndustryCode: '',
            Sector: ''
        } as Industry);
        this.industryDataSharingService.setSharedIndustries = emptyIndustry;
    }

    public validateInput(value: string): void {

        const regex = /^[a-zA-Z0-9\s-]+$/;
        const isValidInput = regex.test(value);
        if (value && !isValidInput) {
            this.isInValidInput = true;
            this.selection = undefined;

            return;
        } else {
            this.isInValidInput = false
        }


        this.estimates.onceDefined((estimates: Estimate[]) => {
            this.isDuplicate = estimates.length > 0 && estimates.some((x) => x.Name.toLowerCase() === this.name.toLowerCase());
            // this.selection = this.isDuplicate ? undefined : this.name = this.name.replace(/[^a-zA-Z0-9]{1,}/g, "");
            this.selection = this.isDuplicate ? undefined : this.name = this.name;
        });
    }
}
