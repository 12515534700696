import { Component, Input } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import * as moment from 'moment';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { PrimeManager } from 'src/app/_navigator/modal/pmodal.manager';
import { TearSheetService } from './data/tear-sheet.service';
import { FlatenMtericCategory } from './models/metric-category.model';

@Component({
    selector: 'metric',
    templateUrl: './metric.component.html'
})

export class MetricComponent {
    @Input() public metric!: FlatenMtericCategory;

    constructor() { }


}
