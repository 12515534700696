import { ErrorHandler, NgModule } from '@angular/core';
import { CostofCapitalInputsComponent } from './components/costof-capital-inputs/costof-capital-inputs.component';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { CommonModules } from '../_config/common-modules';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { SizePremiumComponent } from './components/size-premium/size-premium.component';
import { RiskPremiumOverRiskFreeRateComponent } from './components/risk-premium-over-risk-free-rate/risk-premium-over-risk-free-rate.component';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzTableModule } from 'ng-zorro-antd/table';
import { RiskFreeRatesAndEquityRiskPremiumComponent } from './components/risk-free-rates-and-equity-risk-premium/risk-free-rates-and-equity-risk-premium.component';
import { CocInputsSharedService } from './service/cocInputShared.service';
import { NumberWithCommasPipe } from './number-with-commas.pipe';
import { BetasandIndustryRiskPremiumComponent } from './components/betas-industry-risk-premium-inputs/betas-industry-risk-premium.component';
import { PercentageWithCommasPipe } from './percentage-with-commas.pipe';
import { UpdateOperatorSignPipe } from './update-operator-sign.pipe';
import { CocinputsExcelExportService } from './service/cocinputs-excel-export.service';
import { TrendsOverTimeRfrAndErpComponent } from './components/risk-free-rates-and-equity-risk-premium/trends-over-time-rfr-and-erp/trends-over-time-rfr-and-erp.component';
import { DialogModule } from 'primeng/dialog';
import { CountryRiskPremiumRelativeVoltalityComponent } from './components/country-risk-premium-relative-voltality/country-risk-premium-relative-voltality.component';
import { ErrorHandlerService } from 'src/error-handler.service';
import { DebtBetasTrendsOverTimeComponent } from './components/debt-betas-trends-over-time/debt-betas-trends-over-time.component';


@NgModule({
    declarations: [
        CostofCapitalInputsComponent,
        SizePremiumComponent,
        RiskPremiumOverRiskFreeRateComponent,
        RiskFreeRatesAndEquityRiskPremiumComponent,
        BetasandIndustryRiskPremiumComponent,
        NumberWithCommasPipe,
        PercentageWithCommasPipe,
        UpdateOperatorSignPipe,
        TrendsOverTimeRfrAndErpComponent,
        CountryRiskPremiumRelativeVoltalityComponent,
        DebtBetasTrendsOverTimeComponent
    ],
    imports: [
        ...CommonModules,
        DialogModule,
        NzFormModule,
        NzInputModule,
        NzButtonModule,
        NzIconModule,
        NzTabsModule,
        NzSpaceModule,
        NzDividerModule,
        NzTableModule

    ],

    providers: [
        CocInputsSharedService,
        CocinputsExcelExportService,
    ],
    entryComponents: [TrendsOverTimeRfrAndErpComponent]
})
export class CocInputsModule { }
