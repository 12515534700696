import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager, Spinner } from '@concurrency/angular';
import { Util } from '@concurrency/core';
import { Store } from '@ngxs/store';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { BetaCompanyInfo, BetaCompanyMetricsType, BetaMetricType, BetaSelections, BetaSelectionsType, BetaValues } from 'src/app/_api/responses/beta-estimate.response';
import { IntlDataUnlevering } from 'src/app/_api/responses/intl-data.response';
import { MetricInputs } from 'src/app/_api/responses/metric-inputs.response';
import { BetaEstimate } from 'src/app/_navigator/data/model/beta-estimate.model';
import { TypeaheadFormatters } from 'src/app/_navigator/data/util/typeahead-formatters.util';
import { ArchiveBetaEstimate, CloneBetaEstimate, GetBetaEstimate, GetBetaEstimates, RenameBetaEstimate } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-actions';
import { BetaEstimatesListState, SortType } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-state';
import { BetaService } from 'src/app/beta/beta.service';
import { BetaComparbleCompanyResults, BetaStatistics, LeveredBetas, ReleveredInputs, Statistics, UnleveringInputs } from 'src/app/beta/beta.types';
import { ArchiveEstimateModalComponent } from '../_modals/archive-estimate-model.component';
import { EstimateSharedService } from './estimate-shared-service';
import TableAction from 'src/app/_navigator/common/commonTable/interface/tableActionInterface';
import { ColumnItem } from 'src/app/_navigator/common/commonTable/interface/tableColumnInterface';
// import { DataStore } from 'src/app/_navigator/data/store/data.store';

@Component({
    selector: 'beta-estimates-table',
    templateUrl: './beta-estimates-table.component.html'
})
export class BetaEstimatesTableComponent {
    private allowOutsideToggleFor?: string;
    private sortType = SortType.Date;
    private sortDescending = true;
    @Input() public moduleEstimates!: BetaEstimate[] | undefined;
    public actions = this.getActions();
    public expandedEstimate?: BetaEstimate;
    public visibleDropdown?: string;
    public sameNameError?: boolean;
    public isRename: any = {};
    public SortTypes = SortType;
    public rename: string | undefined = '';
    public TypeaheadFormatters = TypeaheadFormatters;
    public EstimateType = EstimateType;
    public companies: string[] = [];
    public pageIndex: number = 1;
    public isInValidInput = false;

    constructor(
        // private dataStore: DataStore,
        private store: Store,
        private router: Router,
        private modalManager: ModalManager,
        private spinner: Spinner,
        private betaService: BetaService,
        private estimateShared: EstimateSharedService,
        // public industryDataSharingService: IndustryDataSharingService
    ) {
        this.sort();
    }





    getActions(): TableAction<BetaEstimate>[] {
        return [
            {
                icon: 'edit',
                handler: (item: BetaEstimate) => this.routeTo(item),
                tooltip: 'Edit'
            },
            {
                icon: 'copy',
                handler: (item: BetaEstimate) => this.duplicate(item),
                tooltip: 'Copy'
            },
            {
                icon: 'delete',
                handler: (item: BetaEstimate) => this.delete(item),
                tooltip: 'Delete'
            }
        ];
    }


    public betaTableColumns: ColumnItem<BetaEstimate>[] = [
        {
            title: `Estimate Name`,
            compare: (a: BetaEstimate, b: BetaEstimate) => a.Name.localeCompare(b.Name),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'Name',
            type: 'estimateName'
        },
        {
            title: `Created/Modified Date`,
            compare: (a: BetaEstimate, b: BetaEstimate) => this.sortByDate(a, b, 'Updated'),
            sortDirections: ['ascend', 'descend'],
            sortOrder: 'descend',
            key: 'Updated',
            formate: 'MM/dd/yyyy',
            type: 'date'
        },
        {
            title: `Valuation Date`,
            compare: (a: BetaEstimate, b: BetaEstimate) => this.sortByDate(a, b, 'ValuationDate'),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'ValuationDate',
            formate: 'MM/dd/yyyy',
            type: 'date'
        },
        {
            title: `Currency of WACC`,
            compare: (a: BetaEstimate, b: BetaEstimate) => a.currencyWithAcronom?.localeCompare(b.currencyWithAcronom),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'currencyWithAcronom',

        },
        {
            title: `Subjected Company Location`,
            compare: (a: BetaEstimate, b: BetaEstimate) => a.SubjectCompanyLocation?.localeCompare(b.SubjectCompanyLocation),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'SubjectCompanyLocation',

        },
        {
            title: `Return Frequency`,
            compare: (a: BetaEstimate, b: BetaEstimate) => a.ReturnFrequency?.localeCompare(b.ReturnFrequency),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'ReturnFrequency',
        },
        {
            title: `Lookback Period`,
            compare: (a: BetaEstimate, b: BetaEstimate) => a.LookbackPeriod?.localeCompare(b.LookbackPeriod),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'LookbackPeriod',
        },
        {
            title: `MarketIndex`,
            compare: (a: BetaEstimate, b: BetaEstimate) => a.Marketindex?.localeCompare(b.Marketindex),
            sortDirections: ['ascend', 'descend'],
            sortOrder: null,
            key: 'Marketindex',
        },



    ]

    public sortByDate = (a: any, b: any, field: string) => {
        {
            if (a[field] < b[field]) {
                return -1;
            }
            if (a[field] > b[field]) {
                return 1;
            }
            return 0;
        }
    }


    private updateCompanies(values: BetaValues[], betaCompanies: BetaCompanyInfo[]): void {

        // let companies = {} as BetaComparbleCompanyResults;
        const companies: BetaComparbleCompanyResults = {
            GVKEY: '',
            CompanyName: '',
            Observations: 0,
            Ticker: '',
            IncorporationCountry: '',
            HeadquarterCountry: null,
            IndustryCodeDescription: null,
            Dataasof: '',
            mAsOf: '',
            Currency: '',
            Sales: null,
            Assets: null,
            EBITDA: null,
            TotalDebt: null,
            MarketCap: null,
            KeyWords: '',
            IsHidden: false,
            PageCount: 0,
            PageSize: 0,
            TotalCount: 0,
            Exchange: null,
            Website: "",
            TrendsOverTimeDisplay: true
        };
        values.forEach((element) => {
            if (element.BetaMetricId === BetaMetricType.NumObservations) {
                companies.GVKEY = element.GvKey;
                companies.Observations = element.BetaValue !== null ? element.BetaValue : 0;
                companies.CompanyName = element.CompanyName;
                companies.Dataasof = element.DataAsOf;
            } else if (element.BetaMetricId === BetaMetricType.Sales) {
                companies.Sales = element.BetaValue;
            } else if (element.BetaMetricId === BetaMetricType.EBITDA) {
                companies.EBITDA = element.BetaValue;
            } else if (element.BetaMetricId === BetaMetricType.TotalAssets) {
                companies.Assets = element.BetaValue;
            } else if (element.BetaMetricId === BetaMetricType.MVEquity) {
                companies.MarketCap = element.BetaValue;
                companies.mAsOf = element.DataAsOf;
            } else if (element.BetaMetricId === BetaMetricType.BVDebt) {
                companies.TotalDebt = element.BetaValue;
            }
        });
        if (betaCompanies.length > 0) {
            betaCompanies.forEach((element) => {
                if (element.CompanyMetricId === BetaCompanyMetricsType.Industry) {
                    companies.IndustryCodeDescription = element.CompanyInfoValue;
                } else if (element.CompanyMetricId === BetaCompanyMetricsType.Keywords) {
                    companies.KeyWords = element.CompanyInfoValue;
                } else if (element.CompanyMetricId === BetaCompanyMetricsType.HeadquarterCountry) {
                    companies.HeadquarterCountry = element.CompanyInfoValue;
                } else if (element.CompanyMetricId === BetaCompanyMetricsType.IncorporationCountry) {
                    companies.IncorporationCountry = element.CompanyInfoValue;
                } else if (element.CompanyMetricId === BetaCompanyMetricsType.Exchange) {
                    companies.Exchange = element.CompanyInfoValue;
                } else if (element.CompanyMetricId === BetaCompanyMetricsType.Website) {
                    companies.Website = element.CompanyInfoValue;
                }
            });

            if (betaCompanies.some((x) => x.IsHidden === true)) {
                companies.IsHidden = true;
                companies.TrendsOverTimeDisplay = false;
                this.betaService.UserHiddenCompanies.push(companies.GVKEY);
            }

        }

        this.betaService.finalSelectedCompanies.push(companies);
        this.betaService.gvKeys.push(companies.GVKEY);
    }

    // private updateCompanyInfo(betaCompanies: BetaCompanyInfo[], gvKey: any): void {
    //     let companies = this.betaService.finalSelectedCompanies.filter((x: BetaComparbleCompanyResults) => x.GVKEY === gvKey);

    //     console.log(this.betaService.finalSelectedCompanies);
    // }

    private updateLeveredBetas(values: BetaValues[]): void {
        const betas = {} as LeveredBetas;
        values.forEach((element) => {
            if (element.BetaMetricId === BetaMetricType.OLSLevered) {
                betas.GVKEY = element.GvKey;
                betas.OLSBeta = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.SumLevered) {
                betas.SumBeta = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.VasicekLevered) {
                betas.VasicekAdjusted = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.BlumeLevered) {
                betas.BlumeAdjusted = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.PortfolioLevered) {
                betas.PeerGroup = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            }
            this.betaService.betasAsOf = element.DataAsOf;
        });
        if (betas.GVKEY !== null) {
            this.betaService.leveredBetas.push(betas);
        }
    }

    private updateUnleveredBetas(values: BetaValues[]): void {
        const betas = {} as LeveredBetas;
        values.forEach((element) => {
            if (element.BetaMetricId === BetaMetricType.OLSUnlevered) {
                betas.GVKEY = element.GvKey;
                betas.OLSBeta = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.SumUnlevered) {
                betas.SumBeta = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.VasicekUnlevered) {
                betas.VasicekAdjusted = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.BlumeUnlevered) {
                betas.BlumeAdjusted = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.PortfolioUnlevered) {
                betas.PeerGroup = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            }
        });
        if (betas.GVKEY !== null) {
            this.betaService.unleveredBetas.push(betas);
        }
    }

    private updateBetaStatistics(values: BetaValues[]): void {
        const betas = {} as BetaStatistics;
        values.forEach((element) => {
            if (element.BetaMetricId === BetaMetricType.RSquareOLS) {
                betas.GVKey = element.GvKey;
                betas.AdjustedOlsRSquare = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.RSquareSum) {
                betas.AdjustedSumRSquare = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.StandardError) {
                betas.StandardError = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.TStat) {
                betas.TStat = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.IsSig90) {
                betas.SignificantLevel1 = element.BetaValue != null && element.BetaValue < 0.1 ? 'Yes' : 'No';
            } else if (element.BetaMetricId === BetaMetricType.IsSig95) {
                betas.SignificantLevel2 = element.BetaValue != null && element.BetaValue < 0.05 ? 'Yes' : 'No';
            } else if (element.BetaMetricId === BetaMetricType.IsSig99) {
                betas.SignificantLevel3 = element.BetaValue != null && element.BetaValue < 0.01 ? 'Yes' : 'No';
            }
        });
        if (betas.GVKey !== null) {
            this.betaService.betaStatistics.push(betas);
        }
    }

    private updateUnleveringBetas(values: BetaValues[]): void {
        const betas = {} as UnleveringInputs;
        values.forEach((element) => {
            if (element.BetaMetricId === BetaMetricType.BVDebt) {
                betas.GVKEY = element.GvKey;
                betas.DataAsOf = element.DataAsOf;
                betas.TotalDebt = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.MVEquity) {
                betas.MarketCap = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
                betas.mAsOf = element.DataAsOf;
            } else if (element.BetaMetricId === BetaMetricType.DTC) {
                betas.Debt_TotalCapital = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            }
            if (betas.MarketCap !== null && (betas.TotalDebt !== null && betas.TotalDebt !== '-')) {
                betas.Debt_Equity = this.betaService.RoundDecimalValue((Util.round(betas.TotalDebt, 1) / Util.round(betas.MarketCap, 1)) * 100, 2);
            } else {
                betas.Debt_Equity = '-';
            }
        });
        if (betas.GVKEY !== null) {
            this.betaService.unleveringInputs.push(betas);
        }
    }

    private updateUnleverSelections(selections: BetaSelections[]): void {

        selections.forEach((element) => {
            if (element.SelectionMetricId === BetaSelectionsType.TaxRateUnlever) {
                const taxRates: IntlDataUnlevering = {
                    DataAsOf: element.DataAsOf,
                    Label: element.Context,
                    Source: element.Context,
                    Value: element.SelectionValue,
                    GVKEY: element.GVKEY
                };
                if (taxRates.GVKEY !== null) {
                    const unleveringBetas = this.betaService.unleveringInputs.find((x: UnleveringInputs) => x.GVKEY === taxRates.GVKEY);
                    if (unleveringBetas) {
                        unleveringBetas.TaxRate = element.SelectionValue;
                        unleveringBetas.Label = element.Context;
                    }
                    this.betaService.IntlTaxRates.push(taxRates);
                }
            }
        });

    }

    private updateReleverSelections(selections: BetaSelections[], betaType: string): void {
        const releveredInputs = {} as ReleveredInputs;
        this.betaService.betaTypeSelected = betaType;
        releveredInputs.BetaType = betaType;
        selections.forEach((element) => {
            if (element.SelectionMetricId === BetaSelectionsType.TargetCapitalStructure) {
                this.betaService.capitalRateSelected = this.betaService.RoundDecimalValue(element.SelectionValue, 2);
                this.betaService.capitalRateSelectedLabel = element.Context;
                this.betaService.originalTargetCapitalStructure = this.betaService.RoundDecimalValue(element.SelectionValue, 2);
                releveredInputs.TargetCapitalStructure = this.betaService.RoundDecimalValue(element.SelectionValue, 2);
                releveredInputs.OriginalTargetCapitalStructure = this.betaService.RoundDecimalValue(element.SelectionValue, 2);
                releveredInputs.TargetLabel = element.Context;

            } else if (element.SelectionMetricId === BetaSelectionsType.TaxRateRelever) {
                this.betaService.taxRateSelected = this.betaService.RoundDecimalValue(element.SelectionValue, 2);
                this.betaService.taxRateSelectedLabel = element.Context;
                releveredInputs.TaxRate = this.betaService.RoundDecimalValue(element.SelectionValue, 2);
                releveredInputs.TaxRateLabel = element.Context;
            }
        });
        this.betaService.releveredInputs = releveredInputs;

    }

    private updateReleverTargetCapitalOptions(): void {
        const UnleveringDebtTotalCapital = this.betaService.unleveringInputs.map((x) => x.Debt_TotalCapital);
        this.betaService.summaryStatisticsValues.AverageDebt_TotalCapital = this.betaService.getAverageofleveredBetas(UnleveringDebtTotalCapital.map((v) => v === null ? null : v), false);
        this.betaService.summaryStatisticsValues.MedianDebt_TotalCapital = this.betaService.getMedianofleveredBetas(UnleveringDebtTotalCapital.map((v) => v === null ? null : v), false);
    }

    private updateReleverTaxRateOptions(): void {
        const UnleveringInputTaxRate = this.betaService.unleveringInputs.map((x) => x.TaxRate);
        this.betaService.summaryStatisticsValues.AverageTaxRate = this.betaService.getAverageofleveredBetas(UnleveringInputTaxRate.map((v) => v === null ? null : v), false);
        this.betaService.summaryStatisticsValues.MedianTaxRate = this.betaService.getMedianofleveredBetas(UnleveringInputTaxRate.map((v) => v === null ? null : v), false);
    }

    private updateLeveredStatistics(values: BetaValues[]): void {
        values.forEach((element) => {
            if (element.BetaMetricId === BetaMetricType.MinLevered) {
                this.betaService.leveredminimumSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.AverageLevered) {
                this.betaService.leveredaverageSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.MedianLevered) {
                this.betaService.leveredmedianSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.Q1Levered) {
                this.betaService.leveredfirstQuartileSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.Q3Levered) {
                this.betaService.leveredthirdQuartileSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.MaxLevered) {
                this.betaService.leveredmaximumSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            }
        });

        const stats: Statistics = {
            Minimum: this.betaService.leveredminimumSummaryValue,
            Average: this.betaService.leveredaverageSummaryValue,
            Median: this.betaService.leveredmedianSummaryValue,
            FirstQuartile: this.betaService.leveredfirstQuartileSummaryValue,
            ThirdQuartile: this.betaService.leveredthirdQuartileSummaryValue,
            Maximum: this.betaService.leveredmaximumSummaryValue
        };
        this.betaService.leveredStatistics = stats;
    }

    private updateUnleveredStatistics(values: BetaValues[]): void {
        values.forEach((element) => {
            if (element.BetaMetricId === BetaMetricType.MinUnlevered) {
                this.betaService.unleveredMinimumSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.AverageUnlevered) {
                this.betaService.unleveredAverageSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.MedianUnlevered) {
                this.betaService.unleveredMedianSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.Q1Unlevered) {
                this.betaService.unleveredFirstQuartileSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.Q3Unlevered) {
                this.betaService.unleveredThirdQuartileSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.MaxUnlevered) {
                this.betaService.unleveredMaximumSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            }
        });
        const stats: Statistics = {
            Minimum: this.betaService.unleveredMinimumSummaryValue,
            Average: this.betaService.unleveredAverageSummaryValue,
            Median: this.betaService.unleveredMedianSummaryValue,
            FirstQuartile: this.betaService.unleveredFirstQuartileSummaryValue,
            ThirdQuartile: this.betaService.unleveredThirdQuartileSummaryValue,
            Maximum: this.betaService.unleveredMaximumSummaryValue
        };
        this.betaService.unleveredStatistics = stats;
    }

    private updateReleveredStatistics(values: BetaValues[]): void {
        values.forEach((element) => {
            if (element.BetaMetricId === BetaMetricType.MinRelevered) {
                this.betaService.minimumSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.AverageRelevered) {
                this.betaService.averageSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.MedianRelevered) {
                this.betaService.medianSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.Q1Relevered) {
                this.betaService.firstQuartileSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.Q3Relevered) {
                this.betaService.thirdQuartileSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            } else if (element.BetaMetricId === BetaMetricType.MaxRelevered) {
                this.betaService.maximumSummaryValue = element.BetaValue !== null ? this.betaService.RoundDecimalValue(element.BetaValue, 2) : '-';
            }
        });

        const stats: Statistics = {
            Minimum: this.betaService.minimumSummaryValue,
            Average: this.betaService.averageSummaryValue,
            Median: this.betaService.medianSummaryValue,
            FirstQuartile: this.betaService.firstQuartileSummaryValue,
            ThirdQuartile: this.betaService.thirdQuartileSummaryValue,
            Maximum: this.betaService.maximumSummaryValue
        };
        this.betaService.releveredStatistics = stats;
    }

    // public ngOnInit(): void { }

    public sort(sortType?: SortType): void {
        if (sortType === this.sortType) {
            this.sortDescending = !this.sortDescending;
        } else {
            if (sortType === SortType.Name) {
                this.sortDescending = false;
            } else if (sortType === SortType.Date) {
                this.sortDescending = true;
            }
        }

        this.sortType = sortType || SortType.Date;

        this.store.select(BetaEstimatesListState.sort(this.sortType, this.sortDescending)).onceDefined((sorted) => {
            this.moduleEstimates = [...this.estimateShared.getBetaEstimatesWithCurrencyAcronom(sorted)];
            this.pageIndex = 1;

        });
    }

    public routeTo(estimate: BetaEstimate): void {
        this.betaService.UserHiddenCompanies = [];
        this.betaService.betas = [];
        this.betaService.setEstimates();
        const request = this.store.dispatch(new GetBetaEstimate(estimate.EstimateId));
        // const request = this.dataStore.getBetaEstimate(betaEstimate.EstimateId);
        request.once(() => {
            const generalInputs: MetricInputs = {
                ValuationDate: estimate.ValuationDate,
                Frequency: estimate.ReturnFrequency,
                Location: estimate.SubjectCompanyLocation,
                Currency: estimate.CurrencyOfWacc,
                LookbackPeriod: estimate.LookbackPeriod,
                MarketIndex: 0
            };
            this.betaService.estimateId = estimate.EstimateId;
            this.betaService.metricInputs = generalInputs;
            this.betaService.locationName = generalInputs.Location;
            this.betaService.marketIndexName = estimate.Marketindex;
            this.betaService.isBetaEstimate = true;
            this.betaService.editClicked = false;
            this.betaService.lookbackPeriodOptions = [];
            this.betaService.returnFrequencyOptions = [];
            this.betaService.currencyofwaccoptions = [];
            this.betaService.subjectCompanyLocationOptions = [];
            this.betaService.marketIndexOptions = [];
            this.betaService.gvKeys = [];
            if (generalInputs.ValuationDate !== ''
                && generalInputs.Frequency !== null
                && generalInputs.Currency !== null
                && generalInputs.LookbackPeriod !== null
                && generalInputs.Location !== null
                && this.betaService.marketIndexName !== null) {
                this.betaService.betasUpdated = true;
            }
            if (estimate.Values) {
                const gvKeys = estimate.Values.map((beta) => beta.GvKey).filter((value, index, self) => self.indexOf(value) === index);
                gvKeys.forEach((key) => {
                    const betaValues = estimate.Values.filter((x) => x.GvKey === key);
                    const betaCompanies = estimate.Companies ? estimate.Companies.filter((x) => x.GVKEY === key) : [];
                    if (key !== null) {
                        this.updateCompanies(betaValues, betaCompanies);
                        //  this.updateCompanyInfo(betaCompanies, key);
                        this.updateLeveredBetas(betaValues);
                        this.updateBetaStatistics(betaValues);
                        this.updateUnleveredBetas(betaValues);
                        this.updateUnleveringBetas(betaValues);
                        if (estimate.Selections) {
                            this.updateUnleverSelections(estimate.Selections.filter(d => d.GVKEY === key));
                        }
                    } else {
                        this.updateReleverTargetCapitalOptions();
                        this.updateReleverTaxRateOptions();
                        if (estimate.Selections) {
                            this.updateReleverSelections(estimate.Selections, estimate.BetaReleverType);
                        }
                        this.updateLeveredStatistics(betaValues);
                        this.updateUnleveredStatistics(betaValues);
                        this.updateReleveredStatistics(betaValues);
                    }
                });
                this.betaService.getAllSummaryStatistics();
            }

            if (this.betaService.UserHiddenCompanies.length > 0) {
                this.betaService.UserHiddenCompanies.forEach((GVKey) => {
                    const index = this.betaService.leveredBetas.findIndex((x) => x.GVKEY === GVKey);
                    this.betaService.leveredBetas[index].IsHidden = true;
                    this.betaService.unleveredBetas[index].IsHidden = true;
                    this.betaService.betaStatistics[index].IsHidden = true;
                    this.betaService.unleveringInputs[index].IsHidden = true;
                    this.betaService.finalSelectedCompanies[index].IsHidden = true;
                });
                this.betaService.getAllSummaryStatistics();
                this.betaService.updateEstimate('statistics');
            }
            this.router.navigate(['beta']);
        });
        // this.spinner.while(request);
        this.spinner.begin();
    }

    public toggleExpandedEstimate(estimate: BetaEstimate): void {
        this.companies = [];
        if (estimate === this.expandedEstimate) {
            this.expandedEstimate = undefined;
        } else {
            this.expandedEstimate = estimate;
            if (estimate.Values !== null) {
                const companies = estimate.Values.map((beta) => beta.CompanyName).filter((value, index, self) => self.indexOf(value) === index);
                this.companies = companies.filter((x) => x !== null);
            }
        }
    }

    public closeDropdownFromOutside(id: string): void {
        if (this.allowOutsideToggleFor === id) {
            this.visibleDropdown = undefined;
            this.allowOutsideToggleFor = undefined;
        }
    }

    public openDropdown(id: string): void {
        this.allowOutsideToggleFor = undefined;
        this.visibleDropdown = id;
        setTimeout(() => this.allowOutsideToggleFor = id, 100);
    }

    public beginRename(estimate: BetaEstimate): void {
        this.isRename = {};
        this.isRename[estimate.EstimateId] = true;
        this.rename = estimate.Name;
        this.isInValidInput = false;

        const regex = /^[a-zA-Z0-9\s-]+$/;
        const isValidInput = regex.test(this.rename);
        if (this.rename && !isValidInput) {
            this.isInValidInput = true;
        }

    }

    public cancelRename(): void {
        this.isRename = {};
        this.rename = undefined;
        this.sameNameError = false;
        this.isInValidInput = false;
    }

    public submitRename(estimate: BetaEstimate): void {
        if (this.sameNameError || this.rename == null || this.isInValidInput) {
            return;
        }

        this.isRename = {};
        const request = this.store.dispatch(new RenameBetaEstimate(estimate.EstimateId, this.rename));
        this.spinner.while(request);
        request.once(() => {
            this.store.select(BetaEstimatesListState.sort(this.sortType, this.sortDescending)).onceDefined((sorted) => {
                this.moduleEstimates = [...this.estimateShared.getBetaEstimatesWithCurrencyAcronom(sorted)];
                this.pageIndex = 1;
            });
        });
    }

    public validateRename(estimates: BetaEstimate[], rename: string): void {
        if (rename == null) {
            return;
        }

        const regex = /^[a-zA-Z0-9\s-]+$/;
        const isValidInput = regex.test(rename);
        if (rename && !isValidInput) {
            this.isInValidInput = true;
            return;
        } else {
            this.isInValidInput = false
        }


        if (estimates.length > 0
            && estimates.map((e) => e.Name).find((x) => x.toLowerCase() === rename.toLowerCase())) {
            this.sameNameError = true;
        } else {
            this.sameNameError = false;
        }
    }

    public duplicate(estimate: BetaEstimate): void {
        const request = this.store.dispatch(new CloneBetaEstimate(estimate.EstimateId));
        this.spinner.while(request);
        request.once(() => {
            this.store.dispatch(new GetBetaEstimates());
            this.sort();
        });
    }

    public delete(estimate: BetaEstimate): void {
        const modal = this.modalManager.open<string, ArchiveEstimateModalComponent>(ArchiveEstimateModalComponent);
        modal.subscribe(() => {
            const request = this.store.dispatch(new ArchiveBetaEstimate(estimate.EstimateId));
            this.spinner.while(request);
            request.once(() => {
                this.store.dispatch(new GetBetaEstimates());
                this.sort();
            });
        });
    }
}