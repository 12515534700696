import { environment } from "src/environments/environment";
import { Auth0ApiClient } from "../api.client";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { AboutSpreadSheetData } from "../responses/about-spread-sheet-data.response";

@Injectable()
export class ExcelExportsClient extends Auth0ApiClient {
    protected baseUrl = `${environment.urls.navigatorApi}/api`;

    public GetAboutSpreadSheet(moduleName: string, valuationDate: string): Observable<AboutSpreadSheetData[]> {
        return this.get(`ExcelExports/AboutSources?moduleName=${encodeURIComponent(moduleName)}&valuationDate=${valuationDate}`);    
    }
}