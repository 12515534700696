
import { Help } from './help.model';
import { HelpConfig, intlTearsheetHelpTextKeys } from 'src/app/international-estimate/data/model/input-text';

export let IntlTearsheetHelptext: { [key: string]: Help } = intlTearsheetHelpTextKeys.reduce((acc, key) => {
    acc[key] = new HelpConfig('', '')
    return acc;
}, {} as { [key: string]: Help });

export const updateIntlTearSheetHelpText = (data: { [key: string]: Help }) => IntlTearsheetHelptext = data;
