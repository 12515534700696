import { Component, OnInit, ViewContainerRef } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AuthenticationManager, HttpError, ModalManager, NetworkManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import * as Highcharts from 'highcharts';
import { takeUntil } from 'rxjs/operators';
import { MyMonitoringService } from 'src/logging.service';
import { HelpService } from './_navigator/help/help.service';
import { PrimeManager } from './_navigator/modal/pmodal.manager';
import { UserStore } from './_navigator/user/store/user.store';
import { AuthService } from '@auth0/auth0-angular';


@Component({ selector: 'app-root', templateUrl: './app.component.html' })
export class AppComponent extends SubscriberEntity implements OnInit {

    profile: string = "";
    isAuthenticated: boolean = false;
    isSignup: boolean = false;


    constructor(
        public spinner: Spinner,
        public helpService: HelpService,
        private modalManager: ModalManager,
        private primeManager: PrimeManager,
        private userStore: UserStore,
        private authenticationManager: AuthenticationManager,
        private networkManager: NetworkManager,
        private router: Router,
        private myMonitoringService: MyMonitoringService,
        viewContainerRef: ViewContainerRef,
        public auth: AuthService
    ) {
        super();
        this.myMonitoringService.startMonitoring();
        this.primeManager.setHost(viewContainerRef);


        this.networkManager.error
            .pipe(takeUntil(this.destroyed))
            .whileDefined((error: HttpError) => {

                if (error.status === 404) {
                    this.networkManager.acknowledgeErrors();
                    this.spinner.end();
                    this.router.navigate(['landing']);
                    return;
                }
                this.spinner.end();
                this.modalManager
                    .error('There was an unexpected error processing your request.')
                    .subscribe(() => this.networkManager.acknowledgeErrors());
            });

    }

    private scrollToTopOnRoute(): void {
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
        });
    }

    public ngOnInit(): void {
        sessionStorage.setItem('isFreshLogin', '1');
        this.auth.isAuthenticated$.subscribe((sucess) => {
            this.isAuthenticated = sucess;
            if (!sucess)
                if (this.auth)
                    this.auth.loginWithRedirect({ authorizationParams: { showSignup: 'no', loginAfterSignUp: 'yes' } });
        });

        this.scrollToTopOnRoute();
        this.highchartsSetOptions();

        this.auth.user$.subscribe(
            (profile) => {
                this.auth.isLoading$.subscribe((isloading) => {
                    if (!isloading && !this.isAuthenticated && !profile) {
                        debugger
                        const url = window.location.href.split('error=access_denied');
                        if (url?.length > 1) {
                            this.auth.loginWithRedirect({
                                authorizationParams: {
                                    prompt: "login",
                                    error_description: "Please check your email and verify your email address",
                                }
                            });
                        }
                    }

                    else if (profile?.email_verified === false) {
                        this.auth.loginWithRedirect({
                            authorizationParams: {
                                prompt: "login",
                                error_description: "Please check your email and verify your email address",
                            }
                        });
                    }
                    else {
                        (this.profile = JSON.stringify(profile, null, 2))
                        if (this.isAuthenticated) {
                            this.userStore.findtheUser();
                            this.auth.idTokenClaims$.subscribe((claim) => {
                            })
                        }

                    }
                })

            }

        );

    }


    // public onProceedOk() {
    //     this.isSignup = false;
    //     this.auth.loginWithRedirect({
    //         authorizationParams: {
    //             prompt: "login",
    //         }
    //     });
    // }


    public highchartsSetOptions(): void {
        Highcharts.setOptions({

            chart: {
                style: {
                    fontFamily: 'nunito-sans'
                }
            },
            colors: ['#14487f', '#43b059', '#4c9fc8', '#a7a8a9', '#c46600', '#4d4d4f', '#d00070', '#e2665c', '#67d2df']
        });
    }
}
