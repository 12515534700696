export enum MenuType {
    DashBoard = 1,
    CostofCapitalInputs = 2,
    UsIndustryBenchmarking = 3,
    InternationalIndustryBenchmarking = 4,
    UsCostOfCapitalEstimates = 5,
    InternationalCostOfCapitalEstimate = 6,
    CompanyLevelBeta = 7,
    PreviousEstimates = 8,


}


export const cocProSubscriptions = [MenuType.CostofCapitalInputs, MenuType.UsIndustryBenchmarking, MenuType.UsCostOfCapitalEstimates, MenuType.CompanyLevelBeta, MenuType.PreviousEstimates];
export const ineProSubscriptions = [MenuType.UsIndustryBenchmarking, MenuType.CompanyLevelBeta, MenuType.InternationalCostOfCapitalEstimate, MenuType.PreviousEstimates];
export const cocestimmenuItemsatesTools = [MenuType.UsIndustryBenchmarking, MenuType.InternationalIndustryBenchmarking]