import { Component } from '@angular/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Equation, EquationParameter } from 'src/app/_navigator/data/model/equation.model';
import { LeveredScenarioStudyComponent } from '../levered-scenario-study.component';
import { OperandUtility } from '../operand.utility';
import { RiskTableComponent } from './risk-table.component';
import { Risk } from './risk.model';

@Component({ selector: 'risk-buildup-1', templateUrl: '../scenario-study.component.html' })
export class RiskBuildupOneComponent extends LeveredScenarioStudyComponent {
    protected hasRegressionToggle = true;
    protected leveredEquationType = EquationType.RprsBuildup1Levered;
    protected unleveredEquationType = EquationType.RprsBuildup1Unlevered;
    protected releveredEquationType = EquationType.RprsBuildup1Relevered;

    public dataTable = RiskTableComponent;
    public equationTypeName = 'Build-up 1';
    /*public unlever(): void {
        super.unlever();
        // this.helpService.open('regressionUnlevering', HelpText.RegressionUnlevering);
    }*/

    /* public relever(): void {
         super.relever();
         //  this.helpService.open('regressionRelevering', HelpText.RegressionRelevering);
     }*/

    private buildEquation(risk: Risk, equationType: EquationType): Equation {
        const operandUtility = new OperandUtility();
        const costOfEquityCapital = OperandUtility.getCostOfEquityCapital(equationType, risk.implicationType);
        const riskOperand = OperandUtility.getRiskPremium(equationType, risk);

        const ke: EquationParameter = { item: costOfEquityCapital, operator: '=', canEdit: false };
        const rf: EquationParameter = { item: operandUtility.riskFreeRate, operator: '+', canEdit: true };
        const rms: EquationParameter = { item: riskOperand, operator: '+', canEdit: true };
        const erpa: EquationParameter = { item: operandUtility.erpAdjusted, operator: '', canEdit: true };

        return {
            name: risk.name,
            equationType,
            implicationType: risk.implicationType,
            parameters: [ke, rf, rms, erpa],
            calculate: (scenario) => {
                const equation = scenario.getEquation(equationType, risk.implicationType);
                const riskFreeRate = scenario.getValueOrNaN(SelectionType.RiskFreeRate, InputType.None);
                const erpAdjustment = scenario.getValueOrNaN(SelectionType.EquityRiskPremiumAdjustment, InputType.None);
                const riskPremium = scenario.getValueOrNaN(risk.selectionType, risk.inputType);

                const result = riskFreeRate + riskPremium + erpAdjustment;
                equation.Result = result || undefined;

                return equation.Result != null;
            }
        };
    }

    public buildEquations(equationType: EquationType): Equation[] {
        const risks = OperandUtility.getRisks(equationType);
        return risks.map((risk) => this.buildEquation(risk, equationType));
    }
}
