import { Dictionary } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType, ImplicationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { EquationOperand, EquationResult, LabelType } from 'src/app/_navigator/data/model/equation.model';
import { HelpText } from 'src/app/_navigator/help/help-text';
import { BetaEditorComponent } from './_editors/beta-editor.component';
import { Buildup3PortfolioSelectorComponent } from './_editors/buildup3-portfolio-selector.component';
import { EquityRiskAdjustmentEditorComponent } from './_editors/equity-risk-adjustment-editor.component';
import { EquityRiskPremiumEditorComponent } from './_editors/equity-risk-premium-editor.component';
import { IndustryRiskEditorComponent } from './_editors/industry-risk-editor.component';
import { PortfolioSelectorComponent } from './_editors/portfolio-selector.component';
import { RiskFreeRateEditorComponent } from './_editors/risk-free-rate-editor.component';
import { SizePremiumEditorComponent } from './_editors/size-premium-editor.component';
import { ZscoreEditorComponent } from './_editors/zscore-editor.component';
import { Risk } from './risk/risk.model';

export class OperandUtility {
    public riskFreeRate: EquationOperand = {
        name: 'Risk-Free Rate',
        placeholderHTML: 'R<sub>f</sub>',
        selectionType: SelectionType.RiskFreeRate,
        editor: RiskFreeRateEditorComponent,
        help: HelpText.RiskFreeRate
    };

    public equityRiskPremium: EquationOperand = {
        name: 'Equity Risk Premium',
        placeholderHTML: 'RP<sub>m</sub>',
        selectionType: SelectionType.EquityRiskPremium,
        dependentType: SelectionType.RiskFreeRate,
        editor: EquityRiskPremiumEditorComponent,
        notReadyWarning: 'Select a Risk-free Rate to enable',
        help: HelpText.EquityRiskPremium
    };

    public sizePremium: EquationOperand = {
        name: 'Size Premium',
        placeholderHTML: 'RP<sub>s</sub>',
        selectionType: SelectionType.CrspSizePremium,
        editor: SizePremiumEditorComponent,
        help: HelpText.SizePremium
    };

    public buildupSizePremium: EquationOperand = {
        name: 'Size Premium',
        placeholderHTML: 'RP<sub>s</sub>',
        selectionType: SelectionType.CrspBuildupSizePremium,
        editor: SizePremiumEditorComponent,
        help: HelpText.SizePremium
    };

    public erpAdjusted: EquationOperand = {
        name: 'Equity Risk Premium Adjustment',
        placeholderHTML: 'ERP<sub>Adj</sub>',
        selectionType: SelectionType.EquityRiskPremiumAdjustment,
        dependentType: SelectionType.RiskFreeRate,
        suggestionType: SelectionType.EquityRiskPremium,
        editor: EquityRiskAdjustmentEditorComponent,
        notReadyWarning: 'Select a Risk-Free Rate to enable.',
        help: HelpText.ErpAdjustment
    };

    public hfrRiskPremiumOverCapm: EquationOperand = {
        name: 'HFR Risk Premium over Capm',
        placeholderHTML: 'RP<sub>s</sub>',
        selectionType: SelectionType.HighFinancialRiskPremiumOverCapM,
        editor: ZscoreEditorComponent,
        labelType: LabelType.ZScore,
        equationType: EquationType.HfrsCapmHfr
    };

    public hfrRiskPremiumOverRfr: EquationOperand = {
        name: 'HFR Risk Premium over The Risk-Free Rate',
        placeholderHTML: 'RP<sub>m+s</sub>',
        selectionType: SelectionType.HighFinancialRiskPremiumOverRiskFreeRate,
        editor: ZscoreEditorComponent,
        labelType: LabelType.ZScore,
        equationType: EquationType.HfrsBuildupHfr
    };

    public static getBeta(selectionType: SelectionType): EquationOperand {
        if (selectionType !== SelectionType.CrspDecilesBeta &&
            selectionType !== SelectionType.RiskPremiumBeta &&
            selectionType !== SelectionType.HighFinancialRiskBeta
        ) {
            throw Error(`Expected IRP Editor to target Crsp, Rpr, or Hfr Beta.`);
        }

        return {
            name: 'Beta',
            placeholderHTML: '&beta;',
            selectionType,
            editor: BetaEditorComponent,
            mask: '{}',
            help: HelpText.Beta
        };
    }

    public static getIndustryRiskPremium(selectionType: SelectionType): EquationOperand {
        if (selectionType !== SelectionType.CrspIndustryRiskPremium &&
            selectionType !== SelectionType.RprIndustryRiskPremium
        ) {
            throw Error(`Expected IRP Editor to target an Industry Risk Premium.`);
        }

        const betaType = selectionType === SelectionType.CrspIndustryRiskPremium
            ? SelectionType.CrspDecilesBeta
            : SelectionType.RiskPremiumBeta;

        return {
            name: 'Industry Risk Premium',
            placeholderHTML: 'RP<sub>i</sub>',
            selectionType,
            dependentType: SelectionType.EquityRiskPremium,
            suggestionType: betaType,
            editor: IndustryRiskEditorComponent,
            notReadyWarning: 'Select an Equity Risk Premium to enable',
            help: HelpText.IndustryRiskPremium
        };
    }

    public static getCostOfEquityCapital(
        equationType: EquationType, implicationType: ImplicationType = ImplicationType.None
    ): EquationResult {
        const name = 'Cost of Equity Capital';
        const result: EquationResult = {
            name,
            placeholderHTML: 'K<sub>e</sub>',
            help: HelpText.CostOfEquity,
            equationType,
            implicationType
        };

        // TODO: What to do with this?

        // if (implicationType != null &&
        //     portfolioEquations.some((x) => equationType === x)
        // ) {
        //     const inputType = InputType[ImplicationType[implicationType]];
        //     result.info = new Sensitivity(equationType, inputType, this.dataStore);
        // }

        return result;
    }

    public static getCostOfEquityEquationResult(equationType: EquationType, currencyAcronym: string): EquationResult {
        return {
            name: 'Cost of Equity Capital',
            placeholderHTML: `K<sub>e</sub> (in ${currencyAcronym})`,
            help: HelpText.CostOfEquity,
            equationType,
            implicationType: ImplicationType.None
        };
    }

    public static getRiskPremium(equationType: EquationType, risk: Risk): EquationOperand {
        let name = '';
        let placeholderHTML = '';
        let labelType;
        let help = null;

        if (equationType === EquationType.RprsBuildup1Levered ||
            equationType === EquationType.RprsBuildup1Unlevered ||
            equationType === EquationType.RprsBuildup1Relevered
        ) {
            name = 'Risk Premium Over The Risk-Free Rate';
            placeholderHTML = 'RP<sub>m+s</sub>';
            help = HelpText.RiskPremiumBuildup1;
        } else {
            name = 'Size Premium';
            placeholderHTML = 'RP<sub>s</sub>';
            help = HelpText.SizePremium;
        }

        if (equationType === EquationType.RprsBuildup1Relevered) {
            labelType = LabelType.ReleveredDebt;
        } else if (equationType === EquationType.RprsBuildup1Unlevered) {
            labelType = LabelType.UnleveredDebt;
        } else if (equationType === EquationType.RprsBuildup1Levered) {
            labelType = LabelType.LeveredDebt;
        }

        return {
            name,
            placeholderHTML,
            selectionType: risk.selectionType,
            inputType: risk.inputType,
            editor: PortfolioSelectorComponent,
            labelType,
            equationType,
            editorName: risk.implicationName,
            help
        };
    }

    // TODO: Why is this separate from GetRiskPremium?
    public static getBuildup3RiskPremium(risk: Risk): EquationOperand {
        const equationType = EquationType.RprsBuildup3;

        return {
            name: 'Risk Premium Over The Risk-Free Rate',
            placeholderHTML: 'RP<sub>m+c</sub>',
            editor: Buildup3PortfolioSelectorComponent,
            selectionType: risk.selectionType,
            inputType: risk.inputType,
            equationType,
            editorName: risk.implicationName,
            help: HelpText.RiskPremiumBuildup3
        };
    }

    public static getBuildup3Risks(): Risk[] {
        return [
            {
                name: 'Operating Margin',
                implicationName: 'Operating Margin',
                inputType: InputType.OperatingMargin,
                implicationType: ImplicationType.OperatingMargin,
                selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate
            }, {
                name: 'Coefficient of Variation of Operating Margin',
                implicationName: 'Coefficient of Variation of Operating Margin',
                inputType: InputType.CoefficientOfVariationOfOperatingMargin,
                implicationType: ImplicationType.CoefficientOfVariationOfOperatingMargin,
                selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate
            }, {
                name: 'Coefficient of Variation of Return on Equity',
                implicationName: 'Coefficient of Variation of Return on Equity',
                inputType: InputType.CoefficientOfVariationOfReturnOnEquity,
                implicationType: ImplicationType.CoefficientOfVariationOfReturnOnEquity,
                selectionType: SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate
            }
        ];
    }

    public static getRisks(equationType: EquationType): Risk[] {
        const selectionTypes: Dictionary<SelectionType> = {};
        selectionTypes[EquationType.RprsCapmSizeStudy] = SelectionType.RiskPremiumOverCapm;
        selectionTypes[EquationType.RprsBuildup1Levered] = SelectionType.RiskPremiumOverRiskFreeRateLevered;
        selectionTypes[EquationType.RprsBuildup1Unlevered] = SelectionType.RiskPremiumOverRiskFreeRateUnlevered;
        selectionTypes[EquationType.RprsBuildup1Relevered] = SelectionType.RiskPremiumOverRiskFreeRateRelevered;
        selectionTypes[EquationType.RprsBuildup2] = SelectionType.Buildup2RiskPremiumOverTheRiskFreeRate;
        selectionTypes[EquationType.RprsBuildup3] = SelectionType.Buildup3RiskPremiumOverTheRiskFreeRate;

        return [
            {
                name: 'Market Value of Common Equity',
                implicationName: 'Market Value of Common Equity ($USD in millions)',
                inputType: InputType.MarketValueOfCommonEquity,
                implicationType: ImplicationType.MarketValueOfCommonEquity,
                selectionType: selectionTypes[equationType]
            }, {
                name: 'Book Value of Equity',
                implicationName: 'Book Value of Equity ($USD in millions)',
                inputType: InputType.BookValueOfEquity,
                implicationType: ImplicationType.BookValueOfEquity,
                selectionType: selectionTypes[equationType]
            }, {
                name: '5-Year Average Net Income',
                implicationName: '5-Year Average Net Income ($USD in millions)',
                inputType: InputType.FiveYearAverageNetIncome,
                implicationType: ImplicationType.FiveYearAverageNetIncome,
                selectionType: selectionTypes[equationType]
            }, {
                name: 'Market Value of Invested Capital',
                implicationName: 'Market Value of Invested Capital ($USD in millions)',
                inputType: InputType.MarketValueOfInvestedCapital,
                implicationType: ImplicationType.MarketValueOfInvestedCapital,
                selectionType: selectionTypes[equationType]
            }, {
                name: 'Total Assets',
                implicationName: 'Total Assets ($USD in millions)',
                inputType: InputType.TotalAssets,
                implicationType: ImplicationType.TotalAssets,
                selectionType: selectionTypes[equationType]
            }, {
                name: '5-Year Average EBITDA',
                implicationName: '5-Year Average EBITDA ($USD in millions)',
                inputType: InputType.FiveYearAverageEbitda,
                implicationType: ImplicationType.FiveYearAverageEbitda,
                selectionType: selectionTypes[equationType]
            }, {
                name: 'Net Sales',
                implicationName: 'Net Sales ($USD in millions)',
                inputType: InputType.NetSales,
                implicationType: ImplicationType.NetSales,
                selectionType: selectionTypes[equationType]
            }, {
                name: 'Number of Employees',
                implicationName: 'Number of Employees',
                inputType: InputType.NumberOfEmployees,
                implicationType: ImplicationType.NumberOfEmployees,
                selectionType: selectionTypes[equationType]
            }
        ];
    }
}
