import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import TableAction from '../interface/tableActionInterface';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { ColumnItem } from '../interface/tableColumnInterface';
import { TypeaheadFormatters } from 'src/app/_navigator/data/util/typeahead-formatters.util';
import { Store } from '@ngxs/store';
import { ModalManager, Spinner } from '@concurrency/angular';
import { ArchiveEstimate, CloneEstimate, GetEstimates, RenameEstimate } from 'src/app/_navigator/estimates-list-store/estimates-list-actions';
import { EstimatesListState, SortType } from 'src/app/_navigator/estimates-list-store/estimates-list-state';
import { EstimateSharedService } from 'src/app/home/estimates/estimate-shared-service';
import { EstimateTitle, EstimateType } from 'src/app/_api/enums/estimate-type';
import { ArchiveEstimateModalComponent } from 'src/app/home/_modals/archive-estimate-model.component';

@Component({
    selector: 'common-estimate-table',
    templateUrl: './common-estimate-table.component.html',
    styleUrls: ['./common-estimate-table.component.scss']
})
export class CommonEstimateTableComponent<T> {

    @Input() columns: ColumnItem<T>[] = [];
    @Input() moduleEstimates: Estimate[] = [];
    @Input() actions: TableAction<T>[] = this.getDefaultActions() as any;
    public isRename: any = {};
    public rename!: string | undefined;
    public sameNameError!: boolean;
    private sortType = SortType.Date;
    private sortDescending = true;
    public TypeaheadFormatters = TypeaheadFormatters;
    public EstimateType = EstimateType;
    public EstimateTitles = EstimateTitle;
    public pageIndex = 1;
    public isInValidInput = false;





    constructor(
        private store: Store,
        private spinner: Spinner,
        private estimateSharedService: EstimateSharedService,
        private modalManager: ModalManager
    ) {

    }




    getDefaultActions(): TableAction<Estimate>[] {
        return [
            {
                icon: 'edit',
                handler: (item: Estimate) => this.estimateSharedService.routeTo(item),
                tooltip: 'Edit'
            },
            {
                icon: 'copy',
                handler: (item: Estimate) => this.duplicate(item as Estimate),
                tooltip: 'Copy'
            },
            {
                icon: 'delete',
                handler: (item: Estimate) => this.delete(item as Estimate),
                tooltip: 'Delete'
            }
        ];
    }




    public beginRename(estimate: Estimate): void {
        this.isRename = {};
        this.isRename[estimate.Id] = true;
        this.rename = estimate.Name;
        this.isInValidInput = false;

        const regex = /^[a-zA-Z0-9\s-]+$/;
        const isValidInput = regex.test(this.rename);
        if (this.rename && !isValidInput) {
            this.isInValidInput = true;
        }



    }

    public cancelRename(): void {
        this.isRename = {};
        this.rename = undefined;
        this.sameNameError = false;
        this.isInValidInput = false;
    }

    public submitRename(estimate: Estimate): void {
        if (this.sameNameError || this.rename == null || this.isInValidInput) {
            return;
        }

        this.isRename = {};
        const request = this.store.dispatch(new RenameEstimate(estimate.Id, this.rename));
        this.spinner.while(request);
        request.once(() => {
            this.store.select(EstimatesListState.getEstimate(this.sortType, this.sortDescending, estimate.EstimateType)).onceDefined((sorted) => {
                this.moduleEstimates = this.estimateSharedService.mapEstimateCountryData(sorted, estimate.EstimateType);
                this.pageIndex = 1;
            });
        });
    }


    public duplicate(estimate: Estimate): void {
        const request = this.store.dispatch(new CloneEstimate(estimate.Id));
        this.spinner.while(request);
        request.once(() => {
            this.store.dispatch(new GetEstimates());
            this.getLatest(estimate);
        });
    }

    public delete(estimate: Estimate): void {
        const modal = this.modalManager.open<string, ArchiveEstimateModalComponent>(ArchiveEstimateModalComponent);
        modal.subscribe(() => {
            const request = this.store.dispatch(new ArchiveEstimate(estimate.Id));
            this.spinner.while(request);
            request.once(() => {
                this.store.dispatch(new GetEstimates());
                this.getLatest(estimate);
            });
        });
    }

    public getLatest(estimate: Estimate) {
        this.store.select((EstimatesListState.getLatestEstimate(estimate.EstimateType))).onceDefined((data) => {
            this.moduleEstimates = this.estimateSharedService.mapEstimateCountryData(data, estimate.EstimateType);
            this.pageIndex = 1;
        });
    }


    public validateRename(estimates: Estimate[], rename: string): void {
        if (rename == null) {
            return;
        }

        const regex = /^[a-zA-Z0-9\s-]+$/;
        const isValidInput = regex.test(rename);
        if (rename && !isValidInput) {
            this.isInValidInput = true;
            return;
        } else {
            this.isInValidInput = false
        }

        if (estimates.length > 0
            && estimates.map((e) => e.Name).find((x) => x.toLowerCase() === rename.toLowerCase())) {
            this.sameNameError = true;
        } else {
            this.sameNameError = false;
        }
    }


}
