import { Component, Input, OnInit } from '@angular/core';
import { Spinner } from '@concurrency/angular';
import { ContextualValue } from '@concurrency/core';
import { Select } from '@ngxs/store';
import { Chart } from 'angular-highcharts';
import { Observable } from 'rxjs';
import { CountryCreditRating } from '../../../_api/responses/country-credit-rating.response';
import { Country } from '../../../_api/responses/country.response';
import { SubscriptionType, UserResponse } from '../../../_api/responses/user.response';
import { TrendsOverTimeCountryRiskPremiaComponent } from '../../../home/tear-sheet/modals/trends-over-time-country-risk-premia.component';
import { MetricTable } from '../../../home/tear-sheet/models/metric-table.model';
import { CountryListState } from '../../country-list-store/country-list-state';
import { TableData } from '../../data/model/table-data.model';
import { DataStore } from '../../data/store/data.store';
import { PrimeManager } from '../../modal/pmodal.manager';
import { UserStore } from '../../user/store/user.store';
import { DateStruct } from '../date-struct';

@Component({
    selector: 'common-table',
    templateUrl: './common-table.component.html'
})
export class CommonTableComponent implements OnInit {
    @Select(CountryListState.getInvestorCountries) public investorCountriesSelector!: Observable<Country[]>;
    @Select(CountryListState.getInvesteeCountries) public investeeCountriesSelector!: Observable<Country[]>;
    @Input() public tableData!: TableData;
    @Input() public alphas!: any;
    @Input() public metric!: MetricTable;
    @Input() public trendsOverTimeColumn!: boolean;
    @Input() public chart!: Chart;
    @Input() public chartOptions!: Highcharts.Options;
    @Input() public userdata!: UserResponse;
    public trends = false;
    public countrydata!: Country[];
    public investorcountryid!: number;
    public investeecountryid!: number;
    public valuationDate!: DateStruct;
    public investorCountry: ContextualValue<Country> = new ContextualValue<Country>();
    public investeeCountry: ContextualValue<Country> = new ContextualValue<Country>();
    public investorarray: any;
    public investeearray: any;
    public dataasof!: string;
    public credirating!: CountryCreditRating[];
    public trendsovertimelinkcolor = 0;
    constructor(private primeManager: PrimeManager, private dataStore: DataStore, private spinner: Spinner, private userStore: UserStore) { }

    public ngOnInit(): void {
        this.userStore.user.onceDefined((user) => {
            this.userdata = user;
        });
        if (document.getElementsByClassName('tab-header tab-active')[0].innerHTML === '<div>STEP 6</div><div>Cost of Equity Summary</div>') {
            this.trendsovertimelinkcolor = 1;
        }
    }

    public openTrendsOverTimeModal(): void {
        if (document.getElementsByClassName('tab-header tab-active')[0].innerHTML !== '<div>STEP 6</div><div>Cost of Equity Summary</div>') {
            if (this.userdata.Subscriptions.find((x) => x.Type === SubscriptionType.InePro || x.Type === SubscriptionType.IneEnterprise)) {
                const investee = this.alphas[0];
                const investor = this.alphas[1];
                const dataasof = this.alphas[2];
                const ccrrequest = this.dataStore.getccr(investor, investee, dataasof);
                ccrrequest.once(() => {
                    const Credittrends = this.dataStore.countrycredit;
                    Credittrends.onceDefined((data) => { this.credirating = data; });
                });
                const request = this.dataStore.GetCountryRiskPremia(investor, investee, dataasof);
                this.spinner.while(request);
                request.once(() => {
                    const Credittrends = this.dataStore.credittrends;
                    Credittrends.onceDefined((data) => {
                        this.primeManager.openDialog(TrendsOverTimeCountryRiskPremiaComponent, {
                            ccr: {
                                metricName: 'Country Credit Rating'
                            },
                            trendsOverTimeData: data,
                            trendsOverTimeIndustries: this.tableData.content,
                            investorcountryid: this.investorcountryid,
                            investeecountryid: this.investeecountryid,
                            countrycredit: this.credirating,
                            Dataasof: dataasof,
                            userdata: this.userdata
                        });
                    });
                }, () => { this.spinner.end();}, () => { this.spinner.end(); });
            }
        }
    }
}
