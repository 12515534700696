import { Injectable, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager, Spinner } from '@concurrency/angular';
import { Store } from '@ngxs/store';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { Gic } from 'src/app/_api/responses/gic.response';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { GetEstimate } from 'src/app/_navigator/estimate-store/estimate-actions';
import { ArchiveEstimate, CloneEstimate, GetEstimates } from 'src/app/_navigator/estimates-list-store/estimates-list-actions';
import { BenchmarkingUtil } from 'src/app/benchmarking/benchmarking.util';
import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';
import { ArchiveEstimateModalComponent } from '../_modals/archive-estimate-model.component';
import TableAction from 'src/app/_navigator/common/commonTable/interface/tableActionInterface';
import { Country } from 'src/app/_api/responses/country.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Currency } from 'src/app/_api/responses/currency.response';
import { BetaEstimate } from 'src/app/_navigator/data/model/beta-estimate.model';

@Injectable({
    providedIn: 'root'
})


export class EstimateSharedService {

    public selectedIndustries: Industry[] = [];
    public moduleTitle!: string;
    public countries!: Country[];
    public module!: number;
    public currencies!: Currency[]



    constructor(
        private dataStore: DataStore,
        private store: Store,
        private router: Router,
        private modalManager: ModalManager,
        private spinner: Spinner,
        public industryDataSharingService: IndustryDataSharingService) {

    }

    public setCurrency(currency: Currency[]) {
        this.currencies = currency;
    }

    public getBetaEstimatesWithCurrencyAcronom(estimates: BetaEstimate[]): BetaEstimate[] {
        if (estimates.length > 0 && this.currencies.length > 0) {
            estimates.forEach((objBeta: BetaEstimate) => {
                let objCurency = this.currencies.find((d: Currency) => d.CurrencyAcronym.toUpperCase() === objBeta.CurrencyOfWacc);
                if (objCurency) {
                    objBeta.currencyWithAcronom = objCurency.CurrencyAcronym + " - " + objCurency.CurrencyName;
                }
            });
        }

        return estimates;
    }

    public setTitle(title: string) {
        this.moduleTitle = title;
    }

    public setCountries(countries: Country[]) {
        this.countries = countries;
    }

    public setModuleType(module: number) {
        this.module = module;
    }

    public routeTo(estimate: Estimate): void {
        this.dataStore.setIsIndustryAnalysis(false);


        if (estimate.EstimateType === EstimateType.USEstimate) {
            if (estimate.Industries != null) {
                const isSicGic = BenchmarkingUtil.isSic(estimate.ValuationDate.toString(), 'United States');
                estimate.Industries.forEach((item) => {
                    this.selectedIndustries.push({
                        CodeId: item.SicId,
                        GicIndustryCode: isSicGic === false ? item.SicCode : '',
                        Sector: item.IndustryName,
                        SicIndustryCode: isSicGic === true ? item.SicCode : ''
                    });
                });

                this.industryDataSharingService.setSharedIndustries = this.selectedIndustries;
            } else {
                const emptyIndustry: Industry[] = new Array<Industry>();
                emptyIndustry.push({
                    CodeId: 0,
                    GicIndustryCode: '',
                    SicIndustryCode: '',
                    Sector: ''
                } as Industry);
                this.industryDataSharingService.setSharedIndustries = emptyIndustry;
            }
            const request = this.dataStore.setupEstimate(estimate.Id);
            this.spinner.while(request);
            request.once(() => {
                this.router.navigate(['estimate']);
            });
        } else {
            const emptyGicIndustry: Gic[] = new Array<Gic>();
            emptyGicIndustry.push({
                GicId: 0,
                GicCode: '',
                GicDescription: ''
            } as Gic);
            this.industryDataSharingService.setSharedGicIndustries = emptyGicIndustry;

            const request = this.store.dispatch(new GetEstimate(estimate.Id));
            request.once(() => {
                this.router.navigate(['international-estimate']);
            });
            this.spinner.while(request);
        }
    }

    public mapEstimateCountryData(estimates: Estimate[], estimateType: EstimateType): Estimate[] {

        if (estimateType === EstimateType.USEstimate) {
            estimates.forEach((obj: Estimate) => {
                obj.InvestorCountry = this.countries.find((d: Country) => d.CountryId === obj.HomeCountryId)?.CountryName ?? "";
                obj.InvesteeCountry = this.countries.find((d: Country) => d.CountryId === obj.InvesteeCountryId)?.CountryName ?? "";
            });
        }
        else if (estimateType === EstimateType.InternationalEstimate) {
            estimates.forEach((obj: Estimate) => {
                obj.InvestorCountry = this.countries.find((d: Country) => d.CountryId === obj.HomeCountryId)?.CountryName ?? "";
                obj.InvesteeCountry = this.countries.find((d: Country) => d.CountryId === obj.InvesteeCountryId)?.CountryName ?? "";

                if (obj.Inputs && obj.Inputs.find(d => d.InputType === InputType.CashFlows)) {
                    obj.CurrencycashFlow = this.countries.find((d: Country) => d.CountryId === obj.Inputs.find(d => d.InputType === InputType.CashFlows)?.Value)?.CountryName ?? "";

                }

                if (obj.Inputs && obj.Inputs.find(d => d.InputType === InputType.CountryOfInputs)) {
                    obj.COEInputCountry = this.countries.find((d: Country) => d.CountryId === obj.Inputs.find(d => d.InputType === InputType.CountryOfInputs)?.Value)?.CountryName ?? "";

                }
            });
        }
        return estimates;

    }

}