
import { Help } from './help.model';
import { HelpConfig, tearsheetHelpTextKeys } from 'src/app/international-estimate/data/model/input-text';

//Set the Initial TearsheetHelptext Data with empty Text and Title to handle Exception
export let TearsheetHelptext = tearsheetHelpTextKeys.reduce((acc, key) => {
    acc[key] = new HelpConfig('', '')
    return acc;
}, {} as { [key: string]: Help });


//using updateTearsheetHelpText will update the TearsheetHelptext with actual Data once Data Received from Api 
export const updateTearsheetHelpText = (data: { [key: string]: Help }) => TearsheetHelptext = data;