import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ExcelExportComponent } from '@progress/kendo-angular-excel-export';
import { Tab } from 'src/app/_navigator/data/enum/tab.enum';
import { Step } from 'src/app/_navigator/data/model/step.model';
import { BenchmarkingExcelExportService } from 'src/app/estimate/exports/benchmarkingexcel-export.service';
import { ExcelExportService } from 'src/app/estimate/exports/summary-export/excel-export.service';
import { ExportService } from '../data/service/export.service';
import { IndustryPdfExportService } from '../data/service/industry-pdf-export.service';

@Component({
    selector: 'step-nav',
    templateUrl: './step-nav.component.html'
})
export class StepNavComponent {
    @Input() public steps: Step[] = [];
    @Input() public activeTab!: Tab;
    @Input() public canExport = false;
    @Input() public moduleName = '';
    public exportFileName = '';
    public Currenturl = '';

    constructor(
        public router: Router,
        public estimatePdfExportService: ExportService,
        public industryPdfExportService: IndustryPdfExportService,
        public benchmarkingExcelExportService: BenchmarkingExcelExportService,
        public estimateExcelExportService: ExcelExportService
    ) { }

    public showExcelExport(): boolean {
        return this.canExport && (
            this.activeTab === Tab.Results ||
            // this.activeTab === Tab.USIndustry ||
            this.activeTab === Tab.BenchmarkingAnalysis ||
            this.activeTab === Tab.CountryRiskPremiaSummary ||
            this.activeTab === Tab.CostOfEquitySummary ||
            this.activeTab === Tab.CostOfDebtSummary ||
            this.activeTab === Tab.WaccSummary);
    }

    public goToRoute(step: Step): void {
        this.router.navigate([step.Url]);
    }

    public exportToPdf(): void {
        if (this.activeTab === Tab.Results) {
            this.estimatePdfExportService.savePdf();
        }
    }

    public exportToExcel(component: ExcelExportComponent): void {
        if (this.activeTab === Tab.USIndustry) {
            this.exportFileName = 'usisummary.xlsx';
            this.benchmarkingExcelExportService.benchMarkAnalysisName = 'usiBenchmarking'
            this.benchmarkingExcelExportService.saveUSIndustry(component);
            // } else if (this.activeTab === Tab.BenchmarkingAnalysis) {
            //     if (this.moduleName == "usiBenchmarking") {
            //         this.exportFileName = 'usi-benchmarking-summary.xlsx';
            //         this.benchmarkingExcelExportService.benchMarkAnalysisName = 'usiBenchmarking'
            //     } else {
            //         this.exportFileName = 'ini-benchmarking-summary.xlsx';
            //         this.benchmarkingExcelExportService.benchMarkAnalysisName = 'intlBenchmarking'
            //     }
            //     this.benchmarkingExcelExportService.save(component);
        } else if (this.activeTab === Tab.Results) {
            this.exportFileName = 'us-cofc-summary.xlsx';
            this.estimateExcelExportService.save(component);
        }
    }

}
