import * as moment from 'moment';

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ModalManager, Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select, Store } from '@ngxs/store';
import { combineLatest, Observable } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { FilesClient } from 'src/app/_api/clients/files.client';
import { UserClient } from 'src/app/_api/clients/user.client';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { DashboardDataReponse } from 'src/app/_api/responses/dashboard-data.response';
import { MinimumDate } from 'src/app/_api/responses/minimum-date.response';
import { Analysis } from 'src/app/_navigator/data/model/analysis.model';
import { DashboardDisplayService } from 'src/app/_navigator/data/service/dashboard-display.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { CreateBetaEstimate } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-actions';
import { CreateEstimate } from 'src/app/_navigator/estimates-list-store/estimates-list-actions';
import { ProductType } from 'src/app/_navigator/user/enum/product-type';
import { SubscriptionLevel, Subscriptionlabels } from 'src/app/_navigator/user/enum/subscription-level';
import { FastSpringUrl } from 'src/app/_navigator/user/model/fast-spring-url.model';
import { SubscriptionData } from 'src/app/_navigator/user/model/subscription.model';
import { UserUtil } from 'src/app/_navigator/user/util/user.util';
import { ValuesState } from 'src/app/_navigator/values-store/values-state';
import { environment } from 'src/environments/environment';
import { TitleService } from '../../_navigator/data/service/title.service';
import { User } from '../../_navigator/user/model/user.model';
import { UserStore } from '../../_navigator/user/store/user.store';
import { NewBetaEstimateModalComponent } from '../_modals/new-beta-estimate-modal.component';
import { NewEstimateModalComponent } from '../_modals/new-estimate-modal.component';
import { ResourcesLibraryComponent } from '../resources-library/resources-library.component';
import { ModuleData } from './dashboard-module.data';
import { DashboardModule } from './dashboard-module.model';
import { EstimatesListType } from './estimates-list-type';
import { EstimatesList } from './estimates-list.model';
import { ModuleType } from './module-type';
import { inCocSubscriptionDetails, usCocSubscriptionDetails } from './dashboard.subscription.data';
import { SubscriptionType } from 'src/app/_api/responses/user.response';
import { ResourceFileType } from 'src/app/_api/enums/resource-file-type';
import { updateHelpText } from 'src/app/_navigator/help/help-text';
import { HelpTextTypes } from 'src/app/international-estimate/data/model/input-text';
import { updateIntlTearSheetHelpText } from 'src/app/_navigator/help/intltear-sheet-helptext';
import { updateTearsheetHelpText } from 'src/app/_navigator/help/tearsheet-help-text';
declare let pendo: any;

@Component({ templateUrl: 'dashboard.component.html' })
export class DashboardComponent extends SubscriberEntity implements OnInit {
    public showBuyNow = false;
    public introHtml = '';
    public moduleData: ModuleData;
    public user!: User;
    public isBannerMessageRead = false;
    public bannerMessage: any;
    public insights: any[] = [];
    public objUsCocUserDetails = usCocSubscriptionDetails
    public objinCocUserDetails = inCocSubscriptionDetails
    public inputImagePath = environment.urls.DashboardInputImage;

    @Select(ValuesState.get) public valuesSelector!: Observable<MinimumDate | undefined>;

    public usEstimatesList: EstimatesList = {
        sectionHeader: 'Recent U.S. Cost of Capital Estimates',
        listType: EstimatesListType.USList
    };

    public internationalEstimatesList: EstimatesList = {
        sectionHeader: 'Recent International Cost of Capital Estimates',
        listType: EstimatesListType.InternationalList
    };

    public cocFastSpringUrl: FastSpringUrl = {
        half: 'mod150',
        quarter: 'mod175',
        base: 'mod1'
    };

    public usiFastSpringUrl: FastSpringUrl = {
        half: 'mod250',
        quarter: 'mod275',
        base: 'mod2'
    };

    public ineFastSpringUrl: FastSpringUrl = {
        half: 'mod350',
        quarter: 'mod375',
        base: 'mod3'
    };

    public iniFastSpringUrl: FastSpringUrl = {
        half: 'mod450',
        quarter: 'mod475',
        base: 'mod4'
    };
    public BtaFastSpringUrl: FastSpringUrl = {
        half: 'mod550',
        quarter: 'mod575',
        base: 'mod5'
    };

    public usCostOfCapitalSubscribe = '';
    public usIndustryBenchmarkingSubscribe = '';
    public internationalCostOfCapitalSubscribe = '';
    public internationalIndustryBenchmarkingSubscribe = '';
    public BetaModuleSubscribe = '';
    public cocModule = false;
    public benchmarkModule = false;
    public cocUSModule = false;
    public cocIntlModule = false;
    public benchmarkUSModule = false;
    public benchmarkIntlModule = false;
    public betaModule = false;
    public imageUrls = new Array<string>();
    public cocNews = new Array<string>();
    public benchNews = new Array<string>();
    public dashboardNews = new Array<string>();
    public navigatorDashboard: any;
    public productNews = '';
    public dashboardIntro = '';
    public allModuleSubscribed = false;
    public expirationDate!: Date;
    public subscriptionExpiryDate!: Date;
    public isActive = false;
    public cocUSExpireDays = '';
    public cocIntlExpireDays = '';
    public benchmarkUSExpireDays = '';
    public benchmarkIntExpireDays = '';
    public BetaExpireDays = '';
    public isInternal = false;
    public isProdEnv = false;
    constructor(
        private modalManager: ModalManager,
        private userClient: UserClient,
        private store: Store,
        private router: Router,
        private spinner: Spinner,
        private dataStore: DataStore,
        private sanitizer: DomSanitizer,
        private http: HttpClient,
        private userStore: UserStore,
        private titleService: TitleService,
        private filesClient: FilesClient,
        // private pendo: NgxPendoService,
        private displayService: DashboardDisplayService) {
        super();
        this.moduleData = new ModuleData();

        this.userStore.user.onceDefined((user) => {
            this.isActive = user.IsActive;
            this.user = user;
            if (this.user && this.user.Email && this.user.CustomerName) {
                // pendo.initialize({
                //     visitor: {
                //         id: this.user.Email,
                //         roles: this.user.Roles,
                //         featureNames: this.user.FeatureName.length > 0 ? this.user.FeatureName : [],
                //         featuresExpiration: this.user.FeaturesWithExpiration.length > 0 ? this.user.FeaturesWithExpiration.map((x) => x.Expiration) : [],
                //         userLimit: this.user.FeaturesWithExpiration.length > 0 ? this.user.FeaturesWithExpiration.map((x) => x.UserLimit) : []
                //     },
                //     account: {
                //         id: this.user.CustomerName,
                //         partner: this.user.PartnerName,
                //         country: this.user.Country.length > 0 ? this.user.Country[0] : ""
                //     }
                // });
            }
        });
    }

    public subscriptionData!: any[];


    private openNewEstimateModal(type: EstimateType, route: string): void {
        const modal = this.modalManager.open<string, NewEstimateModalComponent>(NewEstimateModalComponent);
        modal.once((name) => {
            const request = this.store.dispatch(new CreateEstimate(name, type));
            request.once(() => {
                this.dataStore.setIsIndustryAnalysis(false);
                this.router.navigate([route]);
            });
            this.spinner.while(request);
        });
    }

    private openNewBetaEstimateModal(type: EstimateType, route: string): void {
        const modal = this.modalManager.open<string, NewBetaEstimateModalComponent>(NewBetaEstimateModalComponent);
        modal.once((name) => {
            const request = this.store.dispatch(new CreateBetaEstimate(name, type));
            request.once(() => {
                this.router.navigate([route]);
            });
            this.spinner.while(request);
        });
    }

    private startIndustryAnalysis(isInternationalIndustryBenchmarking: boolean = false): void {
        combineLatest([
            this.userStore.user,
            this.valuesSelector
        ]).pipe(
            takeUntil(this.destroyed),
            map((x) => ({
                user: x[0],
                values: x[1]
            }))
        ).onceDefined((data) => {
            if (data.user == null || data.values == null) {
                return;
            }

            const defaultValuationDate = moment().toISOString();

            if (isInternationalIndustryBenchmarking) {
                this.setTrialMinimumDate(data, ProductType.Ini, SubscriptionLevel.Trial, defaultValuationDate);
                this.router.navigate(['intlindustry-benchmarking']);
            } else {
                this.setTrialMinimumDate(data, ProductType.Usi, SubscriptionLevel.Trial, defaultValuationDate);
                this.router.navigate(['industry-benchmarking']);
            }
        });
    }


    private setTrialMinimumDate(data: any, productType: ProductType, level: SubscriptionLevel, defaultValuationDate: string): void {
        if (data.user.subscriptionDetails.find((x: any) => x.productType === productType && x.level === level)) {
            const minimumValuationDate = UserUtil.getSubscriptionMinimumValuationDate(
                productType,
                SubscriptionLevel.Trial,
                data.values.UsIndustries,
                data.user.subscriptionDetails
            );
            defaultValuationDate = minimumValuationDate.toISOString();
        }
        this.dataStore.setIsIndustryAnalysis(true);
        this.dataStore.setAnalysis(new Analysis(defaultValuationDate, [], true));
    }

    private getDaysCount(subsDate: Date): any {
        const presentDate = new Date();
        const oneDay = 1000 * 60 * 60 * 24;
        const date = Math.round(subsDate.getTime() - presentDate.getTime() + 1) / (oneDay);
        return date.toFixed(0);
    }

    private activateModule(subscriptionData: SubscriptionData | undefined, dashboardModule: DashboardModule): void {
        if (subscriptionData == null) {
            return;
        }
        dashboardModule.featureName = subscriptionData.level;
        dashboardModule.subscriptionExpiration = subscriptionData.expiration.asDate();

        if (subscriptionData.isExpired) {
            dashboardModule.isDisabled = true;
        } else {
            dashboardModule.isDisabled = false;
            this.subscriptionExpiryDate = dashboardModule.subscriptionExpiration;
        }
    }

    public getStatus(): void {
        this.userClient.productNewsReadStatusGet().subscribe((response) => {
            if (!response) {
                this.filesClient.getFile('Banner_Message.json').subscribe((res) => {
                    this.bannerMessage = JSON.parse(atob(res.toString()));
                });
            }
            this.isBannerMessageRead = response;
        });
    }

    public getNewsAndInsights(): void {
        this.spinner.begin();
        this.filesClient.getDashboardFiles('news-and-insights.json').subscribe((res) => {
            this.insights = JSON.parse(atob(res.toString()));
            this.spinner.end();
        });
    }

    public launchModule(module: DashboardModule): void {
        if (module.isDisabled) {
            window.open(module.storeUrl, '_blank');
        } else {
            if (module.type === ModuleType.UsCostOfCapital) {
                this.openNewEstimateModal(EstimateType.USEstimate, 'estimate');
            } else if (module.type === ModuleType.UsIndustryBenchmarking) {
                this.startIndustryAnalysis();
            } else if (module.type === ModuleType.InternationalCostOfCapital) {
                this.openNewEstimateModal(EstimateType.InternationalEstimate, 'international-estimate');
            } else if (module.type === ModuleType.InternationalIndustryBenchmarking) {
                this.startIndustryAnalysis(true);
            } else if (module.type === ModuleType.Beta) {
                this.openNewBetaEstimateModal(EstimateType.BetaEstimate, 'beta');
                // this.navigate('beta');
            }
        }
    }

    public ngOnInit(): void {
        this.SubscriptionData();
        this.userStore.user.onceDefined((user) => {
            this.isActive = user.IsActive;
            this.user = user;

            if (user && user.Email && user.Email.toLowerCase().includes('@duffandphelps.com') || user.Email.toLowerCase().includes('@kroll.com')) {
                this.isInternal = true;
            }

            if (this.user && this.user.Email && this.isInternal) {
                pendo.initialize({
                    visitor: {
                        id: this.user.Email
                    },
                    account: {
                        id: "Kroll Employee"
                    }
                });
            } else if (this.user && this.user.Email && this.user.CustomerName) {

                try {
                    pendo.initialize({
                        visitor: {
                            id: this.user.Email,
                            roles: this.user.Roles,
                            featureNames: this.user.FeatureName?.length > 0 ? this.user.FeatureName : [],
                            featuresExpiration: this.user.FeaturesWithExpiration?.length > 0 ? this.user.FeaturesWithExpiration.map((x) => x.Expiration) : [],
                            userLimit: this.user.FeaturesWithExpiration?.length > 0 ? this.user.FeaturesWithExpiration.map((x) => x.UserLimit) : []
                        },
                        account: {
                            id: this.user.CustomerName,
                            partner: this.user.PartnerName,
                            country: this.user.Country?.length > 0 ? this.user.Country[0] : ""
                        }
                    });

                } catch (error) {
                    console.log(error);

                }



            }

            if (user && user.IsActive === false) {
                this.cocUSModule = true;
                this.cocIntlModule = true;
                this.benchmarkUSModule = true;
                this.benchmarkIntlModule = true;
                this.betaModule = true;
            }
            this.showBuyNow = user.hasTrial();
            this.titleService.set(`${user.FullName} <span>${user.Email}</span>`);

            const cocSubData = user.subscriptionDetails.find((sub) => sub.productType === ProductType.Coc);
            const usiSubData = user.subscriptionDetails.find((sub) => sub.productType === ProductType.Usi);
            const ineSubData = user.subscriptionDetails.find((sub) => sub.productType === ProductType.Ine);
            const iniSubData = user.subscriptionDetails.find((sub) => sub.productType === ProductType.Ini);
            const btaSubData = user.subscriptionDetails.find((sub) => sub.productType === ProductType.Bta);
            this.mapUserSubscriptionsDetails(user, user.subscriptionDetails);
            // this.activateModule(cocSubData, this.moduleData.usCostOfCapital);
            // this.activateModule(usiSubData, this.moduleData.usIndustryBenchmarking);
            // this.activateModule(ineSubData, this.moduleData.internationalCostOfCapital);
            // this.activateModule(iniSubData, this.moduleData.internationalIndustryBenchmarking);
            // this.activateModule(btaSubData, this.moduleData.Beta);

            this.moduleData.usCostOfCapital.storeUrl = environment.urls.fastSpring + '/coc-usi-bta-module';
            this.usCostOfCapitalSubscribe = environment.urls.fastSpring + '/coc-usi-bta-module';
            this.moduleData.usIndustryBenchmarking.storeUrl = environment.urls.fastSpring + '/coc-usi-bta-module';
            this.usIndustryBenchmarkingSubscribe = environment.urls.fastSpring + '/coc-usi-bta-module';
            this.moduleData.internationalCostOfCapital.storeUrl = environment.urls.fastSpring + '/ine-ini-bta-module';
            this.internationalCostOfCapitalSubscribe = environment.urls.fastSpring + '/ine-ini-bta-module';
            this.moduleData.internationalIndustryBenchmarking.storeUrl = environment.urls.fastSpring + '/ine-ini-bta-module';
            this.internationalIndustryBenchmarkingSubscribe = environment.urls.fastSpring + '/ine-ini-bta-module';
            // this.moduleData.Beta.storeUrl = user.getProRatedLink(ProductType.Bta, this.BtaFastSpringUrl);
            // this.BetaModuleSubscribe = environment.urls.fastSpring + '/beta';

            user.subscriptionDetails.forEach((sub) => {
                if (sub.reseller && sub.reseller.toLowerCase() === 'valusource') {
                    this.moduleData.usCostOfCapital.storeUrl = environment.urls.valuSource + 'kroll-cost-of-capital-navigator-us-cost-of-capital-module/';
                    this.usCostOfCapitalSubscribe = environment.urls.valuSource + 'kroll-cost-of-capital-navigator-us-cost-of-capital-module/';
                    this.moduleData.usIndustryBenchmarking.storeUrl = environment.urls.valuSource + 'kroll-cost-of-capital-navigator-us-industry-benchmarking-module/';
                    this.usIndustryBenchmarkingSubscribe = environment.urls.valuSource + 'kroll-cost-of-capital-navigator-us-industry-benchmarking-module/';
                    this.moduleData.internationalCostOfCapital.storeUrl = environment.urls.valuSource + 'kroll-cost-of-capital-navigator-international-cost-of-capital-module/';
                    this.internationalCostOfCapitalSubscribe = environment.urls.valuSource + 'kroll-cost-of-capital-navigator-international-cost-of-capital-module/';
                    this.moduleData.internationalIndustryBenchmarking.storeUrl = environment.urls.valuSource + 'kroll-cost-of-capital-navigator-international-industry-benchmarking-module/';
                    this.internationalIndustryBenchmarkingSubscribe = environment.urls.valuSource + 'kroll-cost-of-capital-navigator-international-industry-benchmarking-module/';
                    // this.moduleData.Beta.storeUrl = environment.urls.valuSource + 'company-level-beta-module/';
                    // this.BetaModuleSubscribe = environment.urls.valuSource + 'company-level-beta-module/';
                }
            });

            const expirationDates: Date[] = [this.moduleData.usCostOfCapital.subscriptionExpiration,
            this.moduleData.internationalCostOfCapital.subscriptionExpiration,
            this.moduleData.usIndustryBenchmarking.subscriptionExpiration,
            this.moduleData.internationalIndustryBenchmarking.subscriptionExpiration,
            this.moduleData.Beta.subscriptionExpiration]
                .filter((x) => x.getDate() !== new Date(0).getDate());

            this.expirationDate = expirationDates.sort((a, b) => b.getDate() - a.getDate())[0];

            this.cocUSExpireDays = this.getDaysCount(this.moduleData.usCostOfCapital.subscriptionExpiration);
            this.cocIntlExpireDays = this.getDaysCount(this.moduleData.internationalCostOfCapital.subscriptionExpiration);
            this.benchmarkUSExpireDays = this.getDaysCount(this.moduleData.usIndustryBenchmarking.subscriptionExpiration);
            this.benchmarkIntExpireDays = this.getDaysCount(this.moduleData.internationalIndustryBenchmarking.subscriptionExpiration);
            this.BetaExpireDays = this.getDaysCount(this.moduleData.Beta.subscriptionExpiration);
            if (this.isActive) {
                if (this.moduleData.usCostOfCapital.isDisabled ||
                    this.moduleData.usIndustryBenchmarking.isDisabled ||
                    this.moduleData.internationalCostOfCapital.isDisabled ||
                    this.moduleData.internationalIndustryBenchmarking.isDisabled ||
                    this.moduleData.Beta.isDisabled) {
                    this.allModuleSubscribed = false;
                } else {
                    this.allModuleSubscribed = true;
                }
            } else {
                this.moduleData.usCostOfCapital.isDisabled = false;
                this.moduleData.usIndustryBenchmarking.isDisabled = false;
                this.moduleData.internationalCostOfCapital.isDisabled = false;
                this.moduleData.internationalIndustryBenchmarking.isDisabled = false;
                this.moduleData.Beta.isDisabled = false;
            }
            // Hiding Beta Module in prod
            // if (environment.production) {
            //     this.moduleData.Beta.isDisabled = true;
            //     this.isProdEnv = true;
            // }
        });
        this.getHelpinfoJsonData()
        this.getStatus();
        this.getNewsAndInsights();

        this.http.get(environment.urls.contentManagement + '/coc-dashboard-content.json')
            .subscribe((res) => {
                const content = res as DashboardDataReponse;
                this.imageUrls = content.dashboardImageUrls;
                this.getHtmlFile(environment.urls.contentManagement + '/Navigator-Dashboard.html');
                this.getHtmlFile(content.productNews);
                this.getHtmlFile(content.dashboardIntro);
                this.cocNews = content.cocModulesNews;
                this.benchNews = content.indBenchmarkingModulesNews;
                this.dashboardNews = content.dashboardReleaseNews;
                this.moduleData.usCostOfCapital.faqLink = content.usCocFaqlink;
                this.moduleData.usCostOfCapital.helpLink = content.usCocHelpLink;
                this.moduleData.usCostOfCapital.productNews = content.usCocNews;
                this.moduleData.usCostOfCapital.resourcesLink = content.usCocResourcesLink;
                this.moduleData.usCostOfCapital.viewDemoLink = content.usCocVideoLink;
                this.moduleData.internationalCostOfCapital.faqLink = content.inCocFaqLink;
                this.moduleData.internationalCostOfCapital.helpLink = content.inCocHelpLink;
                this.moduleData.internationalCostOfCapital.productNews = content.inCoCNews;
                this.moduleData.internationalCostOfCapital.resourcesLink = content.inCocResourcesLink;
                this.moduleData.internationalCostOfCapital.viewDemoLink = content.inCocVideoLink;
                this.moduleData.usIndustryBenchmarking.faqLink = content.usIndBenchmarkingFaqLink;
                this.moduleData.usIndustryBenchmarking.helpLink = content.usIndBenchmarkingHelpLink;
                this.moduleData.usIndustryBenchmarking.productNews = content.usIndBenchmarkingNews;
                this.moduleData.usIndustryBenchmarking.resourcesLink = content.usIndBenchmarkingResourcesLink;
                this.moduleData.usIndustryBenchmarking.viewDemoLink = content.usIndBenchmarkingVideoLink;
                this.moduleData.internationalIndustryBenchmarking.faqLink = content.inIndBenchmarkingFaqLink;
                this.moduleData.internationalIndustryBenchmarking.helpLink = content.inIndBenchmarkingHelpLink;
                this.moduleData.internationalIndustryBenchmarking.productNews = content.inIndBenchmarkingNews;
                this.moduleData.internationalIndustryBenchmarking.resourcesLink = content.inIndBenchmarkingResourcesLink;
                this.moduleData.internationalIndustryBenchmarking.viewDemoLink = content.inIndBenchmarkingVideoLink;
            });
        // let p: IVisitor = { id: this.user.ProfileId }
        // let a: IAccount = { id: this.user.CustomerId }
        // this.pendo.initialize(p, a)

    }




    public getHtmlFile(fileName: string): void {
        if (fileName) {
            this.http.get(fileName, { responseType: 'text' }).subscribe((res) => {
                if (res.length === 0) {
                    this.getHtmlFile(fileName);
                }
                if (fileName.includes('Navigator-Dashboard')) {
                    this.navigatorDashboard = this.sanitizer.bypassSecurityTrustHtml(res.toString());
                }
                if (fileName.includes('Product-News')) {
                    this.productNews = res.toString();
                }
                if (fileName.includes('dashboard-intro')) {
                    this.dashboardIntro = res.toString();
                }
            });
        }
    }

    public productNewsRead(bannerMessageId: string, bannerStatus: boolean): void {
        this.isBannerMessageRead = true;
        this.userClient.productNewsReadStatusUpdate(bannerMessageId, bannerStatus).subscribe();
    }

    // public hideBanner(bannerMessageId: string): void {
    //     this.isBannerMessageRead = true;
    //     this.userClient.productNewsReadStatusUpdate(bannerMessageId, false).subscribe();
    // }

    public getResourceFile(fileName: string): void {
        this.filesClient.getResourceFile(fileName).subscribe((data) => {
            ResourcesLibraryComponent.openFile(data, fileName);
        });
    }

    public showCocUS(): void {
        this.displayService.DisplayCocUSModule(true);
    }

    public showBenchMarkingUS(): void {
        this.displayService.DisplayBenchmarkUSModule(true);
    }

    public showCoc(): void {
        this.displayService.DisplayCocIntlModule(true);
    }

    public showBenchMarkingIntl(): void {
        this.displayService.DisplayBenchmarkIntlModule(true);
    }

    public navigate(link: string): void {
        this.router.navigate([link]);
    }

    public goto = (route: string) => this.router.navigate([route]);

    public displayModuleGroup(moduleCode: string): void {
        this.goto('dashboard').then(() => {
            if (moduleCode === 'cocUS') {
                this.cocUSModule = true;
            } else if (moduleCode === 'cocIntl') {
                this.cocIntlModule = true;
            } else if (moduleCode === 'indUS') {
                this.benchmarkUSModule = true;
            } else if (moduleCode === 'indIntl') {
                this.benchmarkIntlModule = true;
            } else {
                this.displayService.ResetDisplay();
            }
        });
    }

    mapUserSubscriptionsDetails(user: User, subscriptionData: SubscriptionData[]) {
        const subscriptionFeatures = subscriptionData.filter(item => (item.productType === ProductType.Coc) || (item.productType === ProductType.Ine));
        if (subscriptionFeatures.length > 0) {
            const isCocProExists = subscriptionFeatures.find(s => s.productType === ProductType.Coc);
            const ineProExist = subscriptionFeatures.find(d => d.productType === ProductType.Ine);
            if (isCocProExists) {
                this.objUsCocUserDetails.Status = isCocProExists.isExpired ? 'Inactive' : this.chekIsRenew(isCocProExists.expiration.asDate()) ? 'Renew' : 'Active';
                this.objUsCocUserDetails.Expiration = isCocProExists.expiration.month + "/" + isCocProExists.expiration.day + "/" + isCocProExists.expiration.year
            } else {

            }

            if (ineProExist) {
                this.objinCocUserDetails.Status = ineProExist.isExpired ? 'Inactive' : this.chekIsRenew(ineProExist.expiration.asDate()) ? 'Renew' : 'Active';
                this.objinCocUserDetails.Expiration = ineProExist.expiration.month + "/" + ineProExist.expiration.day + "/" + ineProExist.expiration.year
            } else {

            }

        }

        else if (subscriptionFeatures.length === 0 && user.FeaturesWithExpiration.length > 0) {
            const isCocProExists = user.FeaturesWithExpiration.find(s => s.Name === SubscriptionType.CocPro);
            const ineProExist = user.FeaturesWithExpiration.find(d => d.Name === SubscriptionType.InePro);
            if (isCocProExists) {
                this.objUsCocUserDetails.Status = 'Inactive';
                this.objUsCocUserDetails.Expiration = this.dateforamt(isCocProExists.Expiration);
            }

            if (ineProExist) {
                this.objinCocUserDetails.Status = 'Inactive'
                this.objinCocUserDetails.Expiration = this.dateforamt(ineProExist.Expiration)
            }
        }


    }

    //date format chnages
    dateforamt(date: any): string {
        let tempDate = new Date(date);
        let formatDate = tempDate.getMonth() + '/' + tempDate.getDay() + '/' + tempDate.getFullYear();
        return formatDate
    }

    chekIsRenew(expirationDate: Date): boolean {
        let todaydate = new Date();
        const diffInMs = new Date(expirationDate).getTime() - new Date(todaydate).getTime();
        const diffInDays = diffInMs / (1000 * 60 * 60 * 24);
        if (diffInDays <= 90) {
            return true;
        } else {
            return false
        }

    }

    public usSubscription() {

        let isNewUser = false;

        if (!this.user.PartnerName) {

            if (this.user.Subscriptions[0]?.Type == 'None') {
                isNewUser = true;
            }
        }



        if (this.user.PartnerName || isNewUser) {
            if (this.user.PartnerName === "Internal" || isNewUser) {
                window.open(environment.urls.fastSpring + '/uscofc');
            } else {
                window.open('https://www.valusource.com/products/business-appraiser-databases/kroll-u-s-cost-of-capital-module/');
            }
        }

    }

    public intlSubscription() {

        let isNewUser = false;

        if (!this.user.PartnerName) {

            if (this.user.Subscriptions[0]?.Type == 'None') {
                isNewUser = true;

            }
        }


        if (this.user.PartnerName || isNewUser) {
            if (this.user.PartnerName === "Internal" || isNewUser) {
                window.open(environment.urls.fastSpring + '/intlcofc');
            } else {
                window.open('https://www.valusource.com/products/business-appraiser-databases/kroll-international-cost-of-capital-module/');
            }
        }

    }




    public SubscriptionData() {

        this.subscriptionData = [
            {
                label: Subscriptionlabels.Subscription,
                keyValue: 'Subscription'
            },
            {
                label: Subscriptionlabels.Status,
                keyValue: 'Status'
            },
            {
                label: Subscriptionlabels.Expiration,
                keyValue: 'Expiration'

            },
            {
                label: Subscriptionlabels.GeographicCoverage,
                keyValue: 'GeographicCoverage'

            },
            {
                label: Subscriptionlabels.DatasetsIncluded,
                keyValue: 'DatasetsIncluded'

            },
            {
                label: Subscriptionlabels.DatapointsOffered,
                keyValue: 'DatapointsOffered'

            },
            {
                label: Subscriptionlabels.EarliestCoverage,
                keyValue: 'EarliestCoverage'

            },
            {
                label: Subscriptionlabels.SampleData,
                keyValue: 'SampleData'

            },
        ]
    }


    public isRenewInactive(key: string, status: string): boolean {
        if (key == 'Status' && status == 'Renew') {
            return true;
        } else if (key == 'Status' && status == 'Inactive') {
            return true;
        } else {
            return false;
        }

    }

    public navigateToDataponts() {
        this.router.navigate(['/dataPoints-offered'])
    }

    //set the  HelpInfo Data 
    private getHelpinfoJsonData() {
        this.spinner.begin();
        this.filesClient.getInfoTextData(ResourceFileType.HelpInfoData).subscribe(data => {
            const infoData: HelpTextTypes = JSON.parse(data);
            updateHelpText(infoData.helpText);
            updateTearsheetHelpText(infoData.tearSheetHelpText);
            updateIntlTearSheetHelpText(infoData.intlTearSheetHelpText);
        }, (error: any) => {
            console.log("get HelpText Error", error);
        });
        this.spinner.end();
    }

}
