import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ExcelExportModule } from '@progress/kendo-angular-excel-export';
import { NarikCustomValidatorsModule } from '@narik/custom-validators';
// Components
import { ConcurrencyModule } from '@concurrency/angular';
import { EquationGroupComponent } from './equation-group.component';
import { EquationKeyComponent } from './equation/equation-key.component';
import { EquationParameterComponent } from './equation/equation-parameter.component';
import { EquationWrapperComponent } from './equation/equation-wrapper.component';
import { EquationComponent } from './equation/equation.component';
import { HelpIconComponent } from './help-icon.component';
import { InfoIconComponent } from './info-icon.component';
import { CommonCheckboxComponent } from './inputs/common-checkbox.component';
import { CommonComboboxComponent } from './inputs/common-combobox.component';
import { CommonDatepickerComponent } from './inputs/common-datepicker.component';
import { CommonTextInputComponent } from './inputs/common-text-input.component';
import { CommonTypeaheadComponent } from './inputs/common-typeahead.component';
import { InputContainerComponent } from './inputs/input-container.component';
import { FormatDateStructPipe } from './pipes/format-date-struct.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormatNumberWithCommaPipe } from './pipes/format-number-with-comma.pipe';
import { FormatTableDataPipe } from './pipes/format-table-data.pipe';
import { StepNavComponent } from './step-nav.component';
import { CommonTableComponent } from './table/common-table.component';
import { NzIconModule } from 'ng-zorro-antd/icon';


const declarationsAndExports = [
    CommonTextInputComponent,
    CommonComboboxComponent,
    CommonDatepickerComponent,
    CommonTypeaheadComponent,
    CommonCheckboxComponent,
    InputContainerComponent,
    HelpIconComponent,
    InfoIconComponent,
    StepNavComponent,
    EquationComponent,
    EquationParameterComponent,
    EquationKeyComponent,
    EquationWrapperComponent,
    CommonTableComponent,
    EquationGroupComponent,
    FormatDateStructPipe,
    FormatDatePipe,
    FormatTableDataPipe,
    FormatNumberWithCommaPipe
];

@NgModule({
    declarations: [...declarationsAndExports],
    exports: [...declarationsAndExports],
    imports: [
        CommonModule,
        FormsModule,
        NgbModule,
        ConcurrencyModule,
        NarikCustomValidatorsModule,
        ExcelExportModule,
        NzIconModule,

    ]
})
export class NavCommonModule { }
