import { Component } from '@angular/core';
import { Util } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Equation, EquationParameter } from 'src/app/_navigator/data/model/equation.model';
import { OperandUtility } from '../operand.utility';
import { ScenarioStudyComponent } from '../scenario-study.component';
import { RiskTableComponent } from './risk-table.component';
import { Risk } from './risk.model';

@Component({ templateUrl: '../scenario-study.component.html' })
export class RiskCapmComponent extends ScenarioStudyComponent {
    protected equationType = EquationType.RprsCapmSizeStudy;
    protected hasRegressionToggle = true;
    public dataTable = RiskTableComponent;

    private buildEquation(risk: Risk): Equation {
        const operandUtility = new OperandUtility();
        const costOfEquityCapital = OperandUtility.getCostOfEquityCapital(this.equationType, risk.implicationType);
        const riskOperand = OperandUtility.getRiskPremium(this.equationType, risk);
        const betaOperand = OperandUtility.getBeta(SelectionType.RiskPremiumBeta);

        const ke: EquationParameter = { item: costOfEquityCapital, operator: '=', canEdit: false };
        const rf: EquationParameter = { item: operandUtility.riskFreeRate, operator: '+', canEdit: true };
        const b: EquationParameter = { item: betaOperand, operator: '&#215;', canEdit: true };
        const erp: EquationParameter = { item: operandUtility.equityRiskPremium, operator: '+', canEdit: true };
        const sp: EquationParameter = { item: riskOperand, operator: '', canEdit: true };

        return {
            name: risk.name,
            equationType: this.equationType,
            implicationType: risk.implicationType,
            parameters: [ke, rf, b, erp, sp],
            calculate: (scenario) => {
                const equation = scenario.getEquation(this.equationType, risk.implicationType);
                const riskFreeRate = scenario.getValueOrNaN(SelectionType.RiskFreeRate, InputType.None);
                const beta = scenario.getValueOrNaN(SelectionType.RiskPremiumBeta, InputType.None);
                const equityRiskPremium = scenario.getValueOrNaN(SelectionType.EquityRiskPremium, InputType.None);
                const riskPremium = scenario.getValueOrNaN(risk.selectionType, risk.inputType);

                const betaTimesEquityRiskPremium = Util.round(beta * equityRiskPremium);
                const result = riskFreeRate + betaTimesEquityRiskPremium + riskPremium;
                equation.Result = result || undefined;

                return equation.Result != null;
            }
        };
    }

    public buildEquations(): Equation[] {
        const risks = OperandUtility.getRisks(this.equationType);
        return risks.map((risk) => this.buildEquation(risk));
    }
}
