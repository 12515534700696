import { WorkbookSheetRow, WorkbookSheetRowCell } from '@progress/kendo-angular-excel-export';
import * as moment from 'moment';

type ExcelCellValue = string | number | boolean | Date | undefined | null;

export class ExcelExportUtil {
    public static createCell(value: ExcelCellValue, colSpan: number, format?: string): WorkbookSheetRowCell {
        return {
            value: value ? value : '-',
            colSpan,
            format,
            ...this.mapBordersToCell()
        };
    }

    public static getEmptyCell(width: number): WorkbookSheetRowCell[] {
        const cell: WorkbookSheetRowCell = {
            value: '',
            colSpan: width,
            ...this.mapBordersToCell(),
        };

        const result: WorkbookSheetRowCell[] = [];
        result.push(cell);

        return result;
    }

    public static getRowWidth(row: WorkbookSheetRow): number {
        let wd = 0;

        if (row.cells) {
            row.cells.forEach((x) => {
                if (x.colSpan) {
                    wd += x.colSpan;
                }
            });
        }

        return wd;
    }

    public static getWidthOfWidestRow(rowData: WorkbookSheetRow[]): number {
        let wd = 0;

        rowData.forEach((x: WorkbookSheetRow) => {
            const cellwidth: number = this.getRowWidth(x);

            if (wd < cellwidth) {
                wd = cellwidth;
            }
        });

        return wd;
    }

    public static getSheet(name: string, ...width: number[]): any {
        return {
            name,
            columns: width.map((x) => {
                return { width: x };
            }),
            rows: []
        };
    }

    public static getRow(...text: string[]): any {
        return {
            cells: text.map((x) => {
                return { value: x };
            })
        };
    }

    public static toPercent(percentValue: any): any {
        if (percentValue && percentValue != null) {
            const x = (parseFloat(percentValue) / 100.0);
            if (isNaN(x)) { return ''; } else { return x; }
        } else {
            return '';
        }
    }

    public static toNumber(value: any): any {
        // return as a number for excel or possible N/A string
        if (value == null) { return ''; }
        const x = Number(value);
        if (isNaN(x)) { return value; } else { return x; }
    }

    public static getSimpleRow(value: string, span: number = 6, config: any = {}): any {
        config.value = value;
        config.colSpan = span;

        return { cells: [config] };
    }

    public static getFooter(moduleName: string): WorkbookSheetRow[] {
        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: ''
            }]
        },
        {
            cells: [{
                value: 'Kroll all rights reserved',
                bold: true,
                colSpan: 10,
                borderTop: { size: 1 }
            }]
        }, {
            cells: [{
                value: `Sourced from the Cost of Capital Navigator - ${moduleName}`,
                bold: true
            }]
        }, {
            cells: [{
                value: `Exported on : ${moment().format('MM/DD/YYYY')}`,
                bold: true
            }]
        }];

        return row;
    }

    public static mapBordersToCell(): any {
        return {
            borderBottom: { size: 1, color: "#ECEEEF" },
            borderLeft: { size: 1, color: "#ECEEEF" },
            borderRight: { size: 1, color: "#ECEEEF" },
            borderTop: { size: 1, color: "#ECEEEF" },
        };
    }
}
