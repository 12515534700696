import * as moment from 'moment';

import { Injectable } from '@angular/core';
import {
    ExcelExportComponent,
    Workbook,
    WorkbookSheetRow,
    WorkbookSheetRowCell
} from '@progress/kendo-angular-excel-export';
import { IndustryMetric } from 'src/app/_api/responses/industry-metric.response';
import { ExcelExportUtil } from 'src/app/estimate/exports/util/excel-export.util';
import { TrendsOverTimeIndustry } from '../../../benchmarking/data/trends-over-time-industry';
import { TrendsOverTimeTableData } from '../../../benchmarking/data/trends-over-time-table-data';
import { TearSheetService } from '../../tear-sheet/data/tear-sheet.service';
import { TrendsOverTimeService } from '../../tear-sheet/data/trends-over-time.service';
import { BenchmarkingExcelExportService } from 'src/app/estimate/exports/benchmarkingexcel-export.service';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { CommonExcelExportUtil } from 'src/app/_navigator/common/common-excel-export-util';
import { TearSheetUtil } from '../../tear-sheet/util/tear-sheet.util';

@Injectable()
export class TrendsOverTimeExcelExportService {

    private moduleName = 'U.S. Industry Benchmarking Module'; // TODO rename this based on subscription or url

    public columnWidth = 125;       // width of each cell in grid system
    public blueColor = '#14487f'; // color of all orange items
    public numberFormat = '0.00';   // number format for cells
    public grayBackGround = '#F6F6F7'
    public isBeta!: boolean;

    constructor(
        private trendsOverTimeService: TrendsOverTimeService,
        private tearSheetService: TearSheetService,
        private benchmarkingExcelExportService: BenchmarkingExcelExportService,
        private dataStore: DataStore
    ) { }

    private saveWorksheet(component: ExcelExportComponent, tableData: TrendsOverTimeTableData): void {
        this.isBeta = TearSheetUtil.isBeta(tableData.trendsOverTimeData[0][0].Metric);
        let excelExport = this.getWorksheet(tableData);
        excelExport = this.tearSheetService.setDefaultExcel(excelExport);
        this.dataStore.aboutData.onceDefined(data => {
            let aboutworkBook = this.tearSheetService.setDefaultExcel(this.benchmarkingExcelExportService.aboutBenchMarkingSheet(data));
            excelExport.sheets.push(...aboutworkBook.sheets)
            excelExport = CommonExcelExportUtil.getUpdatedCopyRigtsSheet(excelExport);
            component.save(excelExport);
        });
    }

    private updateSourceFromText(excel: Workbook, analysisName: string): Workbook {

        const replacementCellSheet: number = 1; //About spreadsheet (zero based index number)
        const replacementCellColumn: number = 0 //Column to replace text at (zero based index number)
        const replacementCellRow: number = analysisName == 'usiBenchmarking' ? 26 : 22; //row to repalce text (zero based index number)
        excel.sheets.forEach((sheet, s) => {
            if (s == replacementCellSheet) {
                sheet.rows?.forEach((row, r) => {
                    if (r == replacementCellRow) {
                        row.cells?.forEach((col, c) => {
                            if (c == replacementCellColumn) {
                                col.value = '* Sourced from the Cost of Capital Navigator - Industry Benchmarking Module'
                            }
                        })
                    }
                })
            }
        })

        return excel;
    }

    private getWorksheet(tableData: TrendsOverTimeTableData): Workbook {


        const workbook = new Workbook();
        workbook.sheets = [{
            name: `Trends Over Time`,
            columns: Array(8).fill({ width: this.columnWidth }),
            rows: [
                ...this.buildTableData(tableData),
            ]
        }];

        return workbook;
    }

    private getHeader(valuationDate: string): WorkbookSheetRow[] {
        const dateValue = new Date(valuationDate);

        const row: WorkbookSheetRow[] = [{
            cells: [{
                // value: `Data Updated Through : ${moment(dateValue).format('MM/DD/YYYY')}`,
                value: `Data as of: ${moment(dateValue).format('MM/DD/YYYY')}`,
                bold: true,
                colSpan: 6,

                // borderBottom: { size: 2 }
            }]
        },
        {},
        this.getMetricTitle()
        ];

        return row;
    }

    private getMetricTitle(): WorkbookSheetRow {
        const row: WorkbookSheetRow = {
            cells: [{
                value: this.trendsOverTimeService.metricTitle,
                colSpan: 4,
                bold: true,
                color: this.blueColor
            }]
        }
        return row;
    }

    private getCompLabels(): WorkbookSheetRow[] {
        const medianLabel = 'Median';
        const sicCompositeLabel = 'Industry Composite';
        const largeCompositeLabel = 'Large Composite';
        const smallCompositeLabel = 'Small Composite';
        const highFinancialRiskLabel = 'High-Financial Risk';

        // TODO create workbook sheet row builder
        const row: WorkbookSheetRow[] = [

            {
                cells: [{
                    value: '',
                    colSpan: 1,
                    background: this.grayBackGround
                }]
            },
            {
                cells: [{
                    value: medianLabel,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: sicCompositeLabel,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: largeCompositeLabel,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: smallCompositeLabel,
                    colSpan: 1
                }]
            },
            {
                cells: [{
                    value: highFinancialRiskLabel,
                    colSpan: 1
                }]
            }];

        row.forEach((row: WorkbookSheetRow) => {
            row.cells?.forEach((cel: WorkbookSheetRowCell, index: number) => {
                if (row.cells && row.cells?.length > 0 && cel.value) {
                    row.cells[index] = { ...cel, ...this.mapBordersToCell() }
                }
            })
        })

        return row;
    }

    private buildMetricTableRow(metric: IndustryMetric): WorkbookSheetRow[] {
        const tableRow: WorkbookSheetRow[] = [];
        let row: WorkbookSheetRow = {};
        row.cells = [];
        let cl: WorkbookSheetRowCell = {};
        this.numberFormat = this.isBeta ? '0.00' : '0.0'



        // TODO construct builder to build this out and return the entire result row
        cl = ExcelExportUtil.createCell(this.getRoundedValue(metric.MedianLatest), 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = ExcelExportUtil.createCell(this.getRoundedValue(metric.SICCompositeLatest), 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = ExcelExportUtil.createCell(this.getRoundedValue(metric.LargeCompositeLatest), 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = ExcelExportUtil.createCell(this.getRoundedValue(metric.SmallCompositeLatest), 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];

        cl = ExcelExportUtil.createCell(this.getRoundedValue(metric.HighFinancialRiskLatest), 1, this.numberFormat);
        row.cells.push(cl);

        tableRow.push(row);

        row = {};
        row.cells = [];
        tableRow.forEach((row: WorkbookSheetRow) => {
            row.cells?.forEach((cel: WorkbookSheetRowCell, index: number) => {
                if (row.cells && row.cells?.length > 0) {
                    row.cells[index] = { ...cel, ...this.mapBordersToCell() };
                }
            });
        });

        return tableRow;
    }

    private getRoundedValue(value: number | null): number | null {
        let roundedValue = value ? +TearSheetUtil.roundTearSheetValue(value, this.isBeta) : value;
        return roundedValue;
    }

    private buildScenarioHeader(industryData: TrendsOverTimeIndustry): WorkbookSheetRow[] {
        const header = this.trendsOverTimeService.createIndustryFilterLabel(industryData.SicId, industryData.GicId);
        const row: WorkbookSheetRow[] = [

            {
                cells: [{
                    value: `${header} - ${industryData.Sector} - ${industryData.Area} - ${industryData.CurrencyCode}`,
                    colSpan: 4,
                    // borderBottom: { size: 2, color: this.blueColor },
                    bold: true,
                    color: this.blueColor
                },

                ]
            }
        ];

        return row;
    }

    private buildDataAsOfTitle(title: string, width: number): WorkbookSheetRow[] {
        const dateValue = new Date(title);

        const row: WorkbookSheetRow[] = [{
            cells: [{
                value: moment(dateValue).format('MM/DD/YYYY'),
                colSpan: width,
                // borderBottom: { size: 3 },
                ...this.mapBordersToCell(),
                textAlign: 'right',
                bold: true,
                background: this.grayBackGround

            }]
        }];

        return row;
    }

    private buildTableData(tableData: TrendsOverTimeTableData): WorkbookSheetRow[] {
        let resultData: WorkbookSheetRow[] = [];
        let scenarioHeader: WorkbookSheetRow[] = [];
        let rightSideTableData: WorkbookSheetRow[] = [];
        let combineCompLables: WorkbookSheetRow[] = [];
        const valuationDate = tableData.industries[0].ValuationDate;



        const titleWidth = 1;

        tableData.trendsOverTimeData.forEach((scenario, index) => {
            scenarioHeader = this.buildScenarioHeader(tableData.industries[index]);
            if (index === 0) {
                scenarioHeader.push(...this.getHeader(valuationDate))
            } else {
                scenarioHeader.unshift({})//pushing empty row to create space
                scenarioHeader.push(...this.getHeader(valuationDate))

            }
            scenario.forEach((metric) => {
                let tableTitle = this.buildDataAsOfTitle(metric.DataAsOf, titleWidth);
                const tableRow = this.buildMetricTableRow(metric.Metric);

                tableTitle = tableTitle.concat(tableRow);

                if (rightSideTableData.length > 0) {
                    rightSideTableData = this.concatenateRows(rightSideTableData, tableTitle);
                } else {
                    rightSideTableData = tableTitle;
                }
            });

            // rightSideTableData = scenarioHeader.concat(rightSideTableData);
            combineCompLables = this.concatenateRows(this.getCompLabels(), rightSideTableData);
            combineCompLables.unshift(...scenarioHeader)

            resultData = resultData.concat(combineCompLables);

            rightSideTableData = [];
        });

        return resultData;
    }

    private concatenateRows(rowDataLeft: WorkbookSheetRow[], rowDataRight: WorkbookSheetRow[]): WorkbookSheetRow[] {
        const leftWidth: number = ExcelExportUtil.getWidthOfWidestRow(rowDataLeft);

        const rowData: WorkbookSheetRow[] = [];
        for (let i = 0; i < rowDataLeft.length; ++i) {
            if (i < rowDataRight.length) { // row in right side, add it to the left
                const leftCells = rowDataLeft[i].cells;
                const rightCells = rowDataRight[i].cells;

                if (leftCells && rightCells) {
                    let newCells: WorkbookSheetRowCell[] = [];

                    newCells = leftCells.concat(rightCells);
                    rowDataLeft[i].cells = newCells;
                }

                rowData.push(rowDataLeft[i]);
            }
        }

        //
        //  process any rows on right beyond the left
        //
        // for (let j: number = rowDataLeft.length; j < rowDataRight.length; ++j) {
        //     const rightCells = rowDataRight[j].cells;

        //     if (rightCells) {
        //         let newCells: WorkbookSheetRowCell[] = ExcelExportUtil.getEmptyCell(leftWidth);
        //         newCells = newCells.concat(rightCells);
        //         rowDataRight[j].cells = newCells;
        //         rowData.push(rowDataRight[j]);
        //     }

        // }

        return rowData;
    }

    public save(component: ExcelExportComponent, tableData: TrendsOverTimeTableData): void {
        this.saveWorksheet(component, tableData);
    }

    private mapBordersToCell(): any {
        return {
            borderBottom: { size: 1, color: "#ECEEEF" },
            borderLeft: { size: 1, color: "#ECEEEF" },
            borderRight: { size: 1, color: "#ECEEEF" },
            borderTop: { size: 1, color: "#ECEEEF" },
        };
    }
}
