import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'formatDate' })
export class FormatDatePipe implements PipeTransform {
    public transform(value: string, yearFormat: string = "yy"): string {
        if(yearFormat.toLowerCase() === "yyyy") {
            return moment(value).format('MM/DD/YYYY');
        }
        return moment(value).format('MM/DD/YY');
    }
}
