import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalManager, Spinner } from '@concurrency/angular';
import { Store } from '@ngxs/store';
import { EstimateType } from 'src/app/_api/enums/estimate-type';
import { Gic } from 'src/app/_api/responses/gic.response';
import { Industry } from 'src/app/_api/responses/us-industry.response';
import { Estimate } from 'src/app/_navigator/data/model/estimate.model';
import { DataStore } from 'src/app/_navigator/data/store/data.store';
import { TypeaheadFormatters } from 'src/app/_navigator/data/util/typeahead-formatters.util';
import { GetEstimate } from 'src/app/_navigator/estimate-store/estimate-actions';
import { ArchiveEstimate, CloneEstimate, GetEstimates, RenameEstimate } from 'src/app/_navigator/estimates-list-store/estimates-list-actions';
import { EstimatesListState, SortType } from 'src/app/_navigator/estimates-list-store/estimates-list-state';
import { BenchmarkingUtil } from 'src/app/benchmarking/benchmarking.util';
import { IndustryDataSharingService } from 'src/app/estimate/studies/service/industry-data-sharing.service';
import { ArchiveEstimateModalComponent } from '../_modals/archive-estimate-model.component';

@Component({
    selector: 'coc-estimates-table',
    templateUrl: './coc-estimates-table.component.html'
})
export class CocEstimatesTableComponent {
    private allowOutsideToggleFor?: string;
    private sortType = SortType.Date;
    private sortDescending = true;
    @Input() public moduleEstimates!: Estimate[] | undefined;
    @Input() public hideestimates = false;
    public expandedEstimate?: Estimate;
    public visibleDropdown?: string;
    public sameNameError?: boolean;
    public isRename: any = {};
    public SortTypes = SortType;
    public rename: string | undefined = '';
    public TypeaheadFormatters = TypeaheadFormatters;
    public EstimateType = EstimateType;
    public selectedIndustries: Industry[] = [];

    constructor(
        private dataStore: DataStore,
        private store: Store,
        private router: Router,
        private modalManager: ModalManager,
        private spinner: Spinner,
        public industryDataSharingService: IndustryDataSharingService
    ) {
        this.sort();
    }

    // public ngOnInit(): void { }

    public sort(sortType?: SortType): void {
        if (sortType === this.sortType) {
            this.sortDescending = !this.sortDescending;
        } else {
            if (sortType === SortType.Name) {
                this.sortDescending = false;
            } else if (sortType === SortType.Date) {
                this.sortDescending = true;
            }
        }

        this.sortType = sortType || SortType.Date;

        // this.store.select(EstimatesListState.sort(this.sortType, this.sortDescending)).onceDefined((sorted) => {
        //     this.moduleEstimates = sorted;
        // });
    }

    public routeTo(estimate: Estimate): void {
        this.dataStore.setIsIndustryAnalysis(false);

        if (estimate.EstimateType === EstimateType.USEstimate) {
            if (estimate.Industries != null) {
                const isSicGic = BenchmarkingUtil.isSic(estimate.ValuationDate.toString(), 'United States');
                estimate.Industries.forEach((item) => {
                    this.selectedIndustries.push({
                        CodeId: item.SicId,
                        GicIndustryCode: isSicGic === false ? item.SicCode : '',
                        Sector: item.IndustryName,
                        SicIndustryCode: isSicGic === true ? item.SicCode : ''
                    });
                });

                this.industryDataSharingService.setSharedIndustries = this.selectedIndustries;
            } else {
                const emptyIndustry: Industry[] = new Array<Industry>();
                emptyIndustry.push({
                    CodeId: 0,
                    GicIndustryCode: '',
                    SicIndustryCode: '',
                    Sector: ''
                } as Industry);
                this.industryDataSharingService.setSharedIndustries = emptyIndustry;
            }
            const request = this.dataStore.setupEstimate(estimate.Id);
            this.spinner.while(request);
            request.once(() => {
                this.router.navigate(['estimate']);
            });
        } else {
            const emptyGicIndustry: Gic[] = new Array<Gic>();
            emptyGicIndustry.push({
                GicId: 0,
                GicCode: '',
                GicDescription: ''
            } as Gic);
            this.industryDataSharingService.setSharedGicIndustries = emptyGicIndustry;

            const request = this.store.dispatch(new GetEstimate(estimate.Id));
            request.once(() => {
                this.router.navigate(['international-estimate']);
            });
            this.spinner.while(request);
        }
    }

    public toggleExpandedEstimate(estimate: Estimate): void {
        if (estimate === this.expandedEstimate) {
            this.expandedEstimate = undefined;
        } else {
            this.expandedEstimate = estimate;
        }
    }

    public closeDropdownFromOutside(id: string): void {
        if (this.allowOutsideToggleFor === id) {
            this.visibleDropdown = undefined;
            this.allowOutsideToggleFor = undefined;
        }
    }

    public openDropdown(id: string): void {
        this.allowOutsideToggleFor = undefined;
        this.visibleDropdown = id;
        setTimeout(() => this.allowOutsideToggleFor = id, 100);
    }

    public beginRename(estimate: Estimate): void {
        this.isRename = {};
        this.isRename[estimate.Id] = true;
        this.rename = estimate.Name;
    }

    public cancelRename(): void {
        this.isRename = {};
        this.rename = undefined;
        this.sameNameError = false;
    }

    public submitRename(estimate: Estimate): void {
        if (this.sameNameError || this.rename == null) {
            return;
        }

        this.isRename = {};
        const request = this.store.dispatch(new RenameEstimate(estimate.Id, this.rename));
        this.spinner.while(request);
        request.once(() => {
            this.store.select(EstimatesListState.getEstimate(this.sortType, this.sortDescending, estimate.EstimateType)).onceDefined((sorted) => {
                this.moduleEstimates = sorted;
            });
        });
    }

    public validateRename(estimates: Estimate[], rename: string): void {
        if (rename == null) {
            return;
        }

        if (estimates.length > 0
            && estimates.map((e) => e.Name).find((x) => x.toLowerCase() === rename.toLowerCase())) {
            this.sameNameError = true;
        } else {
            this.sameNameError = false;
        }
    }

    public duplicate(estimate: Estimate): void {
        const request = this.store.dispatch(new CloneEstimate(estimate.Id));
        this.spinner.while(request);
        request.once(() => {
            this.store.dispatch(new GetEstimates());
            this.sort();
        });
    }

    public archive(estimate: Estimate): void {
        const modal = this.modalManager.open<string, ArchiveEstimateModalComponent>(ArchiveEstimateModalComponent);
        modal.subscribe(() => {
            const request = this.store.dispatch(new ArchiveEstimate(estimate.Id));
            this.spinner.while(request);
            request.once(() => {
                this.store.dispatch(new GetEstimates());
                this.sort();
            });
        });
    }
}
