import { Component } from '@angular/core';
import { Util } from '@concurrency/core';
import { SelectionType } from 'src/app/_api/enums/selection-type';
import { EquationType, ImplicationType } from 'src/app/_api/responses/equation.response';
import { InputType } from 'src/app/_api/responses/input.response';
import { Equation, EquationParameter } from 'src/app/_navigator/data/model/equation.model';
import { OperandUtility } from '../operand.utility';
import { StudyComponent } from '../study.component';

@Component({ templateUrl: '../study.component.html' })
export class HfrCapmComponent extends StudyComponent {
    protected equationType = EquationType.HfrsCapmHfr;
    protected hasRegressionToggle = false;

    public buildEquation(): Equation {
        const operandUtility = new OperandUtility();
        const costOfEquityCapital = OperandUtility.getCostOfEquityCapital(this.equationType);
        const betaOperand = OperandUtility.getBeta(SelectionType.HighFinancialRiskBeta);

        const ke: EquationParameter = { item: costOfEquityCapital, operator: '=', canEdit: false };
        const rf: EquationParameter = { item: operandUtility.riskFreeRate, operator: '+', canEdit: true };
        const b: EquationParameter = { item: betaOperand, operator: '&#215;', canEdit: true };
        const erp: EquationParameter = { item: operandUtility.equityRiskPremium, operator: '+', canEdit: true };
        const rp: EquationParameter = { item: operandUtility.hfrRiskPremiumOverCapm, operator: '', canEdit: true };

        return {
            name: '',
            equationType: this.equationType,
            implicationType: ImplicationType.None,
            parameters: [ke, rf, b, erp, rp],
            calculate: (scenario) => {
                const equation = scenario.getEquation(this.equationType, ImplicationType.None);
                const riskFreeRate = scenario.getValueOrNaN(SelectionType.RiskFreeRate, InputType.None);
                const beta = scenario.getValueOrNaN(SelectionType.HighFinancialRiskBeta, InputType.None);
                const equityRiskPremium = scenario.getValueOrNaN(SelectionType.EquityRiskPremium, InputType.None);
                const riskPremium = scenario.getValueOrNaN(SelectionType.HighFinancialRiskPremiumOverCapM, InputType.None);

                const betaTimesEquityRiskPremium = Util.round(beta * equityRiskPremium);
                const result = riskFreeRate + betaTimesEquityRiskPremium + riskPremium;
                equation.Result = result || undefined;

                return equation.Result != null;
            }
        };
    }
}
