import { Component, OnInit } from '@angular/core';
import { Spinner, SubscriberEntity } from '@concurrency/angular';
import { Select } from '@ngxs/store';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { UserClient } from 'src/app/_api/clients/user.client';
import { BetaEstimate } from 'src/app/_navigator/data/model/beta-estimate.model';
import { BetaEstimatesListState } from 'src/app/_navigator/estimates-list-store/beta-estimates-list-state';
import { AccessType } from 'src/app/_navigator/user/enum/access-type';
import { EstimateSharedService } from './estimate-shared-service';
import { MenuType } from '../menu.type';
import { CurrencyClient } from 'src/app/_api/clients/currency.client';
import { Currency } from 'src/app/_api/responses/currency.response';
import { EstimateTitle } from 'src/app/_api/enums/estimate-type';

@Component({
    selector: 'beta-estimates',
    templateUrl: './beta-estimates.component.html'
})
export class BetaEstimatesComponent extends SubscriberEntity implements OnInit {
    private _access = new BehaviorSubject<AccessType | undefined>(undefined);
    public moduleEstimates!: BetaEstimate[] | undefined;
    public access = this._access.asObservable();
    public hideestimates = false;
    @Select(BetaEstimatesListState.get) public estimates!: Observable<BetaEstimate[] | undefined>;

    constructor(
        private spinner: Spinner,
        private userProfileClient: UserClient,
        private estimateSharedService: EstimateSharedService,
        private currencyClient: CurrencyClient
    ) {
        super();
    }

    public ngOnInit(): void {
        this.estimateSharedService.setTitle(EstimateTitle.BetaEstimate);
        this.estimateSharedService.setModuleType(MenuType.CompanyLevelBeta);
        this.estimates.onceDefined((estimates: BetaEstimate[]) => {
            this.getCurrencies(estimates);
        });
        const profileRequest = this.userProfileClient.read();
        this.spinner.while(profileRequest);
        profileRequest.onceDefined((profile) => {
            for (const i of profile.Subscriptions) {
                if (i.Type === 'BtaBasic' || i.Type === 'BtaTrial' || i.Type === 'BtaPro' || i.Type === 'BtaEnterprise') {
                    const isAuthorized = new Date(i.End) > new Date();
                    this.hideestimates = isAuthorized;
                }
            }
        });
    }


    public getCurrencies(estimates: BetaEstimate[]) {
        this.spinner.begin();
        this.currencyClient.getCurrency().onceDefined((data: Currency[]) => {
            this.spinner.end();
            this.estimateSharedService.setCurrency(data);
            this.moduleEstimates = this.estimateSharedService.getBetaEstimatesWithCurrencyAcronom(estimates);
        }, (error: any) => { this.spinner.end(); this.moduleEstimates = estimates; })
    }
}
